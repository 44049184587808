import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFirebase } from 'react-redux-firebase';

// hooks
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useObjectCollection } from '../hooks/useObjectCollection';

// components
import {
  IonSplitPane,
  IonMenu,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonImg,
  IonButton
} from '@ionic/react';
import {
  documentTextOutline,
  documentsOutline,
  personCircleOutline,
  optionsOutline,
  rocketOutline
} from 'ionicons/icons';
import ProjectCreateModal from '../modals/project/ProjectCreateModal';
import { NavLink } from 'react-router-dom';

// types
import { Project } from '../models/Project';

import './Navigation.scss';
import { FormattedMessage } from 'react-intl';


const Navigation: React.FC = (props) => {

  const minDesktopScreenWidth = 992;
  const testWidth = (evt: any) => {
    setIsDesktop(!evt?.matches);
  }
  const [ isDesktop, setIsDesktop ] = useState(window.innerWidth > minDesktopScreenWidth);

  useEffect(() => {
    var mql = window.matchMedia('(max-width: ' + minDesktopScreenWidth + 'px)');
    mql.addListener(testWidth);
    return () => mql.removeListener(testWidth);
  }, []);

  const firebase = useFirebase();
  const history = useHistory();
  function signOut() {
    firebase.logout();
    setTimeout(() => {
      history.push('/');
    }, 200);
  }

  // disguise
  const disguise = useSelector((state: any) => state.firebase.profile?.disguise);

  // show tabs
  const showTabs = useSelector((state: any) => state.ui.showTabs);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});

  //get all projects
  const projects = useObjectCollection('projects');

  //is there any project with category:"cat1" to show resources menu 
  const isCat1Project: boolean = useMemo( () => {
    if (projects.state.isLoaded && !projects.state.isEmpty) {
      return Object.keys(projects.items).some(pid => projects.items[pid].category === 'cat1');
    }
    return false;
  }, [ projects.items, projects.state.isEmpty, projects.state.isLoaded ]);

  // display the list of objects
  const listProjects = () => {
    if (
      permissions['projects.group.read'] &&   // we can read projects for the group
      !permissions['projects.all.read'] &&    // but we are not an admin
      !permissions['projects.published.read'] // and not a jury
    ) {
      if (projects.state.isLoaded && !projects.state.isEmpty) {
        return Object.keys(projects.items)
          .map(key => projects.items[key])
          .filter(project => project)
          .sort((pr1, pr2) => pr1.pid > pr2.pid ? 1 : -1)
          .map((project: Project) => (
            <NavLink className="side-menu--item" activeClassName="side-menu--item--selected" to={`/projects/${ project.pid }`} key={project.pid}>{ project.pid } · { project.title }</NavLink>
          ))
      }
    }
    return null;
  }

  //open modal
  const pageRef = useRef();
  const [ modalOpened, setModalOpened ] = useState(false);
  const openModal = () => {
    setModalOpened(true);
  }

  const removeDisguise = () => {
    firebase.updateProfile({ disguise: null });
  }
  
  if (isDesktop) {
    return (
      <IonSplitPane contentId="main" className="main-content desktop">
        <ProjectCreateModal opened={modalOpened} setOpened={setModalOpened} pageRef={pageRef.current} />
        <IonMenu contentId="main" className="side-menu">
          <div className="side-menu--contents">
            <NavLink exact to="/projects">
              <IonImg src="/assets/logo.svg" className="side-menu--logo" />
            </NavLink>

            <div className="side-menu--list">
              { (
                  permissions['projects.all.read'] ||
                  permissions['projects.approved.read'] ||
                  permissions['companies.group.read'] ||
                  (
                    permissions['projects.sponsoring.read'] &&
                    !permissions['projects.group.read']
                  )
                )
                ? <NavLink exact
                    to="/projects"
                    className="side-menu--item project"
                    activeClassName="side-menu--item--selected"
                    isActive={(match, location) => (match || location.pathname.indexOf('/projects') === 0) ? true : false}
                  >
                    <FormattedMessage
                      id="nav.projects"
                      defaultMessage="Projects"
                      description="Projects nav button"
                    />
                  </NavLink>
                : permissions['projects.group.read'] &&
                  listProjects()
              }
              {
                !permissions['projects.all.read'] &&
                !permissions['companies.group.read'] &&
                !permissions['projects.sponsoring.read'] &&
                permissions['projects.group.read'] &&
                <NavLink exact
                         to="/projects"
                         className="side-menu--item projects-list"
                         activeClassName="side-menu--item--selected"
                         isActive={(match, location) => (match || location.pathname === 'projects') ? true : false}
                >
                    <FormattedMessage
                        id="nav.projects-list"
                        defaultMessage="Projects list"
                        description="Projects list button"
                    />
                </NavLink>
              }
              { !disguise && permissions['projects.group.create'] &&
                <span onClick={() => openModal()} className="side-menu--item project-create">
                  <FormattedMessage
                    id="nav.new-project"
                    defaultMessage="Create a new project"
                    description="Create Projects nav button"
                  />
                </span>
              }
            </div>

            { ( permissions['resources.read'] || isCat1Project ) &&
              <div className="side-menu--list resources">
                <NavLink
                  to="/resources/general"
                  className="side-menu--item resources"
                  activeClassName="side-menu--item--selected"
                  isActive={(match, location) => (match || location.pathname.indexOf('/resources') === 0) ? true : false}
                >
                  <FormattedMessage
                    id="nav.resources"
                    defaultMessage="Resources"
                    description="Resources nav button"
                  />
                </NavLink>
              </div>
            }

            { (permissions['companies.all.read'] || permissions['companies.published.read']) &&
              <div className="side-menu--list">
                <NavLink
                  to="/sponsors"
                  className="side-menu--item"
                  activeClassName="side-menu--item--selected"
                  isActive={(match, location) => (match || location.pathname.indexOf('/sponsors') === 0) ? true : false}
                >
                  <FormattedMessage
                    id="nav.sponsors"
                    defaultMessage="Sponsors"
                    description="Sponsors nav button"
                  />
                </NavLink>
              </div>
            }

            { (permissions['settings.users.read'] || permissions['settings.roles.read'] || permissions['settings.permissions.read']) &&
              <div className="side-menu--list">
                <NavLink
                  to="/settings"
                  className="side-menu--item settings"
                  activeClassName="side-menu--item--selected"
                  isActive={(match, location) => (match || location.pathname.indexOf('/settings') === 0) ? true : false}
                >
                  <FormattedMessage
                    id="nav.settings"
                    defaultMessage="Settings"
                    description="Settings nav button"
                  />
                </NavLink>
              </div>
            }
            <div className="side-menu--list">
              { !disguise &&
                <NavLink
                  to={
                    permissions['companies.group.read']
                    ? '/account/company'
                    : '/account/profile-details'
                  }
                  className="side-menu--item account"
                  activeClassName="side-menu--item--selected"
                  isActive={(match, location) => (match || location.pathname.indexOf('/account') === 0) ? true : false}
                >
                  <FormattedMessage
                    id="account.title"
                    defaultMessage="Account"
                    description="Account section title"
                  />
                </NavLink>
              }

              <span className="side-menu--item log-out" onClick={signOut}>
                <FormattedMessage
                  id="nav.sign-out"
                  defaultMessage="Sign Out"
                  description="Sign out nav button"
                />
              </span>
            </div>
          </div>


          { disguise &&
            <div className="side-menu--disguise">
              <div className="side-menu--disguise-name">
                <FormattedMessage
                  id="nav.disguised-as"
                  defaultMessage="Disguised as {names}"
                  description="Message as whom the user is disguised"
                  values={{ names: disguise.names }}
                />
              </div>
              <IonButton size="small" fill="outline" color="light" onClick={removeDisguise}>
                <FormattedMessage
                  id="button.disguise-off"
                  defaultMessage="Switch Off"
                  description="Button that turns off the disguisement"
                />
              </IonButton>
            </div>
          }

        </IonMenu>

        { props.children }
      </IonSplitPane>
    )
  } else {
    return (
      <IonTabs>
        { props.children }

        <IonTabBar slot="bottom" className={'main-content not-desktop ' + (showTabs ? '' : 'hide-tabs')}>
          <IonTabButton tab="projects" href="/projects">
            <IonIcon icon={documentTextOutline} />
            <IonLabel>
              <FormattedMessage
                id="nav.projects"
                defaultMessage="Projects"
                description="Projects nav button"
              />
            </IonLabel>
          </IonTabButton>
          
          <IonTabButton tab="resources" href="/resources" hidden={ permissions['resources.read'] ? false : !isCat1Project }>
            <IonIcon icon={documentsOutline} />
            <IonLabel>
              <FormattedMessage
                id="nav.resources"
                defaultMessage="Resources"
                description="Resources nav button"
              />
            </IonLabel>
          </IonTabButton>
          
          { (permissions['companies.all.read'] || permissions['companies.published.read']) &&
            <IonTabButton tab="sponsors" href="/sponsors">
              <IonIcon icon={rocketOutline} />
              <IonLabel>
                <FormattedMessage
                  id="nav.sponsors"
                  defaultMessage="Sponsors"
                  description="Sponsors nav button"
                />
              </IonLabel>
            </IonTabButton>
          }
          { (permissions['settings.users.read'] || permissions['settings.roles.read'] || permissions['settings.permissions.read']) &&
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={optionsOutline} />
              <IonLabel>
                <FormattedMessage
                  id="nav.settings"
                  defaultMessage="Settings"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonTabButton>
          }
          { !disguise &&
            <IonTabButton tab="account" href="/account">
              <IonIcon icon={personCircleOutline} />
              <IonLabel>
                <FormattedMessage
                  id="account.title"
                  defaultMessage="Account"
                  description="Account section title"
                />
              </IonLabel>
            </IonTabButton>
          }
        </IonTabBar>
      </IonTabs>
    )
  }
}

export default Navigation;
