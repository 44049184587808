/**
 * Converts file size in bytes to the most appropriate size in KB, MB or GB
 * 
 * @param bytes  file size in bytes
 * @param dp     numbers after the comma
 * @param si     divider should be the 10^3 (SI) or 2^10
 */
export const formatFileSize = (bytes: number, dp = 0, si = true) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


/**
 * Removes accents from the text
 * 
 * @param str  string to normalize
 */
export const normString = (str: string | string[]): string => {
  if (!str) return '';
  if (Array.isArray(str)) {
    str = str.join('|');
  }
  if (typeof str !== 'string') {
    return '';
  } else {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zа-яё0-9|@.]/gi, '')
      .toLowerCase();
  }
}


/**
 * Extracts the YouTube ID from the URL
 * 
 * @param url  YouTube address
 */
export const getYouTubeId = (url: string) => {
  const [a, , b] = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0];
  } else {
    return a;
  }
};
