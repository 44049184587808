import React, { useState, useRef } from 'react';

// hooks
import { useParams } from 'react-router';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';

// components
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonButton
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import TermModal from '../modals/TermModal';

import { TaxonomiesState, taxonomiesNames } from '../models/Taxonomies';
import { FormattedMessage } from 'react-intl';

const TaxonomyTermList: React.FC = () => {
  const { taxonomy } = useParams<{ taxonomy: string }>();

  // get taxonomy terms from db
  const taxonomyTerms = useFirestoreCollectionQuery(taxonomy, false) as TaxonomiesState;
    
  const pageRef = useRef();
  const [ termOpened, setTermOpened ] = useState(false);
  const [ selectedTermValue, setselectedTermValue ] = useState('');

  const openTermModal = (value: string) => {
    setselectedTermValue(value);
    setTermOpened(true);
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings/taxonomies" />
          </IonButtons>
          <IonTitle>{ taxonomiesNames[taxonomy] }</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => openTermModal('')}>
              <FormattedMessage
                  id="taxonomy.term.create"
                  defaultMessage="Create New Term"
                  description="Taxonomy list nav button"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{ taxonomiesNames[taxonomy] }</IonTitle>
          </IonToolbar>
        </IonHeader>

        { setselectedTermValue &&
          <TermModal
            opened={termOpened}
            setOpened={setTermOpened}
            pageRef={pageRef.current}
            termValue={selectedTermValue}
            taxonomy ={taxonomy}
          />
        }
        { !taxonomyTerms.state.isLoaded
          ? <LoadingScreen/>
          :  !taxonomyTerms.items 
            ? <div></div>
            :
            <IonList>
              { Object.keys( taxonomyTerms.items )
                  .map( key => taxonomyTerms.items[key])
                  .filter(taxonomyTerms => taxonomyTerms )
                  .map((taxonomyTerms) => (
                    <IonItem key={ taxonomyTerms.value }
                      button
                      detail={false}
                      onClick={() => openTermModal(taxonomyTerms.value)}
                    >
                      <IonLabel>{ taxonomyTerms.name }</IonLabel>
                    </IonItem>
                  ))
              }
            </IonList>
        }
      </IonContent>
    </IonPage>
  );
};

export default TaxonomyTermList;