import { IonButton } from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type AuthChooseRegistrationProps = {
  changeFormType: Function;
}

const AuthChooseRegistration = ({ changeFormType }: AuthChooseRegistrationProps) => {
  return (
    <>
      <div>
        <div className="auth-form-wrap">
          <div className="ion-padding sign-in-button">
            <h3 className="auth-form--subtitle">
              <FormattedMessage
                id= "auth.chose.leader.title"
                defaultMessage= "Project Leader"
                description="Choose sign up profile leader title"
              />
            </h3>
            <p className="auth-form--description">
              <FormattedMessage
                id= "auth.chose.leader.description"
                defaultMessage= "You want to create and manage projects and events as part of Esch 2022"
                description="Choose sign up profile project leader description text"
              />
            </p>
            <IonButton
              expand="block" type="submit"
              onClick={() => changeFormType('sign-up')}
              disabled={true}
            >
              <FormattedMessage
                id= "auth.chose.submit"
                defaultMessage= "Select"
                description="Choose sign up profile submit button"
              />
            </IonButton>
          </div>
        </div>
        <div className="auth-form-wrap">
          <div className="ion-padding sign-in-button">
            <h3 className="auth-form--subtitle">
              <FormattedMessage
                id= "auth.chose.sponsor.title"
                defaultMessage= "Sponsor"
                description="Choose sign up profile sponsor title"
              />
            </h3>
            <p className="auth-form--description">
              <FormattedMessage
                id= "auth.chose.sponsor.description"
                defaultMessage= "You are willing to support one or several projects during the Esch 2022"
                description="Choose sign up profile project sponsor description text"
              />
            </p>
            <IonButton
              expand="block" type="submit"
              onClick={() => changeFormType('sign-sponsor')}
            >
              <FormattedMessage
                id= "auth.chose.submit"
                defaultMessage= "Select"
                description="Choose sign up profile submit button"
              />
            </IonButton>
          </div>
        </div>
      </div>
      <div className="auth-form-content-switch">
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => changeFormType('sign-in')}
        >
          <FormattedMessage
            id= 'button.back-sign-in'
            defaultMessage= 'Back to Sign In'
            description= 'Back to Sign In'
          />
        </IonButton>
      </div>
    </>
  )
}

export default AuthChooseRegistration;
