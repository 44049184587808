import { RequestState } from "./RequestState";

export type Permission = {
  id: string;
  name: string;
  orderIndex: number;
}

export type PermissionState = {
  item: Permission;
  state: RequestState;
};

export type PermissionsState = {
  items: { [id: string]: Permission };
  state: RequestState;
};

export type PermissionsOrderedState = {
  items: Permission[];
  state: RequestState;
};


export const newPermission = {
  id: '',
  name: '',
  orderIndex: 0
}
