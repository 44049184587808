import React from 'react';

// hooks
import { useIntl } from 'react-intl';

// style
import './AuthTitle.scss';

type AuthTitlrProps = {
    toWhichForm: string;
}

const AuthTitle = ({ toWhichForm }: AuthTitlrProps) => {
  const intl = useIntl();

  const titles: { [type: string]: string } = {
    'sign-in': intl.formatMessage({
      id: 'auth.titles.sign-in',
      defaultMessage: 'Login to Esch2022',
      description: 'Sign In form title',
    }),
    'sign-in-subtitle': intl.formatMessage({
      id: 'auth.titles.sign-in-subtitle',
      defaultMessage: 'Dedicated to project leader and sponsors',
      description: 'Sign In screen sub title',
    }),
    'sign-up': intl.formatMessage({
      id: 'auth.titles.sign-up',
      defaultMessage: 'Create a Project Leader Account',
      description: 'Sign Up screen title',
    }),
    'sign-sponsor': intl.formatMessage({
      id: 'auth.titles.sign-sponsor',
      defaultMessage: 'Create a Sponsor Account',
      description: 'Sign Sponsor screen title',
    }),
    'reset-password': intl.formatMessage({
      id: 'auth.titles.reset-password',
      defaultMessage: 'Forgot Password',
      description: 'Reset password screen title',
    }),
    'reset-password-expired': intl.formatMessage({
      id: 'auth.titles.reset-password-expired',
      defaultMessage: 'In order to set your new password, please enter your email address',
      description: 'Reset expired password screen title',
    }),
    'create-password': intl.formatMessage({
      id: 'auth.titles.create-password',
      defaultMessage: 'Enter Your New Password',
      description: 'Create password screen title',
    }),
    'choose-registration': intl.formatMessage({
      id: 'auth.titles.choose-registration',
      defaultMessage: 'Create an account',
      description: 'Choose Registration Type screen title',
    })
  }

  return (
    <>
      <h2 className="esch-auth-title">
        { titles[toWhichForm] }
      </h2>
      { toWhichForm === 'sign-in' &&
        <h3 className="esch-auth-subtitle">
            { titles['sign-in-subtitle'] }
        </h3>
      }
    </>
  )
}

export default AuthTitle;
