import React, { ReactElement, useEffect } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useFirebase } from 'react-redux-firebase';

// components
import { IonPage, IonSpinner } from '@ionic/react';


type TDataLoaderProps = {
  children: ReactElement
}

const DataLoader = ({ children }: TDataLoaderProps) => {
  // load user's profile
  const profile = useSelector((state: any) => state.firebase.profile || { isNotLoaded: true });

  // load system projects settings
  useFirestoreItemQuery('system', 'projects');

  // load user's permissions
  const uid = useSelector((state: any) => profile.disguise?.uid || state.firebase.auth.uid);
  const role = useFirestoreItemQuery('roles', profile.disguise?.role || profile.role, { storeAs: 'role' });

  // user group
  const groupId = profile.disguise?.groupId || profile.groupId;

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || { isNotLoaded: true });


  // make online presence records if uid is changing
  const firebase = useFirebase();
  useEffect(() => {
    const statusRef = firebase.database().ref(`/status/${uid}`);

    firebase.database().ref('.info/connected')
      .on('value', snapshot => {
        if (snapshot.val() !== false) {
          statusRef.onDisconnect()
            .set({
              state: 'offline',
              time: firebase.database.ServerValue.TIMESTAMP
            })
            .then(() => {
              statusRef.set({
                state: 'online',
                time: firebase.database.ServerValue.TIMESTAMP
              })
            })
        }
      })

    // eslint-disable-next-line
  }, [uid]);


  if (role.state.isLoaded && !role.state.isEmpty && !permissions.isNotLoaded) {
    if (
      permissions['projects.all.read'] ||
      permissions['projects.approved.read'] ||
      permissions['projects.group.read'] ||
      permissions['projects.sponsoring.read']
    ) {
      return (
        <>
          <ProjectsLoader permissions={permissions} groupId={groupId} />
          { children }
        </>
      )
    } else {
      return children
    }
  } else {
    return (
      <IonPage>
        <div className="center-element">
          <IonSpinner />
        </div>
      </IonPage>
    )
  }
}

export default DataLoader;


const ProjectsLoader = ({ permissions, groupId }: any) => {
  const options = permissions['projects.all.read'] || permissions['projects.approved.read']
  ? { storeAs: 'projects' }
  // : permissions['projects.approved.read']
  //   ? {
  //       where: ['status', '==', 'approved'],
  //       storeAs: 'projects'
  //     }
    : permissions['projects.sponsoring.read']
    ? {
        where: [`sponsoring.participation`, '==', true],
        storeAs: 'projects'
      }
      : permissions['projects.group.read']
        ? {
            where: [`access.${groupId}`, '>', ''],
            storeAs: 'projects'
          }
      : {
          where: ['nothing', '==', 'everything'],
          storeAs: 'projects'
        }

  useFirestoreCollectionQuery('projects', false, options);
  return <></>
}
