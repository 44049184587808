import React, { useEffect, useState } from 'react'
import * as fb from 'firebase/app';

// hooks
import { useSelector } from 'react-redux';
import { useFirestoreItemQuery } from '../../hooks/useFirestoreItemQuery';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'

import './ProjectDraftPanel.scss';
import { Link } from 'react-router-dom';

// types
import { CompanyState } from '../../models/Company';
import { FormattedMessage } from 'react-intl';


const ProjectsForUpapprovedSponsors = () => {
  const firebase = useFirebase();

  // get the user's id
  // const uid = useSelector((state: any) => state.firebase.auth.uid);
  const profile = useSelector((state: any) => state.firebase.profile);

  // get user's permissions
  // const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  // get user's company info
  const company = useFirestoreItemQuery('companies', profile.disguise?.groupId || profile.groupId) as CompanyState;


  // check the company form completeness
  const [ isCompanyComplete, setCompanyComplete ] = useState(false);
  useEffect(() => {
    if (company.item) {
      setCompanyComplete(
        !!(company.item.name && company.item.name !== '') &&
        !!(company.item.cardDescription && company.item.cardDescription !== '') &&
        !!(company.item.activity && company.item.activity !== '') &&
        !!(company.item.contactPerson && company.item.contactPerson !== '') &&
        !!(company.item.contactEmail && company.item.contactEmail !== '')
      );
    }
  }, [company.item]);


  const [ accessIsRequesting, setAccessIsRequesting ] = useState(false);
  const [ accessRequested, setAccessRequested ] = useState(profile.accessRequested);
  const submitCompany = () => {
    setAccessIsRequesting(true);
    const requestAccessFn = fb.app().functions('europe-west3').httpsCallable('requestAccess');
    requestAccessFn({
      gid: profile.groupId,
      user_name: `${profile.firstName} ${profile.lastName}`
    })
      .then(() => {
        firebase.updateProfile({
          accessRequested: true
        })
        .then(() => {
          setAccessRequested(true);
          setAccessIsRequesting(false);
        });
      })
      .catch(err => {
        console.error('Error requesting access', err);
        setAccessIsRequesting(false);
      });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="nav.projects"
              defaultMessage="Projects"
              description="Projects nav button"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="draft-panel">
          <div className="draft-panel--message">
            { accessRequested && isCompanyComplete
              ? <>
                  <h2>You already send your access request</h2>
                  <p>
                    But you can send another one in case you need that.
                  </p>
                </>
              : isCompanyComplete
              ? <>
                  <h2>You are ready to request your access</h2>
                  <p>
                    As your profile has been completed, you can now request your access as a Sponsor.
                  </p>
                </>
              : <>
                  <h2>Complete your company profile</h2>
                  <p>
                    In order to access the Esch2022 projects, please complete your profile
                    in the <Link to="/account/company">Account section</Link> and request an access.
                  </p>
                </>
            }
          </div>
          <IonButton className="blue-style" disabled={!isCompanyComplete || accessIsRequesting} onClick={() => submitCompany()}>Request an Access</IonButton>
        </div>

      </IonContent>
    </IonPage>
  )
}

export default ProjectsForUpapprovedSponsors;
