import React from 'react';
import * as fb from 'firebase/app';

// components
import { IonButton, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import {
  FormattedMessage,
  // useIntl
} from 'react-intl';

type AuthResetPasswordProps = {
  email: any;
  errors: any;
  changeFormType: Function;
  setMessageType: Function;
  clearState?: Function;
};

const AuthResetPassword = ({ email, errors, changeFormType, setMessageType, clearState }: AuthResetPasswordProps) => {
  // const intl = useIntl();

  // reset pass
  function resetPassword() {
    const auth = fb.auth();
    auth.sendPasswordResetEmail(email.value).then(
      () => {
        setMessageType('reset-password');
        if (clearState) {
          clearState();
        }
      },
      e => {
        console.error('reset password error', e);
      },
    );

    /* Temporarily disabled, because not all the emails are finding their way to the users

    const resetPasswordFn = fb.app().functions('europe-west3').httpsCallable('resetPassword');
    resetPasswordFn({ email: email.value, lang: intl.locale })
      .then(() => {
        // we should show the message that the email was sent
        setMessageType('reset-password');
        if (clearState) {
          clearState();
        }
      }, (e) => {
        console.error('reset password error', e);
      });
    */
  }

  // catch the Enter key in the password field to submit the form
  function enterPressed(evt: any) {
    if (evt && evt.which && evt.which === 13) {
      resetPassword();
    }
  }

  return (
    <>
      <div className="auth-form-wrap esch">
        <IonList lines="full" className="auth-form-content" class="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage id="form.email" defaultMessage="Email address" description="Email field on all form" />
            </IonLabel>
            <IonInput
              required
              type="email"
              name="email"
              {...email}
              autofocus={true}
              onKeyPress={enterPressed}
            ></IonInput>
          </IonItem>
        </IonList>
        {errors}
        <div className="ion-padding button-container">
          <IonButton type="submit" class="ion-reset-password-button" onClick={resetPassword}>
            <FormattedMessage
              id="auth.form.reset.submit"
              defaultMessage="Send email"
              description="Reset passsword form send button label"
            />
          </IonButton>
        </div>
      </div>
      <div className="auth-form-content-switch">
        <IonButton
          className="over-red-ion-button"
          fill="clear"
          size="small"
          color="dark"
          onClick={() => {
            changeFormType('sign-in');
          }}
        >
          <FormattedMessage id="button.back-sign-in" defaultMessage="Back to Sign In" description="Back to Sign In" />
        </IonButton>
      </div>
    </>
  );
};

export default AuthResetPassword;
