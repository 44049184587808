export default `
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600');
  @page { 
    size: auto;
    margin: 15mm 5mm 20mm 20mm;  
  } 
  body {
    background: white;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    padding-top: 60px;
    margin: 12px;
    background: url('https://esch2022.lu/assets/images/logo-red.png') no-repeat;
    background-position: 10px 20px;
    background-size: 205px 36px;
  }
  h2 {
    margin: 1.4em 0 0.8em;
    font-weight: 600;
    page-break-after: avoid;
  }
  ion-label {
    display: block;
  }
  ion-label {
    display: block;
    font-weight: 500;
    margin: 0.8em 0 0.6em;
    page-break-after: avoid;
  }
  .data-value {
    display: block;
    margin: 0.6em 0 1.2em 0.6em;
    font-weight: 400;
    page-break-before: avoid;
  }
  .data-value--file-size {
    float: right;
  }
`;
