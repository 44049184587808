import React from 'react';
import classNames from 'classnames';

// hooks
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// components
import { IonButton, IonImg } from '@ionic/react';

import { locales } from '../Intl';

import './AuthHat.scss';
import { FormattedMessage } from 'react-intl';

const AuthHat = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const changeLocale = (newLocale: string) => {
    if (newLocale !== intl.locale) {
      dispatch({ type: 'SET_LOCALE', payload: newLocale });
    }
  }

  return (
    <div className="auth-hat">
      <div>
        <a className="auth-hat--link" href="https://esch2022.lu">
        <FormattedMessage
          id= "auth.back-to-esch"
          defaultMessage= "Back to esch2022.lu"
          description="Button label to go back esch2022.lu site"
        />
        </a>
        { locales.map(locale => (
            <IonButton
              key={locale.id}
              className={classNames({
                'auth-hat--lang-switch': true,
                'auth-hat--lang-switch-selected': intl.locale === locale.id
              })}
              fill="clear"
              disabled={intl.locale === locale.id}
              onClick={() => changeLocale(locale.id)}
            >{ locale.id.toUpperCase() }</IonButton>
          ))
        }
      </div>
      <IonImg src="/assets/logo.svg" className="auth-hat--logo" />
    </div>
  )
}

export default AuthHat;
