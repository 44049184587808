import React, { useState, useEffect } from 'react';

// hooks
import { useFirestore } from 'react-redux-firebase';
import { useOrderedCollection } from '../hooks/useOrderedCollection';
import { useCollectionItem } from '../hooks/useCollectionItem';

// components
import ModalForm from "../components/ModalForm";
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';

// models
import { Permission, newPermission } from '../models/Permission';
import { useIntl, FormattedMessage } from 'react-intl';

type PermissionModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  setPermissionId?: Function;
  permissionId?: string;
}

const PermissionModal = (props: PermissionModalProps) => {

  // loading data of the chosen item (if any)
  const permissions = useOrderedCollection('permissions');
  const permission = useCollectionItem('permissions', props.permissionId);
  const [ permissionId, setPermissionId ] = useState<string>(props.permissionId || '');

  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<Permission>({
    ...newPermission,
    ...permission.item
  });

  // updating form data when the item is loaded
  useEffect(() => {
    if (permissionId !== props.permissionId && permission.item?.id !== formData.id) {
      setPermissionId(props.permissionId || '');
      resetForm();
    }
    // eslint-disable-next-line
  }, [permission])


  const resetForm = () => {
    if (props.permissionId !== '') {
      setFormData({ ...permission.item });
    } else {
      setFormData({ ...newPermission });
    }
  }

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // function that is saving the data
  const firestore = useFirestore();
  const createItem = () => {
    // since we can'd do anything without id
    // we are checking
    // TODO: show an error for required fields
    if (formData.id) {

      // clearing undefined values — Firestore don't like theese
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key]
        }
      });

      // setting orderIndex if there is no index yet
      if (!formData.orderIndex) {
        formData.orderIndex = permissions.items.length;
      }

      // sending to the firestore
      firestore
        .collection('permissions')
        .doc(formData.id)
        .set(formData)
        .then(() => {
          // clears the data from the form
          resetForm();
          // closes the modal
          props.setOpened(false);
        });
    }
  }

  // function that deleted current item
  const deleteItem = () => {
    if (props.permissionId) {
      firestore
        .collection('permissions')
        .doc(props.permissionId)
        .delete()
        .then(() => {
          resetForm();
          props.setOpened(false);
        })
        .catch(err => {
          console.error('error deleting permission', props.permissionId, err);
        });
    }
  }

  const intl = useIntl();

  return (
    <ModalForm {...props}
      title={
        props.permissionId
        ? intl.formatMessage({
          id: 'modal.title.edit',
          defaultMessage: 'Edit {data}',
          description: 'Edit a role',
        },{
          data: formData.name
        })
        : intl.formatMessage({
          id: 'permissions.modal.title.create',
          defaultMessage: 'Create a New Permission',
          description: 'Create a New Permission modal title',
        })
      }
    >
      <IonList className={!props.permissionId ? 'no-bottom-controls' : ''}>
        <IonItem>
          <IonLabel position="stacked">
              <FormattedMessage
                id="permissions.id"
                defaultMessage="Idetifier (required)"
                description="Permissions field label"
              />
            </IonLabel>
          <IonInput
            value={formData.id}
            onIonChange={e => updateData('id', e.detail.value)}
            name="id"
            type="text"
            inputmode="text"
            enterkeyhint="next"
            clearInput
            readonly={!!permissionId}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
              <FormattedMessage
                id="permissions.display-name"
                defaultMessage="Display Name"
                description="Permissions field label"
              />
            </IonLabel>
          <IonInput
            value={formData.name}
            onIonChange={e => updateData('name', e.detail.value)}
            name="name"
            type="text"
            inputmode="text"
            enterkeyhint="next"
            clearInput
          ></IonInput>
        </IonItem>
      </IonList>
      <div className="permissions-actions-bar bottom-controls">
        { props.permissionId &&
          <IonButton
            expand="block"
            className="danger-style"
            onClick={deleteItem}
          >
            <FormattedMessage
              id= "permissions.button.delete"
              defaultMessage= 'Delete Permission'
              description="delete button label"
            />
          </IonButton>
        }
        <IonButton
          expand="block"
          onClick={createItem}
          className="blue-style"
        >{props.permissionId
          ? <FormattedMessage
              id= "button.update"
              defaultMessage= "Update"
              description="Update button label"
            />
          : <FormattedMessage
              id= "permissions.button.create"
              defaultMessage= 'Create a permission'
              description="create button label"
            />
          }</IonButton>
      </div>
    </ModalForm>
  )
}

export default PermissionModal;
