import React, { useMemo } from 'react';

// hooks
import { useParams } from 'react-router';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

// components
import {
  IonPage,
  IonContent,
} from '@ionic/react';
import ResourcesHeader from '../components/ResourcesHeader';
import LoadingScreen from '../components/LoadingScreen';
import ResourcesList from '../components/ResourcesList';


const ResourcesCategory = () => {
  const intl = useIntl();
  const { category } = useParams<{ category: string }>();

  const files = useFirestoreItemQuery('resources', category);

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  const firestore = useFirestore();
  const updateDocument = (updatedFiles: { [fileId: string]: any }) => {
    // create firestore document
    firestore
      .collection('resources')
      .doc(category)
      .set(updatedFiles)
      .catch(err => {
        console.error('Resource document update error:', category, err);
      });
  }

  
  const resourcesDescriptions = useMemo<{ [category: string]: string }>(() => ({
    'general': intl.formatMessage({
      id: 'resources.general.description',
      defaultMessage: ' ',
      description: 'Resources General category description'
    }),
    'coaching': intl.formatMessage({
      id: 'resources.coaching.description',
      defaultMessage: ' ',
      description: 'Resources Coaching category description'
    })
    // eslint-disable-next-line
  }), []);

  const resourcesDescription = resourcesDescriptions[category] || ' ';

  return (
    <IonPage>
      <ResourcesHeader category={category} />
      <IonContent>
        { resourcesDescription !== ' ' &&
          <p className="project--section-title">{ resourcesDescription }</p>
        }

        { !files.state.isLoaded
          ? <LoadingScreen />
          : <ResourcesList
              collection="resources"
              doc={category}
              files={files.item}
              permissions={{
                create: permissions[`resources.${category}.create`],
                delete: permissions[`resources.${category}.delete`]
              }}
              onChange={updateDocument}
            />
        }

      </IonContent>
    </IonPage>
  )
}

export default ResourcesCategory;
