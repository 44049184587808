import React from 'react';

// hooks
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';


type AuthRegisterProps = {
  firstName: any;
  lastName: any;
  email: any;
  password: any;
  gdprCheck: any;
  errors: any;
  setErrorMessage: Function;
  setGdprCheck: Function;
  changeFormType: Function;
}

const AuthRegister = ({firstName, lastName, email, password, gdprCheck, errors, setErrorMessage, setGdprCheck, changeFormType}: AuthRegisterProps) => {
  const firebase = useFirebase();
  const intl = useIntl();

  // registration
  function createAccount() {
    // check the fields first
    if (firstName.value === '') {
      setErrorMessage(intl.formatMessage({
        id: 'auth.errors.empty-first-name',
        defaultMessage: 'Please, fill in your First Name.',
        description: 'Error when somebody is registering and did not fill in his/her first name'
      }));
    } else if (lastName.value === '') {
      setErrorMessage(intl.formatMessage({
        id: 'auth.errors.empty-last-name',
        defaultMessage: 'Please, fill in your Last Name.',
        description: 'Error when somebody is registering and did not fill in his/her last name'
      }));
    } else if (!gdprCheck.checked) {
      setErrorMessage(intl.formatMessage({
        id: 'auth.errors.privacy-policy',
        defaultMessage: 'You have to accept the Privacy Policy to continue.',
        description: 'Error when somebody is registering and did not check that he/she accepts the privacy policy'
      }));
    } else {
      setErrorMessage('');

      firebase
        .createUser(
          {
            email: email.value,
            password: password.value
          },
          {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            role: 'registered', // default role
            // groupId will be created by the cloud function
            gdpr: gdprCheck.dateTime,
            lang: intl.locale
          }
        )
    }
  }

  // catch the Enter key in the password field to submit the form
  function enterPressed(evt: any) {
    if (evt && evt.which && evt.which === 13) {
      createAccount();
    }
  }
  
  return (
    <>
      <div className="auth-form-wrap esch">
        <IonList lines="full" className="auth-form-content" class="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.first-name"
                defaultMessage= "First Name"
                description="First Name field on all form"
              />
            </IonLabel>
            <IonInput required type="text" name="firstName"
              {...firstName}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.last-name"
                defaultMessage= "Last Name"
                description="Last Name field on all form"
              />
            </IonLabel>
            <IonInput required type="text" name="lastName"
              {...lastName}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.email"
                defaultMessage= "Email address"
                description="Email field on all form"
              />
            </IonLabel>
            <IonInput required type="email" name="email"
              {...email}
              autofocus={true}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.password"
                defaultMessage= "Password"
                description="Password field on all form"
              />
            </IonLabel>
            <IonInput required type="password" name="password"
              {...password}
              onKeyPress={enterPressed}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonCheckbox 
              checked={gdprCheck.checked}
              slot="start"
              name="gdpr"
              onIonChange={
                (e) => {
                  setGdprCheck( { checked: e.detail.checked, dateTime : new Date().toISOString() });
                  setErrorMessage('');
                }
              }
            />
            <p>
              <FormattedMessage
                id= "form.privacy.first"
                defaultMessage= "You accept the"
                description="You accept the  ... "
              />&nbsp;
              <a href="https://esch2022.lu/assets/pdf/legal/privacy-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id= "form.privacy.second"
                  defaultMessage= "Privacy policy"
                  description="... Privacy policy"
                />
              </a>
            </p>
          </IonItem>
        </IonList>
        { errors }
        <div className="ion-padding button-container">
          <IonButton
            expand="block" type="submit"
            class="ion-sign-up-leader-button"
            onClick={createAccount}
          >Create Account</IonButton>
        </div>
      </div>
      <div className="auth-form-content-switch">
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => changeFormType('sign-in')}
        >Back to Login</IonButton>
      </div>
    </>
  )
}

export default AuthRegister;
