import React from 'react';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// components
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonList, IonRadio, IonRadioGroup
} from '@ionic/react';
import { useIntl } from 'react-intl';
import AccountHeader from '../components/AccountHeader';

import { locales } from '../components/Intl';

const AccountLanguage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const currentLocale = useSelector((state: any) => state.firebase.profile?.lang || intl.locale);

  const changeLocale = (lang: string) => {
    dispatch({ type: 'SET_LOCALE', payload: lang });
  }

  return (
    <IonPage>
      <AccountHeader page="language" />
      <IonContent>      
        <IonList>
          <IonRadioGroup value={currentLocale} onIonChange={e => changeLocale(e.detail.value)}>
          { locales.map(lang => (
            <IonItem key={lang.id}>
              <IonLabel>{ lang.title }</IonLabel>
              <IonRadio slot="end" value={lang.id} mode="ios" />
            </IonItem>
          ))}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default AccountLanguage;
