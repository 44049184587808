import React from 'react';

// components
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonImg
} from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';

import './CardGridItem.scss';
import { FormattedMessage } from 'react-intl';

type CardGridItem = {
  id: string;
  className?: string;
  visualsUrl?: string;
  title: string;
  description?: string;
  favorite?: boolean;
  onFavorite?: Function;
  onReadMore?: Function;
}

const CardGridItem = ({ id, className, visualsUrl, title, description, favorite = false, onFavorite, onReadMore }: CardGridItem) => {
  return (
    <IonCard className={'card-grid-item card-grid-item--card ' + className} key={id}>
      { visualsUrl &&
        <IonCardHeader>
          { onFavorite &&
            <IonButton
              shape="round"
              color="light"
              className="card-grid-item--favorite-button"
              onClick={() => onFavorite()}
            >
              <IonIcon icon={favorite ? star : starOutline} slot="icon-only" color="dark" />
            </IonButton>
          }
          <IonImg src={visualsUrl} className="card-grid-item--visuals" />
        </IonCardHeader>
      }
      <IonCardContent>
        <IonCardTitle className="card-grid-item--title">{ title }</IonCardTitle>
        <div className="card-grid-item--description" dangerouslySetInnerHTML={{ __html: description || '' }}></div>
        { onReadMore &&
          <div className="card-grid-item--button-container">
            <IonButton
              className="card-grid-item--button blue-style"
              onClick={() => onReadMore()}
            >
              <FormattedMessage
                id="button.read-more"
                defaultMessage="Read More"
                description=" Read More button label "
              />
            </IonButton>
          </div>
        }
      </IonCardContent>
    </IonCard>
  )
}

export default CardGridItem;
