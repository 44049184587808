import { RequestState } from "./RequestState";

export type TaxonomyTerm = {
    value: string;
    name: string;
    parentvalue?: string;
    color?: string;
}

export type Taxonomy = {
  value: string;
  terms: TaxonomyTerm[]
}

export type TaxonomyTermState = {
  item: TaxonomyTerm;
  state: RequestState;
}

export type TaxonomiesState = {
  items: { [value: string]: TaxonomyTerm };
  state: RequestState;
};

export const newTerm = {
  value: '',
  name: ''
};

export const taxonomiesNames: { [id: string]: string } = {
  'actionRequired': 'Action Required',
  'tags': 'Tags',
  'region': 'Region'
}


type TStaticTaxonomies = {
  [name: string]: {
    name: string,
    terms: {
      value: string,
      name: string,
      parentvalue?: string
      color?: string
    }[]
  }
}

export const staticTaxonomies: TStaticTaxonomies = {
  category: {
    name: 'Category',
    terms: [
      {
        value: 'cat1',
        name: 'Cat1'
      },
      {
        value: 'cat2',
        name: 'Cat2'
      },
      {
        value: 'cat3',
        name: 'Cat3'
      },
      {
        value: 'cat4',
        name: 'Cat4'
      },
      {
        value: 'autres',
        name: 'Autres'
      },
      {
        value: 'projets-e22',
        name: 'Projets E22'
      },
      {
        value: 'cancelled',
        name: 'Retirés'
      },
      {
        value: 'merged',
        name: 'Fusionnés'
      },
      {
        value: 'label',
        name: 'Label '
      }
    ]
  },
  region: {
    name: 'Region',
    terms: [
      {
        value: 'esch22',
        name: 'Territoire Esch2022',
      },
      {
        value: 'esch22-prosud',
        name: 'PROSUD',
        parentvalue: 'esch22'
      },
      {
        value: 'esch22-prosud-bettembourg',
        name: 'Bettembourg',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-differdange',
        name: 'Differdange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-dudelange',
        name: 'Dudelange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-eschalzette',
        name: 'Esch-sur-Alzette',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-mondercange',
        name: 'Mondercange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-kaerjeng',
        name: 'Käerjeng',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-kayl',
        name: 'Kayl',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-petange',
        name: 'Pétange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-rumelange',
        name: 'Rumelange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-sanem',
        name: 'Sanem',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-prosud-schifflange',
        name: 'Schifflange',
        parentvalue: 'esch22-prosud'
      },
      {
        value: 'esch22-ccphva',
        name: 'CCPHVA',
        parentvalue: 'esch22'
      },
      {
        value: 'esch22-ccphva-audunTiche',
        name: 'Audun-le-Tiche',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-aumetz',
        name: 'Aumetz',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-boulange',
        name: 'Boulange',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-ottange',
        name: 'Ottange',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-redange',
        name: 'Rédange',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-russange',
        name: 'Russange',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-thil',
        name: 'Thil',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'esch22-ccphva-villerupt',
        name: 'Villerupt',
        parentvalue: 'esch22-ccphva'
      },
      {
        value: 'europe',
        name: 'Europe',
      },
      {
        value: 'europe-kaunas',
        name: 'Kaunas',
        parentvalue: 'europe'
      },
      {
        value: 'europe-timisoara',
        name: 'Timisoara',
        parentvalue: 'europe'
      },
      {
        value: 'europe-elfsina',
        name: 'Elfsina',
        parentvalue: 'europe'
      },
      {
        value: 'europe-novisad',
        name: 'Novi Sad',
        parentvalue: 'europe'
      },
      {
        value: 'europe-vesprem',
        name: 'Vesprem',
        parentvalue: 'europe'
      },
      {
        value: 'europe-tempere',
        name: 'Tempere',
        parentvalue: 'europe'
      },
      {
        value: 'europe-tartu',
        name: 'Tartu',
        parentvalue: 'europe'
      }
    ]
  },
  discipline1: {
    name: 'discipline I',
    terms: [
      {
        value: 'autre',
        name: 'Autres, Pluridisciplinaire',
        color: '#FCB900'
      },
      {
        value: 'historyNatureHerit',
        name: 'Histoire, Nature, Patrimoine',
        color: '#f5f5dc'
      },
      {
        value: 'filmLit',
        name: 'Film, Littérature',
        color: '#ffff00'
      },
      {
        value: 'music',
        name: 'Musique',
        color: '#ff0000'
      },
      {
        value: 'theatreDanseArtPerf',
        name: 'Théâtre, Danse, Arts Performatifs',
        color: '#ffa500'
      },
      {
        value: 'artDesign',
        name: 'Art, Design, Architecture'
      }
    ]
  },
  discipline2: {
    name: 'discipline II',
    terms: [
      {
        value: 'festival',
        name: 'Festival'
      },
      {
        value: 'concert',
        name: 'Concert'
      },
      {
        value: 'exposition',
        name: 'Exposition'
      },
      {
        value: 'performance',
        name: 'Performance'
      },
      {
        value: 'itinerant',
        name: 'Itinérant'
      },
      {
        value: 'architecture',
        name: 'Architecture'
      },
      {
        value: 'artsRue ',
        name: 'Arts de la rue '
      },
      {
        value: 'ArtsNumerique',
        name: 'Arts numérique'
      },
      {
        value: 'artsPlastique',
        name: 'Arts plastique'
      },
      {
        value: 'artsVisuels ',
        name: 'Arts visuels '
      },
      {
        value: 'danse',
        name: 'Danse'
      },
      {
        value: 'design',
        name: 'Design'
      },
      {
        value: 'filmCinema',
        name: 'Film / Cinéma'
      },
      {
        value: 'jardinNature',
        name: 'Jardin / Nature'
      },
      {
        value: 'foodGastronomie',
        name: 'Food / Gastronomie'
      },
      {
        value: 'dimensionEcologique',
        name: 'Dimension écologique'
      },
      {
        value: 'litterature',
        name: 'Littérature'
      },
      {
        value: 'musiqueClassiqueContemporaine',
        name: 'Musique classique / contemporaine'
      },
      {
        value: 'opera',
        name: 'Opera'
      },
      {
        value: 'musiqueJazz  ',
        name: 'Musique jazz  '
      },
      {
        value: 'musiquePopRockElectro',
        name: 'Musique pop / rock / électro'
      },
      {
        value: 'patrimoine',
        name: 'Patrimoine'
      },
      {
        value: 'pluridisciplinaire',
        name: 'Pluridisciplinaire'
      },
      {
        value: 'sport',
        name: 'Sport'
      },
      {
        value: 'theatre',
        name: 'Théâtre'
      },
      {
        value: 'autre',
        name: 'Autre'
      },
      {
        value: 'cirque',
        name: 'Cirque'
      },
      {
        value: 'conference',
        name: 'Conference'
      },
      {
        value: 'photographie',
        name: 'Photographie'
      },
      {
        value: 'scienceTechnologie',
        name: 'Science & technologie'
      }
    ]
  },
  publicTarget: {
    name: 'Public Target',
    terms: [
      {
        value: 'enfants',
        name: 'Enfants'
      },
      {
        value: 'jeune',
        name: 'Jeune'
      },
      {
        value: 'adulte',
        name: 'Adulte'
      },
      {
        value: 'senior',
        name: 'Senior'
      },
      {
        value: 'tous',
        name: 'Tous'
      },
      {
        value: 'participatif',
        name: 'Participatif'
      },
      {
        value: 'scolaire',
        name: 'Scolaire'
      },
      {
        value: 'transfrontalier',
        name: 'Transfrontalier'
      },
      {
        value: 'multiculturel',
        name: 'Multiculturel'
      },
    ]
  }
};
