import React from 'react';
import { IonButton, IonFooter, IonToolbar } from '@ionic/react';


type PaginationToolbar = {
  currentPage: number;
  pagesNumber: number;
  onFirst?: Function;
  onPrevious: Function;
  onNext: Function;
  onLast?: Function;
}

const PaginationToolbar = ({ currentPage, pagesNumber, onFirst, onPrevious, onNext, onLast }: PaginationToolbar) => {
  return (
    <IonFooter className="ion-no-border">
      <IonToolbar>
        <section className="bottom-controls bottom-controls--right">
          <div>
            { onFirst &&
              <IonButton
                onClick={() => { onFirst(); }}
                className="blue-style shorter"
                disabled={currentPage < 1}
              >&laquo;</IonButton>
            }
            <IonButton
              className="blue-style shorter"
              onClick={() => { if (onPrevious) { onPrevious(); } }}
              disabled={currentPage < 1}
            >&lsaquo;</IonButton>
            <span className="bottom-controls--pages">Page { currentPage + 1 } of { pagesNumber }</span>
            <IonButton
              className="blue-style shorter"
              onClick={() => { if (onNext) { onNext(); } }}
              disabled={currentPage + 1 >= pagesNumber}
            >&rsaquo;</IonButton>
            { onLast &&
              <IonButton
                className="blue-style shorter"
                onClick={() => { onLast(); }}
                disabled={currentPage + 1 >= pagesNumber}
              >&raquo;</IonButton>
            }
          </div>
        </section>
      </IonToolbar>
    </IonFooter>
  )
}

export default PaginationToolbar;
