import React, { useState } from 'react';

// hooks
import { useFirestore } from 'react-redux-firebase';

// components
import ModalForm from "../../components/ModalForm";
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';

// models
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

type PermissionModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
}

const ProjectCreateModal = (props: PermissionModalProps) => {
  // loading permissions
  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});
  // loading last project id, to use the next number for the new one
  const lastProjectId = useSelector((state: any) => state.firestore.data?.system?.projects?.lastId);
  // get the user's groupId
  const gid = useSelector((state: any) => state.firebase.profile.groupId);
  const uid = useSelector((state: any) => state.firebase.auth.uid);

  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState({ title: '' });

  const resetForm = () => {
    setFormData({ title: '' });
  }

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // function that is saving the data
  const firestore = useFirestore();
  const history = useHistory();
  const createItem = () => {
    const newProjectId = (parseInt(lastProjectId, 10) + 1).toString();
    // checking the permission first
    if (permissions['projects.group.create']) {

      // clearing undefined values — Firestore don't like theese
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key]
        }
      });

      // checking the name of the new project
      if (formData.title === '') {
        formData.title = 'Untitled Project ' + newProjectId;
      }

      // sending to the firestore
      firestore
        .collection('projects')
        .doc(newProjectId)
        .set({
          pid: newProjectId,
          ...formData,
          access: { [gid]: 'author' },
          moderation_state: 'draft',
          editable: true,
          contactType: 'private',
          partners: false,
          date: new Date().toISOString(),
          uid
        })
        .then(() => {
          // update the last project id
          firestore.collection('system').doc('projects').update({ lastId: newProjectId });
          // clears the data from the form
          resetForm();
          // closes the modal
          props.setOpened(false);
          // redirects to the edit route for the new project
          history.push(`/projects/${newProjectId}/edit`);
        });
    }
  }

  const intl = useIntl();

  return (
    <ModalForm
      {...props}
      title= { intl.formatMessage({
        id: 'modal.create-project.title',
        defaultMessage: 'Create a New Project',
        description: 'Create Projects modal title',
      }) }
    >
      <IonList>
        <IonItem>
          <IonLabel>
            <p className="description">
              <FormattedMessage
                id="modal.create-project.description"
                defaultMessage="Creating a project is a long procedure. Later you will be able to give numerous answers that we need.
                But for now, let's start with giving your project a name."
                description="Create Projects short help text"
              />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            <FormattedMessage
              id="form.project-name"
              defaultMessage="Project Name"
              description="Project Name field label"
            />
          </IonLabel>
          <IonInput
            value={formData.title}
            onIonChange={e => updateData('title', e.detail.value)}
            name="name"
            type="text"
            inputmode="text"
            enterkeyhint="next"
            clearInput
          ></IonInput>
        </IonItem>
        <div className="bottom-controls">
          <IonButton
            expand="block"
            onClick={createItem}
            className="blue-style"
          >
            <FormattedMessage
              id="button.create-project"
              defaultMessage="Create the Project"
              description="Create project button label"
            />
          </IonButton>
        </div>
      </IonList>
    </ModalForm>
  )
}

export default ProjectCreateModal;
