import React, { useEffect, useState } from 'react';
import { getYouTubeId } from '../utils';

// hooks
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow
} from '@ionic/react';
import ModalForm from '../components/ModalForm';
import YouTube from 'react-youtube';

// icons and types
import { star, starOutline } from 'ionicons/icons';
import { Project } from '../models/Project';

import './SponsorModal.scss';
import { FormattedMessage } from 'react-intl';


const partnershipTypes = [
  { name: 'Financier', value: 'financial' },
  { name: 'En nature', value: 'natural' },
  { name: 'De compétences', value: 'experience' },
  { name: 'Autre', value: 'other' }
];

type TProjectSponsoringModal = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  project: Project;
  visualsUrl?: string;
  hideFavorites?: boolean;
}

const ProjectSponsoringModal = ({ project, visualsUrl, hideFavorites, ...props }: TProjectSponsoringModal) => {
  const firebase = useFirebase();

  const [ filesUrls, setFilesUrls ] = useState<{ [fileId: string]: string }>({});
  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setFilesUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }
  useEffect(() => {
    if (project.sponsoring?.documents?.length) {
      project.sponsoring.documents.forEach(file => {
        getDownloadUrl(file.id, file.fullPath);
      });
    }
    if (project.sponsoring?.budget?.length) {
      project.sponsoring.budget.forEach(file => {
        if (file.fullPath) {
          getDownloadUrl((file.id)!, file.fullPath);
        }
      });
    }
    // eslint-disable-next-line
  }, [project]);


  // favorites
  const favorites = useSelector((state: any) => state.firebase.profile?.favorites);
  const toggleFavorite = (pid: string) => {
    firebase.updateProfile({
      favorites: {
        ...favorites,
        [pid]: favorites ? !favorites[pid] : true
      }
    });
  }

  return (
    <ModalForm {...props} title={ project.title || 'Untitled Project' } className="sponsor-modal-desktop">
      <div className="sponsor-modal">
        { !hideFavorites &&
          <IonButton
            shape="round"
            color="light"
            className="sponsor-modal--favorite-button"
            onClick={() => toggleFavorite(project.pid)}
          >
            <IonIcon icon={favorites && favorites[project.pid] ? star : starOutline} slot="icon-only" color="dark" />
          </IonButton>
        }

        { project?.sponsoring?.youTubeLink
          ? <YouTube videoId={getYouTubeId(project.sponsoring.youTubeLink)} opts={{ width: '100%', height: '500' }} />
          : visualsUrl && <IonImg src={visualsUrl} className="sponsor-modal--visuals" />
        }
        <div className="sponsor-modal--presentation-panel">
          { project.sponsoring?.description && project.sponsoring.description !== '<p></p>' &&
            <div className="sponsor-modal--description" dangerouslySetInnerHTML={{ __html: project.sponsoring.description || '' }}></div>
          }

          <IonGrid>
            <IonRow>
              { project.sponsoring?.documents?.length &&
                <IonCol>
                  <h3>
                    <FormattedMessage
                      id= "form.doc.sponsorship"
                      defaultMessage= 'Sponsorship files'
                      description= "Sponsorship Document file field label"
                    />
                  </h3>
                  { project.sponsoring.documents.map(file => (
                      <p key={file.id}>
                        <a href={filesUrls[file.id]} target="blank">{ file.name }</a>
                      </p>
                    ))
                  }
                </IonCol>
              }
              { project.sponsoring?.budget?.length &&
                <IonCol>
                  <h3>
                    <FormattedMessage
                      id= 'project.title.13'
                      defaultMessage= 'Budget'
                      description= 'Project section title'
                    />
                  </h3>
                  { project.sponsoring.budget.map(file => (
                      <p key={file.id}>
                        <a href={filesUrls[(file.id)!]} target="blank">{ file.name }</a>
                      </p>
                    ))
                  }
                </IonCol>
              }
            </IonRow>
            { (project.sponsoring?.contactPerson || project.sponsoring?.contactEmail) &&
              <IonRow>
                { project.sponsoring?.contactPerson &&
                  <IonCol>
                    <h3>
                      <FormattedMessage
                        id= "form.contact-person"
                        defaultMessage= 'Contact Person'
                        description= "Input field label"
                      />
                    </h3>
                    { project.sponsoring?.contactPerson }
                  </IonCol>
                }
                { project.sponsoring?.contactEmail &&
                  <IonCol>
                    <h3>
                      <FormattedMessage
                        id= "form.email"
                        defaultMessage= "Email address"
                        description="Email field on all form"
                      />
                    </h3>
                    { project.sponsoring?.contactEmail }
                  </IonCol>
                }
              </IonRow>
            }
            { (project.sponsoring?.contactPhone) &&
              <IonRow>
                <IonCol>
                  <h3>
                    <FormattedMessage
                      id= "form.phone"
                      defaultMessage= 'Phone number'
                      description= "Phone number field on all form"
                    />
                  </h3>
                  { project.sponsoring?.contactPhone }
                </IonCol>
              </IonRow>
            }
          </IonGrid>

          <div className="sponsor-modal--data">
            <h3>
              <FormattedMessage
                id='form.partnership'
                defaultMessage='Type of partnership'
                description='2nd label for required input fields'
              />
            </h3>
            <p>{
              partnershipTypes
                .filter(item => project.sponsoring?.type?.find(type => type === item.value))
                .map(item => item.name)
                .join(', ')
            }</p>
            { project.sponsoring?.partnershipDetails &&
              <>
                <h3>
                  <FormattedMessage
                    id="form.partnership-details"
                    defaultMessage="Details of the partnership"
                    description="Details of the partnership field label"
                  />
                </h3>
                <p dangerouslySetInnerHTML={{ __html: project.sponsoring?.partnershipDetails }}></p>
              </>
            }
          </div>


          { project.sponsoring?.remarks &&
            <div className="sponsor-modal--data">
              <h3>
                <FormattedMessage
                  id= "form.additional-information"
                  defaultMessage= 'Additional information'
                  description= "Additional information field label"
                />
              </h3>
              <p dangerouslySetInnerHTML={{ __html: project.sponsoring?.remarks }}></p>
            </div>
          }

        </div>

      </div>
    </ModalForm>
  )
}

export default ProjectSponsoringModal;
