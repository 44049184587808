import React from 'react';
import { useFirebase } from 'react-redux-firebase';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';


export const accountTopNavigationItems = [
  {
    id: 'profile-details',
    title: 'Profile details',
    href: '/account/profile-details'
  },
  {
    id: 'change-password',
    title: 'Change password',
    href: '/account/change-password'
  },
  {
    id: 'notifications',
    title: 'Notifications',
    href: '/account/notifications'
  }
]

// export const getAccountTopNavigationItems = (intl: IntlShape) => {
//   return [
//     {
//       id: 'profile-details',
//       title: intl.formatMessage({
//         id: 'account.details',
//         defaultMessage: 'Profile details',
//         description: 'Profile Details nav item',
//       }),
//       href: '/account/profile-details'
//     },
//     {
//       id: 'change-password',
//       title: intl.formatMessage({
//         id: 'button.change-password',
//         defaultMessage: 'Change password',
//         description: 'Change Password button label',
//       }),
//       href: '/account/change-password'
//     },
//     {
//       id: 'notifications',
//       title: intl.formatMessage({
//         id: 'account.notifications',
//         defaultMessage: 'Notifications',
//         description: 'Notifications nav item',
//       }),
//       href: '/account/notifications'
//     }
//   ]
// }

const Account: React.FC = () => {

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  const firebase: any = useFirebase();
  function signOut() {
    firebase.logout();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="account.title"
              defaultMessage="Account"
              description="Account section title"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <FormattedMessage
                id="account.title"
                defaultMessage="Account"
                description="Account section title"
              />
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          { permissions['companies.group.read'] &&
            <IonItem button routerLink={'/account/company'}>
              <IonLabel>
              <FormattedMessage
                id="form.company"
                defaultMessage="Company"
                description="Company field on all form"
              />
              </IonLabel>
            </IonItem>
          }
          <IonItem button routerLink={'/account/profile-details'}>
            <IonLabel>
              <FormattedMessage
                id="account.details"
                defaultMessage="Profile Details"
                description="Profile Details nav item"
              />
            </IonLabel>
          </IonItem>
          <IonItem button routerLink={'/account/change-password'}>
            <IonLabel>
              <FormattedMessage
                id="button.change-password"
                defaultMessage="Change Password"
                description="Change Password button label"
              />
            </IonLabel>
          </IonItem>
          { permissions['settings.notifications.own.edit'] &&
            <IonItem button routerLink={'/account/notifications'}>
              <IonLabel>
                <FormattedMessage
                  id="account.notifications"
                  defaultMessage="Notifications"
                  description="Notifications nav item"
                />
              </IonLabel>
            </IonItem>
          }
          <IonItem button routerLink={'/account/language'}>
            <IonLabel>
              <FormattedMessage
                id="account.language"
                defaultMessage="Language"
                description="Language nav item"
              />
            </IonLabel>
          </IonItem>
          <IonItem button onClick={signOut} detail={false} lines="none">
            <IonLabel color="danger">
              <FormattedMessage
                id="button.sign-out"
                defaultMessage="Sign Out"
                description="Button sign out"
              />
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Account;
