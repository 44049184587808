import React, { useEffect, useMemo, useRef, useState } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useFirebase } from 'react-redux-firebase';
import { useLocation } from 'react-router';

// components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import CardGrid from '../components/CardGrid';
import CardGridItem from '../components/CardGridItem';
import TopNavigation from '../components/TopNavigation';
import SponsorModal from '../modals/SponsorModal';

// types
import { CompaniesState, Company, getCompanyActivityFields } from '../models/Company';
import { FormattedMessage, useIntl } from 'react-intl';


const SponsorsList = () => {
  const pageRef = useRef();
  const firebase = useFirebase();
  const { pathname } = useLocation();
  const page = pathname === '/sponsors/favorites' ? 'favorites' : 'all';

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});


  // favorites
  const favorites = useSelector((state: any) => state.firebase.profile?.favorites);
  const toggleFavorite = (sponsorId: string) => {
    firebase.updateProfile({
      favorites: {
        ...favorites,
        [sponsorId]: favorites ? !favorites[sponsorId] : true
      }
    });
  }


  // load sponsors
  const sponsors = useFirestoreCollectionQuery(
    'companies', false,
    permissions['companies.all.read']
    ? {} 
    : permissions['companies.published.read']
      ? { where: ['published', '==', true], storeAs: 'sponsors' }
      : { where: ['nothing', '==', 'everything'] }
  ) as CompaniesState;


  // retrieve logo urls
  const [ logoUrls, setLogoUrls ] = useState<{ [gid: string]: string }>({});

  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setLogoUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }

  useEffect(() => {
    if (sponsors.items) {
      Object.keys(sponsors.items).forEach(sponsorId => {
        const logo = sponsors.items[sponsorId]?.logo;
        if (logo) {
          logo.forEach(file => {
            if (file.fullPath) {
              getDownloadUrl(sponsorId, file.fullPath);
            }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [sponsors.items]);

  const intl = useIntl();
  const companyActivityFields = useMemo( () => getCompanyActivityFields(intl),
  // eslint-disable-next-line
  [intl.locale]);

  // grouping sponsors by activity field value
  const sponsorGroups = useMemo(() => {
    if (sponsors.items) {
      // grouping sponsors by type
      const groupedSponsors = Object.keys(sponsors.items)
        .filter(sponsorId => favorites && page === 'favorites' ? favorites[sponsorId] : true)
        .map((sponsorId: string) => sponsors.items[sponsorId])
        .reduce((acc, item) => ({
          ...acc,
          [item.activity]: [ ...(acc[item.activity] || []), item ]
        }), {} as { [activity: string]: any });

      // putting grouped sponsors in the groups
      return companyActivityFields
        .filter(activity => groupedSponsors[activity.value])
        .map(activity => ({
          name: activity.name,
          value: activity.value,
          sponsors: groupedSponsors[activity.value]
        }));
    }
    return [];
  }, 
  // eslint-disable-next-line
  [sponsors.items, page, favorites]);


  // modals
  const [ sponsorOpened, setSponsorOpened ] = useState(false);
  const [ selectedSponsorId, setSelectedSponsorId ] = useState('');

  const openSponsorModal = (sponsorId: string) => {
    setSelectedSponsorId(sponsorId);
    // set modal as opened
    setSponsorOpened(true);
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="nav.sponsors"
              defaultMessage="Sponsors"
              description="Sponsors nav button"
            />
          </IonTitle>
          <TopNavigation items={[
              {
                id: 'all',
                title: intl.formatMessage({
                  id: 'topNavigation.all',
                  defaultMessage: 'All',
                  description: 'Top navigation view all button',
                }),
                href: '/sponsors',
              },
              {
                id: 'favorites',
                title: intl.formatMessage({
                  id: 'topNavigation.favorites',
                  defaultMessage: 'Favorites',
                  description: 'Top navigation favorites button',
                }),
                href: '/sponsors/favorites'
              }
            ]}
            selected={page}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="sponsors-grid">

        { selectedSponsorId &&
          <SponsorModal
            opened={sponsorOpened}
            setOpened={setSponsorOpened}
            pageRef={pageRef.current}
            sponsor={sponsors.items[selectedSponsorId]}
            visualsUrl={logoUrls[selectedSponsorId]}
          />
        }
        { (sponsors.state.isNotLoaded || sponsors.state.isLoading)
          ? <LoadingScreen />
          : sponsors.state.isEmpty
            ? <p>
                <FormattedMessage
                  id="sponsors.list-empty"
                  defaultMessage="No Available Sponsors"
                  description="Text when no available sponsor"
                />
              </p>
            : sponsorGroups.map(group => (
                <CardGrid title={group.name} key={group.value}>
                  { group.sponsors.map((sponsor: Company) => (
                      <CardGridItem key={sponsor.id}
                        id={sponsor.id}
                        className="company-card"
                        visualsUrl={logoUrls[sponsor.id]}
                        title={sponsor.name || 'Untitled Company'}
                        description={sponsor.cardDescription}
                        favorite={favorites ? favorites[sponsor.id] : false}
                        onFavorite={() => toggleFavorite(sponsor.id)}
                        onReadMore={() => openSponsorModal(sponsor.id)}
                      />
                    ))
                  }
                </CardGrid>
              ))
        }
      </IonContent>
    </IonPage>
  )
}

export default SponsorsList;
