import React, { useMemo } from 'react';

// hooks
import { useFirestoreCollectionQuery } from '../../hooks/useFirestoreCollectionQuery';
import { useHistory } from 'react-router';

// types and styles
import { Project, getProjectModerationStatus } from '../../models/Project';
import { TaxonomiesState, staticTaxonomies } from '../../models/Taxonomies';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';

type dashboardProject = {
  projects: Project[];
  sortField: string;
  sortDirection: number;
  onSortFieldChange: Function;
}
const ProjectDashboard = ( { projects, sortField, sortDirection, onSortFieldChange }: dashboardProject  ) => {

  // function to change route to given url
  const history = useHistory();
  const goto = (url: string) => {
    history.push(url);
  }
  
  // load editable taxonomies
  const actionRequired = useFirestoreCollectionQuery( 'actionRequired', false) as TaxonomiesState;
  const tags = useFirestoreCollectionQuery( 'tags', false) as TaxonomiesState;
  const region = useFirestoreCollectionQuery( 'region', false) as TaxonomiesState;

  const displayArray = (array: any, staticTerm?: string, prefix?: string) => {
    return array.map((termId: string) => (
      <React.Fragment key={termId}>
        <span className={`term term-${termId}`}>{ prefix }{ getTermName(termId, staticTerm) }</span>
        {' '}
      </React.Fragment>
    ));
  }

  const displayAuthorName = ( project: Project ) => {
    const authorName = `${ project.lastName  || '' } ${ project.firstName || '' }`;
    switch ( project.contactType ) {
      case 'others':
        return `${ project.structureName || ''  }${ authorName !== ' ' ? ': ' + authorName : '' }`;
      case 'asbl':
        return `${ project.officialName || ''  }${ authorName !== ' ' ? ': ' + authorName : '' }`;
      case 'commune':
        return `${ project.municipality || ''  }${ authorName !== ' ' ? ': ' + authorName : '' }`;
      case 'private':
        return authorName;
      default:
        return project.contactType;
    }
  }

  const getTermName = ( termId: any, staticTerm?: string ) => {
    if (staticTerm) {
      const term = staticTaxonomies[staticTerm].terms.find(item => item.value === termId)
      return term ? (<span className={`term term-${term.value}`}>{ term.name }</span>) : ''
    } else if ( actionRequired.state.isLoaded && termId in actionRequired.items ){
      return actionRequired.items[termId].name;
    } else if ( tags.state.isLoaded && termId in tags.items ){
      return tags.items[termId].name;
    }else if ( region.state.isLoaded && termId in region.items ){
      return region.items[termId].name;
    }
  }

  const displaySortingSign = ( fieldProperty: string ) => {
    if (sortField === fieldProperty ) {
      return sortDirection > 0 ? <span>&#8593;</span> : <span>&#8595;</span>;
    }
    return null;
  }

  const intl = useIntl();
  const projectModerationStatus = useMemo(
    () => getProjectModerationStatus(intl),
    // eslint-disable-next-line
    [intl.locale]
  );

  return (
    <div className="table-container">
      <table className="projects-table">
        <thead>
          <tr>
            <th><button type='button' onClick={ () => onSortFieldChange('pid') }>Id</button>{ displaySortingSign('pid') }</th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange('title') }>
                <FormattedMessage
                  id='form.project-name'
                  defaultMessage='Project Name'
                  description='Project Name field label'
                />
              </button>{ displaySortingSign('title') }
              {' / '}
              <FormattedMessage
                id='form.author'
                defaultMessage='Author'
                description='Author field label'
              />
            </th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange('category') }>
                <FormattedMessage
                  id='form.category'
                  defaultMessage='Category'
                  description='Project revision form category field label'
                />
              </button>{ displaySortingSign('category') }
            </th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange('deadline') }>
                <FormattedMessage
                  id='form.deadline'
                  defaultMessage='Deadline'
                  description='Project revision form deadline field label'
                />
              </button>{ displaySortingSign('deadline') }
            </th>
            <th>
              <FormattedMessage
                id='form.action-required'
                defaultMessage='Action Required'
                description='Project revision form Action Required field label'
              />
            </th>
            <th>
              <FormattedMessage
                id='form.tags'
                defaultMessage='Tags'
                description='Project revision form Tags field label'
              />
            </th>
            <th>
              <FormattedMessage
                id='form.region'
                defaultMessage='Region'
                description='Project revision form Region field label'
              />
            </th>
            <th>
              <FormattedMessage
                id='form.disciplines'
                defaultMessage='Disciplines'
                description='Project revision form Disciplines field label'
              />
            </th>
            <th>
              <FormattedMessage
                id='form.public-target'
                defaultMessage='Public Target'
                description='Project revision form Public Target field label'
              />
            </th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange( 'moderation_state' ) }>
                <FormattedMessage
                id='form.status'
                defaultMessage='Status'
                description='Project revision form status field label'
              />
              </button>{ displaySortingSign('moderation_state') }
            </th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange('date') }>
                <FormattedMessage
                  id='form.modified'
                  defaultMessage='Modified'
                  description='Project revision form Modified field label'
                />
              </button>{ displaySortingSign('date') }
            </th>
            <th>
              <button type='button' onClick={ () => onSortFieldChange('sponsoringCompletionRate') }>
                <FormattedMessage
                  id='topNavigation.sponsoring'
                  defaultMessage='Partnerships'
                  description='Project revision form Partnership field label'
                />
              </button>{ displaySortingSign('sponsoringCompletionRate') }
            </th>
          </tr>
        </thead>
        <tbody>
          { projects
              .map(( project ) => (
                <tr key={ project.pid }>
                  <td data-column="Id: ">{ parseInt(project.pid, 10) }</td>
                  <td data-column="Project Name: ">
                    <h2 className="projects-table--link" onClick={() => goto(`/projects/${project.pid}`)}>{ project.title }</h2>
                    { project.contactType && displayAuthorName( project ) !== ' ' &&
                      <p>{ displayAuthorName( project ) }</p>
                    }
                  </td>
                  <td data-column="Category: ">{ project.category ? getTermName( project.category, 'category' ) : null }</td>
                  <td className="projects-table--date-cell" data-column="Deadline: ">
                    { project.deadline &&
                      <FormattedDate value={project.deadline} dateStyle="medium" />
                    }
                  </td>
                  <td className="projects-table--action" data-column="Action required: ">
                    { project.actionRequired ? displayArray( project.actionRequired ) : null }
                  </td>
                  <td data-column="Tags: ">{ project.tags ? displayArray( project.tags, undefined, '#' ) : null }</td>
                  <td data-column="Region: ">{ project.region ? displayArray( project.region ) : null}</td>
                  <td data-column="Disciplines ">
                    <div className="terms">{ getTermName( project.discipline1, 'discipline1') }</div>
                    <div className="terms">{ project.discipline2 && displayArray( project.discipline2, 'discipline2') }</div>
                  </td>
                  <td data-column="Public: ">{ project.publicTarget ? displayArray( project.publicTarget, 'publicTarget') : null}</td>
                  <td data-column="Status: ">{ projectModerationStatus[project.moderation_state] }</td>
                  <td className="projects-table--date-cell" data-column="Modified: ">
                    { project.date &&
                      <>
                        <FormattedDate value={project.date} dateStyle="medium" /><br />
                        <FormattedTime value={project.date} hour="numeric" minute="numeric" />
                      </>
                    }
                  </td>
                  <td data-column="Partnerships: " className="projects-table--sponsoring-cell">
                    <p>
                      {
                        project.sponsoring
                        ? project.sponsoringCompletionRate === '0'
                          ? null
                          : project.sponsoringCompletionRate + '%'
                        : null
                      }
                    </p>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default ProjectDashboard;
