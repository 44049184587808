import { IonPage, IonContent, IonItem, IonLabel, IonList } from '@ionic/react';
import firebase from 'firebase';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import LoadingScreen from '../components/LoadingScreen';
import ProjectDataValue from '../components/projects/ProjectDataValue';
import ProjectHeader from '../components/projects/ProjectHeader';
import RevisionsDropdown, { RevisionsState } from '../components/RevisionsDropdown';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { getProjectStructure } from '../models/Project';
import { filterProjectFieldsByDependancies } from './Project';

import './ProjectRevisionview.scss';

const ProjectRevisionview = () => {
    
    const intl = useIntl();
    // get current projectid
    const { pid } = useParams<{ pid: string }>();
    const project = useCollectionItem('projects', pid);
    // get current revision id
    const { vid } = useParams<{ vid: string }>();
    // load revisions for the current project
    const revisions = useFirestoreCollectionQuery(`projects/${pid}/revisions`, false, { storeAs: `revisions-${pid}` }) as RevisionsState;
    const needsReviewRevisions = useMemo(() => {
        if (revisions.items) {
            return Object.keys( revisions.items )
                .map( key => revisions.items[key] )
                .filter( revision => revision )
                .sort((revision1, revision2 ) => revision1.date > revision2.date ? -1 : 1)
                .filter((revision ) => revision.moderation_state === 'needs_review');
        } else {
            return [];
        }
    }, [revisions.items]);

    const revision = useSelector((state: any) => state.firestore?.data[`revisions-${pid}`] ? state.firestore.data[`revisions-${pid}`][`${vid}`] : null );
    // get project structure
    const ProjectStructure = useMemo(() => getProjectStructure(intl),
    // eslint-disable-next-line
    [intl.locale]);

    // get download urls for revisions
    const [ fileUrls, setFileUrls ] = useState<{ [fileId: string]: any }>({});
    const getDownloadUrl = (fileId: string, fullPath: string) => {
        const storageRef = firebase.storage().ref(fullPath);
        storageRef.getDownloadURL()
        .then((downloadUrl: string) => {
            setFileUrls(state => ({ ...state, [fileId]: downloadUrl }));
        });
    }
    useEffect(() => {
        if ( revision ) {
        ProjectStructure.forEach(section => {
            if (section.fields) {
            section.fields.forEach(field => {
                if (field.type === 'files' && revision[field.name]) {
                revision[field.name].forEach((file: any) => {
                    if (file.fullPath) {
                    getDownloadUrl(file.fid || file.id, file.fullPath);
                    }
                });
                }
            });
            }
        });
        }
        // eslint-disable-next-line
    }, [revision]);

    
    
    return (
        <IonPage>
            <ProjectHeader pid={pid} page="revisions"/>
            <IonContent>
            
                { (project.state.isNotLoaded || project.state.isLoading || !revision )
                    ? <LoadingScreen />
                    :
                    <>
                        <RevisionsDropdown revisions={needsReviewRevisions} selected={vid}/>
                        {
                            ProjectStructure.map((section, idx) => (
                                <div key={`section${idx}`}>
                                    { section.title && section.title !== '' &&
                                        <h2 className="project--section-title">{ section.number && (section.number + '. ') } { section.title }</h2>
                                    }
                                    <IonList className="project--section">
                                        { section.fields && section.fields
                                            .filter(field => filterProjectFieldsByDependancies(field, project.item))
                                            .map((field, idx) => (
                                            <IonItem key={`field$${field.number}-${idx}`} className="project--section-item">
                                                <IonLabel position="stacked">{ field.number && (field.number + '. ') } { field.title }</IonLabel>
                                                {
                                                    compareProjectFields(field, project.item[field.name], revision[field.name])
                                                    ?
                                                    <div className="revision-data-container">
                                                    <div className="revision-data-added"><ProjectDataValue field={field} value={project.item && project.item[field.name]} fileUrls={fileUrls} /></div>
                                                    <div className="revision-data-deleted"><ProjectDataValue field={field} value={revision && revision[field.name]} fileUrls={fileUrls} /></div>
                                                    </div>
                                                    :
                                                    <ProjectDataValue field={field} value={project.item && project.item[field.name]} fileUrls={fileUrls} />
                                                }
                                            </IonItem>
                                            ))
                                        }
                                    </IonList>
                                </div>
                            ))
                        }
                    </> 
                }   
            </IonContent>
        </IonPage>
    );
}

export default ProjectRevisionview;

export const compareProjectFields = (field: any, recentFieldContent: any, oldFieldContent: any) => {

    switch (field.type) {
        case 'files':
            if ( recentFieldContent?.length !== oldFieldContent?.length ){
                return true;
            }
            if ( recentFieldContent ) {
                recentFieldContent.forEach( (file: any, index: number) => {
                    if( file.fullPath !== oldFieldContent[index].fullPath ){
                        return true;
                    }
                });
            }
            return false;

        case 'rich-text':
            if ( oldFieldContent ) {
                //remove html tags and compare
                let recentText = recentFieldContent.replace(/(<([^>]+)>)/gi, "");
                let oldText = oldFieldContent.replace(/(<([^>]+)>)/gi, "");
                if ( recentText !== oldText ){
                    return true;
                }
                return false;
            }
            if ( recentFieldContent ){
                return true;
            }
            return false;

        case 'checkbox':
            if ( oldFieldContent ) {
                const keys1 = Object.keys(recentFieldContent);
                const keys2 = Object.keys(oldFieldContent);
                if (keys1.length !== keys2.length) {
                    return true;
                }
                for (let key of keys1) {
                    if (recentFieldContent[key] !== oldFieldContent[key]) {
                    return true;
                    }
                    return false;
                } 
            }
            if ( recentFieldContent ){
                return true;
            }
            return false;
        default:
            if ( recentFieldContent !== oldFieldContent ){
                return true;
            }
            return false;
    }
}