import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as fb from 'firebase/app';
import { normString } from '../utils';
import { format } from 'date-fns';

// hooks
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';

// components
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import UserModal from '../modals/UserModal';
import PaginationToolbar from '../components/PaginationToolbar';
import SearchField from '../components/SearchField';
import TopNavigation from '../components/TopNavigation';

import { RolesState } from '../models/Role';
import { User } from '../models/User';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';


// how many items to show on the page
const pageLength = 20;

// which fields are used in search
// we have to set the field names in the type to match the User fields
const searchDataFields: Array<'email' | 'firstName' | 'lastName' | 'role' | 'groupId'> = ['email', 'firstName', 'lastName', 'role',  'groupId'];

const UsersList = () => {
  const pageRef = useRef();

  const users = useSelector((state: any) => state.users) as { [uid: string]: User };
  const unsubscribeFromUsers = useRef<any>();
  const dispatch = useDispatch();
  const [ isLoading, setLoading ] = useState(false);
  useEffect(() => {
    setLoading(true);
    unsubscribeFromUsers.current = fb.firestore()
      .collection('users')
      .onSnapshot((snapshot) => {
        dispatch({
          type: 'UPDATE_USERS',
          payload: snapshot.docs
            .map(doc => doc.data())
            .reduce((acc, item) => ({ ...acc, [item.uid]: item }), {})
        });
        setLoading(false);
      })
    return () => {
      if (unsubscribeFromUsers.current) {
        unsubscribeFromUsers.current();
      }
    }
    // eslint-disable-next-line
  }, []);

  // const users = useFirestoreCollectionQuery('users', true, { orderBy: 'gdpr', storeAs: 'allUsers' }) as UsersOrderedState;
  const roles = useFirestoreCollectionQuery('roles', false, { storeAs: 'allRoles' }) as RolesState;

  const [ userOpened, setUserOpened ] = useState(false);
  const [ selectedUserId, setSelectedUserId ] = useState('');

  // this opens the modal
  const openUserModal = (uid: string) => {
    // setting which user we want to edit
    setSelectedUserId(uid);
    // set modal as opened
    setUserOpened(true);
  }

  // search
  const [ searchData, setSearchData ] = useState<{ [uid: string]: string } | null>(null);
  const prepareDataForSearch = () => {
    if (!searchData) {
      setSearchData(
        Object.keys(users)
          .map(userId => {
            const data = searchDataFields.map(field => normString(users[userId][field])).join('|');
            return {
              id: userId,
              data
            }
          })
          .reduce((acc, item) => ({ ...acc, [item.id]: item.data }), {})
      )
    }
  }

  // role filters
  const [ selectedRole, setSelectedRole ] = useState('all');

  const [ searchValue, setSearchValue ] = useState< string[] >([]);
  const setSearchString = (searchStr: string) => {
    setSearchValue(
      searchStr
        .split(/[\s|,]/)
        .map(str => normString(str))
        .filter(str => str !== '')
    )
  }

  const filterBySearchValue = (user: User) => {
    // if the user was removed, filter it out
    if (!user) return false;
    // check for category filters
    if (selectedRole !== 'all' && user.role !== selectedRole ) return false;
    // if no search string — show everything
    if (!searchValue.length) return true;
    // for each piece of the search string – look for a match
    for (let i = 0, len = (searchValue).length; i < len; i++) {
      if (searchData && searchData[user.uid].indexOf(searchValue[i]) >= 0) {
        return true;
      }
    }
    // return false if nothing matched
    return false;
  }

  // display items
  const resultsArr = useMemo(() => {
    if (users && Object.keys(users).length) {
      return Object.keys(users)
        .map(uid => users[uid])
        .filter(user => user)
        .filter(user => filterBySearchValue(user))
        .sort((user1, user2) => user1.gdpr > user2.gdpr ? 1 : -1);
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [users, searchValue, selectedRole]);

  // pagination
  const [ currentPage, setCurrentPage ] = useState(0);
  const pages = useMemo(() => Math.ceil(resultsArr.length / pageLength), [resultsArr]);
  useEffect(() => setCurrentPage(0), [pages]);


  // online status
  useFirebaseConnect('status');
  const onlineStatus = useSelector((state: any) => state.firebase.data.status);
  const getOnlineStatus = (uid: string) => {
    if (onlineStatus && onlineStatus[uid]) {
      const date = format(new Date(onlineStatus[uid].time), 'dd/MM/yyyy HH:mm');
      if (onlineStatus[uid].state === 'offline') {
        return 'Last seen online: ' + date;
      } else {
        return 'Online since: ' + date;
      }
    } else {
      return null;
    }
  }

  const intl = useIntl();


  const listRef = useRef<any>(null);
  const firebase = useFirebase();
  const history = useHistory();
  const disguiseAsUser = (uid: string, role: string, groupId: string, names: string) => {
      firebase
        .updateProfile({
          disguise: {
            uid,
            role,
            groupId,
            names
          }
        })
        .then(() => {
          if (listRef.current && listRef.current?.closeSlidingItems) {
            listRef.current.closeSlidingItems();
          }
          history.replace('/projects');
        });
      console.log('Disguise as User', uid);
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="nav.users"
              defaultMessage="Users"
              description="Settings nav button"
            />
          </IonTitle>
          <TopNavigation
            items={[
              {
                id: 'all',
                title: intl.formatMessage({
                  id: 'topNavigation.all',
                  defaultMessage: 'All',
                  description: 'Top navigation view all button',
                }),
                onClick: () => setSelectedRole('all')
              },
              ...((roles.items && Object.keys(roles.items)
                .filter(roleId => roles.items[roleId].showInTheFilters)
                .map(roleId => ({
                  id: roleId,
                  title: roles.items[roleId].name,
                  onClick: () => setSelectedRole(roleId)
                }))
              ) || [])
            ]}
            selected={selectedRole}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { selectedUserId &&
          <UserModal opened={userOpened} setOpened={setUserOpened} pageRef={pageRef.current} userId={selectedUserId} />
        }
        { (isLoading || !Object.keys(users).length)
          ? <LoadingScreen />
          : <>
              <h2 className="list-title">
                <span>
                  { resultsArr.length }&nbsp;
                  <FormattedMessage
                    id="nav.users"
                    defaultMessage="Users"
                    description="Settings nav button"
                  />
                </span>
                <SearchField onSearch={setSearchString} onActivate={prepareDataForSearch} />
              </h2>
              <IonList ref={listRef}>
                { !resultsArr.length &&
                  <IonItem>
                    <IonLabel>
                      <h2>
                        <FormattedMessage
                          id="users.title.not-found"
                          defaultMessage="No Users Found"
                          description="Users listing title if no item"
                        />
                      </h2>
                      <p>
                        <FormattedMessage
                          id="companies.description.not-found"
                          defaultMessage="Try to change the filtering criteria."
                          description="Companies listing description if no item"
                        />
                      </p>
                    </IonLabel>
                  </IonItem>
                }
                    { resultsArr
                        .filter((_, idx) => idx >= currentPage * pageLength && idx < (currentPage + 1) * pageLength)
                        .map((user, idx, all) => (
                          <IonItemSliding key={idx}>
                            <IonItemOptions side="start">
                              <IonItemOption
                                color="primary"
                                onClick={() => disguiseAsUser(user.uid, user.role, user.groupId, `${user.firstName} ${user.lastName}`)}
                              >
                                <FormattedMessage
                                  id="button.disguise"
                                  description="Disguise button label"
                                  defaultMessage="Disguise"
                                />
                              </IonItemOption>
                            </IonItemOptions>
                            <IonItem
                              onClick={() => openUserModal(user.uid)}
                              button
                              detail={false}
                              lines={all.length === idx + 1 ? 'none' : 'inset'}
                            >
                              <IonLabel>
                                <h2>{ user.firstName } { user.lastName }</h2>
                                <p>{ user.email }</p>
                              </IonLabel>
                              <p slot="end">
                                { roles.state.isLoaded && roles.items ? roles.items[user.role]?.name : user.role }<br />
                                <span>{ getOnlineStatus(user.uid) }</span>
                              </p>
                            </IonItem>
                            {/* <IonItemOptions side="end">
                              <IonItemOption color="danger" onClick={() => deleteUser(user.uid)}>Delete</IonItemOption>
                            </IonItemOptions> */}
                          </IonItemSliding>
                        ))
                    }
              </IonList>
            </>
        }
      </IonContent>

      { pages > 1 &&
        <PaginationToolbar
          currentPage={currentPage}
          pagesNumber={pages}
          onFirst={() => setCurrentPage(0)}
          onPrevious={() => setCurrentPage(page => page - 1)}
          onNext={() => setCurrentPage(page => page + 1)}
          onLast={() => setCurrentPage(pages - 1)}
        />
      }
    </IonPage>
  )
}

export default UsersList;
