import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';

const TaxonomiesList = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="nav.taxonomies"
              defaultMessage="Taxonomies"
              description="Settings nav button"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <FormattedMessage
                id="nav.taxonomies"
                defaultMessage="Taxonomies"
                description="Settings nav button"
              />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem button routerLink="/settings/taxonomies/actionRequired">
            <IonLabel>
            <FormattedMessage
                id="form.action-required"
                defaultMessage="Action Required"
                description="Project revision form Action Required field label"
            />
            </IonLabel>
          </IonItem>

          <IonItem button routerLink="/settings/taxonomies/tags">
            <IonLabel>
            <FormattedMessage
                id="form.tags"
                defaultMessage="Tags"
                description="Project revision form Tags field label"
            />
            </IonLabel>
          </IonItem>

          <IonItem button routerLink="/settings/taxonomies/region" lines="none">
            <IonLabel>
              <FormattedMessage
                id='form.region'
                defaultMessage='Region'
                description='Project revision form Region field label'
              />
            </IonLabel>
          </IonItem>

          {/* <IonItem button routerLink="/settings/taxonomies/category">
            <IonLabel>Category</IonLabel>
          </IonItem>
        
          <IonItem button routerLink="/settings/taxonomies/discipline1">
            <IonLabel>Discipline I</IonLabel>
          </IonItem>

          <IonItem button routerLink="/settings/taxonomies/discipline2">
            <IonLabel>Discipline II</IonLabel>
          </IonItem>

          <IonItem button routerLink="/settings/taxonomies/publicTarget">
            <IonLabel>Public Target</IonLabel>
          </IonItem> */}

        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TaxonomiesList;