import React, { useEffect, useMemo, useState } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import CardGrid from '../CardGrid';
import CardGridItem from '../CardGridItem';
import ProjectSponsoringModal from '../../modals/ProjectSponsoringModal';

// types
import { Project } from '../../models/Project';


const regions = [
  { name: 'PROSUD', value: 'esch22-prosud' },
  { name: 'CCPHVA', value: 'esch22-ccphva' },
  { name: 'Europe', value: 'europe' },
]

type TProjectSponsoringListProps = {
  projects: Project[];
  fav: boolean;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
}

const ProjectSponsoringList = ({ projects, fav, pageRef }: TProjectSponsoringListProps) => {
  const firebase = useFirebase();

  // retrieve logo urls
  const [ visualsUrls, setVisualsUrls ] = useState<{ [pid: string]: string }>({});

  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setVisualsUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }

  useEffect(() => {
    if (projects) {
      projects.forEach(project => {
        const visuals = project.sponsoring?.visual;
        if (visuals) {
          visuals.forEach(file => {
            if (file.fullPath) {
              getDownloadUrl(project.pid, file.fullPath);
            }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [projects]);


  // favorites
  const favorites = useSelector((state: any) => state.firebase.profile?.favorites);
  const toggleFavorite = (sponsorId: string) => {
    firebase.updateProfile({
      favorites: {
        ...favorites,
        [sponsorId]: favorites ? !favorites[sponsorId] : true
      }
    });
  }


  // grouping by municipalities (regions)
  const projectGroups = useMemo(() => {
    if (projects) {
      // grouping sponsors by type
      const groupedProjects = projects
        .filter(project => favorites && fav ? favorites[project.pid] : true)
        .reduce((acc, project) => {
          let groups = {};
          if (project.sponsoring?.region && project.sponsoring?.region.length) {
            groups = project.sponsoring?.region.reduce((regions, item) => ({ ...regions, [item]: [ ...(acc[item] || []), project ] }), {})
          }
          return {
            ...acc,
            ...groups
          }
        }, {} as { [activity: string]: any });

      // putting grouped sponsors in the groups
      return regions
        .filter(region => groupedProjects[region.value])
        .map(region => ({
          name: region.name,
          value: region.value,
          projects: groupedProjects[region.value]
        }));
    }
    return [];
  }, [projects, fav, favorites]);


  // modals
  const [ projectOpened, setProjectOpened ] = useState(false);
  const [ selectedProject, setSelectedProject ] = useState<Project>();

  const openProjectModal = (project: Project) => {
    setSelectedProject(project);
    // set modal as opened
    setProjectOpened(true);
  }


  return (
    <div className="grid-container">
      { selectedProject &&
        <ProjectSponsoringModal
          opened={projectOpened}
          setOpened={setProjectOpened}
          pageRef={pageRef}
          project={selectedProject}
          visualsUrl={visualsUrls[selectedProject.pid]}
        />
      }
      { projectGroups.map(region => (
          <CardGrid title={region.name} key={region.value}>
            { region.projects.map((project: Project) => (
                <CardGridItem key={project.pid}
                  id={project.pid}
                  visualsUrl={visualsUrls[project.pid]}
                  title={project.title}
                  description={project.sponsoring?.cardDescription}
                  favorite={favorites ? favorites[project.pid] : false}
                  onFavorite={() => toggleFavorite(project.pid)}
                  onReadMore={() => openProjectModal(project)}
                />
              ))
            }
          </CardGrid>
        ))
      }
    </div>
  )
}

export default ProjectSponsoringList;
