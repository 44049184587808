import React, { KeyboardEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

// hooks
import { Redirect, useParams } from 'react-router';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useOrderedCollection } from '../hooks/useOrderedCollection';
import { useCollectionItem } from '../hooks/useCollectionItem';

// components
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonTextarea,
  IonToast,
  useIonViewWillLeave,
  useIonViewDidEnter, IonFooter
} from '@ionic/react';
import ProjectHeader from '../components/projects/ProjectHeader';
import LoadingScreen from '../components/LoadingScreen';

// icon
import { send, ellipsisHorizontal } from 'ionicons/icons';
import { CommentsOrderedState } from '../models/Comment';


const ProjectComments = () => {
  const { pid } = useParams<{ pid: string }>();

  const dispatch = useDispatch();
  const firebase = useFirebase();

  const profile = useSelector((state: any) => state.firebase.profile || {});


  // record last time the user visted comments page
  const [ commentsActive, setCommentsActive ] = useState(false);
  useEffect(() => {
    if (!commentsActive) {
      firebase.updateProfile({
        comments: {
          ...profile.comments,
          [pid]: new Date().toISOString()
        }
      });
    }
    // eslint-disable-next-line
  }, [commentsActive]);

  useIonViewDidEnter(() => {
    setCommentsActive(true);
  });

  useIonViewWillLeave(() => {
    setCommentsActive(false);
  });


  // project
  const project = useCollectionItem('projects', pid);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});
  const gropupId = profile?.disguise?.groupId || profile?.groupId;

  // comments
  const comments = useOrderedCollection(`comments-${pid}`) as CommentsOrderedState;

  const firestore = useFirestore()
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const sendComment = () => {
    if (comment !== '') {
      setSending(true);
      const datetime = new Date().toISOString();

      firestore
        .collection('projects')
        .doc(pid)
        .collection('comments')
        .doc(datetime)
        .set({
          date: datetime,
          comment,
          user: {
            uid: profile.uid,
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            role: profile.role,
            lang: profile.lang
          }
        }).then(() => {
          setComment('');
          setSending(false);

          firebase.updateProfile({
            comments: {
              ...profile.comments,
              [pid]: new Date().toISOString()
            }
          });
        })
        .catch(err => {
          setError(`Can't send the message: ${err.message}`);
        });
    }
  }

  // Comment message and input form
  const [comment, setComment] = useState('');

  // detect Ctrl + Enter
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      sendComment();
    }
  }

  if (
    (
      project.state.isNotLoaded ||            // project is unavailable
      project.state.isLoaded                  // project is loaded
    ) &&
    Object.keys(permissions).length &&        // permissions are loaded
    !permissions['projects.all.read'] &&      // it's not an admin
    !(permissions['projects.group.read'] && !!project.item?.access[gropupId]) // it's not a project leader
  ) {
    return <Redirect to="/projects" />
  } else {
    return (
      <IonPage>
        <ProjectHeader pid={pid} page="comments"/>

        <IonContent className="comments-content">

          { (comments.state.isLoading || comments.state.isNotLoaded)
            ? <LoadingScreen />
            : <div className="comments-list">
                { (comments.state.isEmpty || comments.state.isNotFound)
                  ? <div className="comment">
                      <div className="comment-text">No comments available yet</div>
                    </div>
                  : comments.items.map(comment => (
                      <div key={comment.date} className={classNames({
                        'comment': true,
                        'comment-self': comment.user.uid === profile.uid
                      })}>
                        <h3 className="comment-author">
                          { comment.user.firstName || comment.user.lastName
                            ? `${comment.user.firstName} ${comment.user.lastName}`
                            : comment.user.email
                          }
                          <span className="comment-date">
                            { format(new Date(comment.date), 'MMMM dd, yyyy @ HH:mm:ss') }
                          </span>
                        </h3>
                        { /<\/?[a-z][\s\S]*>/i.test(comment.comment)
                          ? <div className="comment-text" dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
                          : <div className="comment-text comment-text--pre-wrap">{ comment.comment }</div>
                        }
                      </div>
                    ))                
                }
              </div>
          }
        </IonContent>
        <IonFooter className="ion-no-border">
          <section className="bottom-controls bottom-controls--comments">
            <div className="send-comment-container">
              <IonTextarea
                className={classNames({
                  'send-input': true,
                  'send-input--with-value': comment !== ''
                })}
                autoGrow={true}
                rows={1}
                placeholder="Add a comment..."
                value={comment}
                onIonChange={(e) => setComment(e.detail.value!)}
                onIonFocus={() => dispatch({ type: 'HIDE_TABS' })}
                onIonBlur={() => dispatch({ type: 'SHOW_TABS' })}
                onKeyUp={handleKeyPress}
              ></IonTextarea>
              <IonButton
                className="send-button"
                onClick={() => sendComment()}
                shape="round"
                size="small"
                disabled={sending}
                title="Ctrl+Enter"
              >
                <IonIcon icon={sending ? ellipsisHorizontal : send} slot="icon-only"/>
              </IonButton>
            </div>
          </section>
        </IonFooter>

        <IonToast
          isOpen={error !== ''}
          onDidDismiss={() => setError('')}
          header='Project comments'
          message={error}
          position='bottom'
          cssClass='bottom-toasts'
          animated={true}
          color="danger"
          buttons={[{ text: '×', role: 'cancel' }]}
        />
      </IonPage>
    )
  }
}

export default ProjectComments;
