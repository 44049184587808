import React, { useState, useRef } from 'react';

// hooks
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';

//components
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonLabel, IonButton, IonList } from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import RoleModal from '../modals/RoleModal';

//models
import { RolesState} from '../models/Role';
import { FormattedMessage } from 'react-intl';

const RolesList = () => {
  const pageRef = useRef();

  //get roles collection
  const roles = useFirestoreCollectionQuery('roles', false, { storeAs: 'allRoles' }) as RolesState;

  const [ roleOpened, setRoleOpened ] = useState(false);
  const [ selectedRoleId, setselectedRoleId ] = useState('');

  // this opens the modal
  const openRoleModal = (rid: string) => {
    // setting which role we want to edit
    setselectedRoleId(rid);
    // set modal as opened
    setRoleOpened(true);
  }

  return(
    <IonPage ref={pageRef}>
      <IonHeader>
         <IonToolbar>
           <IonButtons slot="start">
             <IonBackButton defaultHref="/settings" />
           </IonButtons>
           <IonTitle>
            <FormattedMessage
                id="nav.roles"
                defaultMessage="Roles"
                description="Settings nav button"
              />
             </IonTitle> 
           <IonButtons slot="primary">
            <IonButton onClick={() => openRoleModal('')}>
              <FormattedMessage
                id="role.create"
                defaultMessage="Create New Role"
                description="nav button"
              />
            </IonButton>
          </IonButtons>
         </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <FormattedMessage
                id="nav.roles"
                defaultMessage="Roles"
                description="Settings nav button"
              />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      { setselectedRoleId &&
        <RoleModal 
          opened={roleOpened}
          setOpened={setRoleOpened}
          pageRef={pageRef.current}
          roleId={selectedRoleId}
        />
      }
      {
        roles.state.isLoaded 
        ? <IonList>
            { Object.keys(roles.items)
                .map( key => roles.items[key])
                .filter(role => role )
                .map((role, idx, all) => (
                  <IonItem
                    key={role.id}
                    onClick={() => openRoleModal(role.id)}
                    button
                    detail={false}
                    lines={all.length === idx + 1 ? 'none' : 'inset'}
                  >
                    <IonLabel >
                      <h2>{role.name}</h2>
                    </IonLabel>
                  </IonItem>
                ))
            }
          </IonList>
        :
          <LoadingScreen />
      }
      </IonContent>
    </IonPage>
  );
}
export default RolesList;