import React, { useRef, useState } from 'react';

// hooks
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useFirestore } from 'react-redux-firebase';

// components
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonButton,
  IonReorderGroup,
  IonReorder,
  IonList
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import PermissionModal from '../modals/PermissionModal';

import { PermissionsOrderedState, Permission } from '../models/Permission';
import { FormattedMessage } from 'react-intl';

const PermissionsList = () => {
  const pageRef = useRef();
  
  const permissions = useFirestoreCollectionQuery('permissions', true, { orderBy: 'orderIndex' }) as PermissionsOrderedState;

  const [ modalOpened, setModalOpened ] = useState(false);
  const [ selectedPermission, setSelectedPermission ] = useState('');

  // this opens the modal
  const openModal = (id: string) => {
    // setting which user we want to edit
    setSelectedPermission(id);
    // set modal as opened
    setModalOpened(true);
  }

  const [ reorderActive, setReorderActive ] = useState(false);
  const firestore = useFirestore();
  const reorderItems = (evt: any) => {
    const newOrder = evt.detail.complete([...permissions.items]);

    const batch = firestore.batch();
    newOrder.forEach((item: Permission, index: number) => {
      if (item.orderIndex !== index) {
        batch.update(firestore.collection('permissions').doc(item.id), { orderIndex: index });
      }
    });
    batch.commit()
      .then(() => {})
      .catch((err) => {
        console.error('error saving the reordering', err);
      })
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="nav.permissions"
              defaultMessage="Permissions"
              description="Settings nav button"
            />
          </IonTitle>
          <IonButtons slot="primary">
            {/* Show reorder button only if there is more than two items */}
            { permissions.items && Object.keys(permissions.items).length > 1 &&
              <IonButton
                onClick={() => setReorderActive(state => !state)}
                color={reorderActive ? 'danger' : ''}
              >
                <FormattedMessage
                  id="permissions.reorder"
                  defaultMessage="Reorder Permissions"
                  description="permissions page nav button"
                />
              </IonButton>
            }
            <IonButton onClick={() => openModal('')}>
              <FormattedMessage
                id="permissions.create"
                defaultMessage="Create New Permission"
                description="permissions page nav button"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <FormattedMessage
                id="nav.permissions"
                defaultMessage="Permissions"
                description="Settings nav button"
              />
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        { setSelectedPermission && modalOpened &&
          <PermissionModal opened={modalOpened} setOpened={setModalOpened} pageRef={pageRef.current} permissionId={selectedPermission} />
        }
        { !permissions.state.isLoaded
          ? <LoadingScreen />
          : <IonList>
              { permissions.state.isEmpty
                ? <IonItem onClick={() => openModal('')} button detail={false} lines="none">
                    <IonLabel>
                      <h2>No permissions yet</h2>
                      <p>Click here to create the first one</p>
                    </IonLabel>
                  </IonItem>
                : <IonReorderGroup disabled={!reorderActive} onIonItemReorder={reorderItems}>
                    { permissions.items
                        .filter(permission => permission)
                        .map((permission, idx, all) => (
                          <IonItem
                            key={permission.id + idx}
                            onClick={() => !reorderActive && openModal(permission.id)}
                            button
                            detail={false}
                            lines={all.length === idx + 1 ? 'none' : 'inset'}
                          >
                            <IonLabel>
                              <h2>{ permission.name }</h2>
                              <p>{ permission.id }</p>
                            </IonLabel>
                            <IonReorder slot="end" />
                          </IonItem>
                        ))
                    }
                  </IonReorderGroup>
              }
            </IonList>
        }

      </IonContent>
    </IonPage>
  )
}

export default PermissionsList;
