import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';

// hooks
import { useFirestore } from 'react-redux-firebase';
import { useObjectCollection } from '../hooks/useObjectCollection';

// components
import ModalForm from "../components/ModalForm";
import {IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonAlert } from '@ionic/react';

// models
import { User } from '../models/User';
import { RolesState } from '../models/Role';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

type UserModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  userId?: string;
  data?: any
}

const UserModal = (props: UserModalProps) => {
  // current user
  const auth = useSelector((state: any) => state.firebase.auth);
  const stateUserData = useSelector((state: any) => state.users[props.userId || 'none']) as User;

  // user
  const user = props.data || stateUserData;

  // roles
  const roles = useObjectCollection('allRoles') as RolesState;

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});

  const [ userData, setUserData ] = useState<User>({ ...user });

  // update the userData in case if initially the store is empty
  useEffect(() => {
    if (props.userId && user?.uid !== userData.uid) {
      setUserData(data => ({
        ...data,
        ...user
      }))
    }
    // eslint-disable-next-line 
  }, [user])

  // updates the userData by property key
  // const updateData = (property: string, value: any) => {
  //   if ((userData as any)[property] !== value) {
  //     setUserData(data => ({ ...data, [property]: value }));
  //   }
  // }

  // updates user's role id and group id if needed
  const updateRole = (roleId: string) => {
    if (roles.items[roleId].groupId && roles.items[roleId].groupId !== '') {
      setUserData(data => ({
        ...data,
        role: roleId,
        groupId: roles.items[roleId].groupId!
      }));
    } else {
      setUserData(data => ({ ...data, role: roleId }));
    }
  }

  const firestore = useFirestore();
  const updateUser = () => {
    Object.keys(userData).forEach(key => {
      if ((userData as any)[key] === undefined) {
        delete (userData as any)[key];
      }
    });

    firestore
      .collection('users')
      .doc(userData.uid)
      .update({
        ...userData,
        updatedBy: auth.uid,
        updatedOn: new Date().toISOString()
      })
      .then(() => {
        props.setOpened(false);
      });
  }
  const intl = useIntl();


  const [ errorMessage, setErrorMessage ] = useState('');
  const [ deleteRequest, setDeleteRequest ] = useState(false);
  const [ deleting, setDeleteing ] = useState(false);
  const deleteUser = () => {
    setDeleteing(true);
    
    const removeUserFn = firebase.app().functions('europe-west3').httpsCallable('removeUser');
    removeUserFn({
      userId: props.userId
    })
      .then(() => {
        props.setOpened(false);
        setDeleteing(false);
      })
      .catch(() => {
        setDeleteing(false);
        setErrorMessage(`Couldn't delete user. Try Again later.`);
      })
  }

  return (
    <ModalForm {...props}
      title={ 
        intl.formatMessage({
          id: 'modal.title.edit',
          defaultMessage: 'Edit {data}',
          description: 'Edit a role',
        },{
          data: userData.email
        })
      }
    >
      <IonAlert
        isOpen={deleteRequest}
        cssClass='confirmation-alert-modal'
        onDidDismiss={() => setDeleteRequest(false)}
        header={'Delete User'}
        message={`Are you sure, you want to delete this user? You won't be able to restore it later!`}
        buttons={['Cancel', {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => deleteUser()
        }]}
      />
      <IonList className="no-bottom-controls">
        <IonItem>
          <IonLabel position="stacked">
            <FormattedMessage
              id="form.name"
              defaultMessage="Name"
              description="Full name field label"
            />
          </IonLabel>
          <IonInput
            value={`${userData.firstName} ${userData.lastName}`}
            name="names"
            type="text"
            readonly
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            <FormattedMessage
              id= "form.role"
              defaultMessage= "Role"
              description="User role field on all form"
            />
          </IonLabel>
          <IonSelect
            value={userData.role}
            onIonChange={e => updateRole(e.detail.value)}
            interface="action-sheet"
          >
            {
              Object.keys(roles.items)
                .map(key => roles.items[key])
                .filter(role => role)
                .map(role => (
                  <IonSelectOption key={role.id} value={role.id}>{ role.name }</IonSelectOption>
                ))
            }
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            <FormattedMessage
              id= "form.time-privacy-accepted"
              defaultMessage= "Time Privacy Policy was accepted"
              description="User role field on all form"
            />
          </IonLabel>
          <IonInput
            value={userData.gdpr}
            name="gdpr"
            type="text"
            readonly
          ></IonInput>
        </IonItem>
        { errorMessage &&
          <IonItem>
            <IonLabel className="danger">{ errorMessage }</IonLabel>
          </IonItem>
        }
      </IonList>
      <div className="bottom-controls bottom-controls--appart">

        { permissions['settings.users.delete'] &&
          <IonButton
            expand="block"
            onClick={() => { 
              setErrorMessage('');
              setDeleteRequest(true);
            }}
            className="danger-style"
            disabled={deleting}
          >
            <FormattedMessage
              id= "button.delete"
              defaultMessage= "Delete"
              description="Delete button label"
            />
          </IonButton>
        }

        <IonButton
          expand="block"
          onClick={updateUser}
          className="blue-style"
          disabled={deleting}
        >
          <FormattedMessage
            id= "button.update"
            defaultMessage= "Update"
            description="Update button label"
          />
        </IonButton>
      </div>
    </ModalForm>
  )
}

export default UserModal;
