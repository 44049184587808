import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';

// hooks
import { useFirebase } from 'react-redux-firebase';

// components
import { IonAlert, IonButton, IonIcon } from '@ionic/react';
import { addCircle } from 'ionicons/icons';
import ResourceTile from './ResourceTile';

import './ResourcesList.scss';
import { FormattedMessage } from 'react-intl';


type FilesObj = {
  [fileId: string]: any
}


type ResourcesListProps = {
  collection: string;
  doc: string;
  files: FilesObj;
  permissions: {
    create: boolean;
    delete: boolean;
  }
  onChange: (updatedFiles: FilesObj) => void;
}

const ResourcesList = ({ collection, doc, files, permissions, onChange }: ResourcesListProps) => {
  const firebase = useFirebase();

  // file downloading
  const [ resources, setResources ] = useState<{ [fileId: string]: any }>({});
  useEffect(() => {
    if (files) {
      Object.keys(files)
        .sort((file1, file2) => files[file1].created > files[file2].created ? -1 : 1 )
        .forEach(fileId => {
          if (!resources[fileId] || !resources[fileId].downloadUrl) {
            const resource = files[fileId];
            const storageRef = firebase.storage().ref(resource.fullPath);
            storageRef.getDownloadURL()
              .then((downloadUrl: string) => {
                setResources(res => ({
                  ...res,
                  [fileId]: {
                    ...resource,
                    downloadUrl,
                  }
                }));
              })
              .catch(err => {
                console.error('get download url', err);
              });
          }
        })
    }
    // eslint-disable-next-line
  }, [files]);

  // file uploading
  const fileInput = useRef<any>(null);
  const [ uploading, setUploading ] = useState(false);

  const selectFile = () => {
    if (fileInput.current && fileInput.current.click) {
      fileInput.current.click();
    }
  }

  const uploadFile = async () => {
    if (fileInput.current.files) {
      // upload files
      setUploading(true);

      const newFiles: { [id: string]: any } = {};
      // reverse the array so the first files will get the latest `created` timestamp
      for (let i = fileInput.current.files.length - 1; i >= 0; i--) {
        const file = fileInput.current.files[i];
        if (file) {
          // create id for the file
          const fileId = nanoid(14);

          await firebase
            .uploadFile(`${collection}/${doc}`, file, undefined, {
              name: fileId + '-' + file.name,
              // metadata: {
              //   contentDisposition: `attachment; filename="${file.name}"`
              // }
            })
            .then(({ uploadTaskSnapshot: { metadata } }: any) => {
              // if file was successfully uploaded
              // add it to the files array
              newFiles[fileId] = {
                id: fileId,
                name: file.name,
                contentType: metadata.contentType,
                fullPath: metadata.fullPath,
                size: metadata.size,
                created: metadata.timeCreated,
                updated: metadata.updated,
              };
            })
            .catch(err => {
              console.error('File upload error:', err);
            });
        }

        onChange({
          ...files,
          ...newFiles
        });
      };

      // clear the file input
      fileInput.current.value = null;
      setUploading(false);
    }
  }


  // file deleting
  const [fileToDelete, setFileToDelete] = useState<{ id?: string, name?: string }>({});
  const deleteFile = (fileId: string) => {
    if (files && files[fileId]) {
      const storageRef = firebase.storage().ref(files[fileId].fullPath);
      storageRef.delete()
        .then(() => {
          // file is deleted, we need to remove links to it from firestore
          const updatedFiles = { ...files };
          delete updatedFiles[fileId];

          onChange(updatedFiles);
        })
        .catch(err => {
          console.error('Error deleting file', err, files[fileId]);
        });
    
    }
  }

  return (
    <>
      <IonAlert
        isOpen={!!fileToDelete.id}
        cssClass='confirmation-alert-modal'
        onDidDismiss={() => setFileToDelete({})}
        header={'Delete File'}
        message={`Are you sure, you want to delete file ${fileToDelete.name}?`}
        buttons={['Cancel', {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => deleteFile(fileToDelete.id!)
        }]}
      />
      <div className="resources-list">
        { files && Object.keys(files).length > 0 &&
          Object.keys(files)
            .map(fileId => files[fileId])
            .sort((file1, file2) => file1.created > file2.created ? -1 : 1)
            .map((file, idx) => (
              <ResourceTile key={'file' + idx}
                name={file.name}
                size={file.size}
                type={file.contentType}
                date={file.created}
                url={resources[file.id]?.downloadUrl}
                onDelete={permissions.delete ? () => setFileToDelete({ id: file.id, name: file.name }) : undefined}
              />
            ))
        }
        { permissions.create &&
          <>
            <input
              type="file"
              capture="environment"
              accept=".jpg,.gif,.jpeg,.png,image/*,.pdf,application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,application/*,.mov,.avi,.mp4,video/*"
              multiple
              ref={fileInput}
              onChange={uploadFile}
              className="resource-tile--hidden-input"
            />
            <div className="resource-tile--container">
              <IonButton fill="clear" className="resource-tile resource-tile-add" onClick={selectFile} disabled={uploading}>
                <div className="resource-tile--content">
                  <IonIcon icon={addCircle} className="resource-tile--icon" />
                  <h2 className="resource-tile--title">
                    <FormattedMessage
                      id= "form.doc.add-multiple"
                      defaultMessage= 'Add New Files'
                      description="Button label to add multiple files"
                    />
                  </h2>
                </div>
              </IonButton>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default ResourcesList;
