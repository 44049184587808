import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

import { createStore, combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,

  // presence: 'presence', // tracking the online presence - disabled errors in Safari

  mergeOrdered: false,
  mergeOrderedDocUpdate: false,
  mergeOrderedCollectionUpdates: false
}

// Initialize firebase instance
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
});

// Initialize other services on firebase instance
firebase.database();
firebase.firestore();
firebase.functions();
firebase.storage();
firebase.analytics();

// Additional reducers
const ui = (state = {}, action: any) => {
  switch (action.type) {
    case 'SHOW_TABS':
      return {
        ...state,
        showTabs: true
      }
    case 'HIDE_TABS':
      return {
        ...state,
        showTabs: false
      }
    case 'SET_CATEGORY':
      return {
        ...state,
        selectedCategory: action.payload
      }
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload
      }
    default:
      return state;
  }
}

const users = (state = {}, action: any) => {
  switch (action.type) {
    case 'UPDATE_USERS':
      return {
        ...action.payload
      };
    default:
      return state;
  }
}


// Add firebase and firestore to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  ui,
  users
});

// Create store with reducers and initial state
const initialState = {
  firebase: {},
  firestore: {},
  ui: {
    selectedCategory: 'all',
    showTabs: true,
    locale: null
  },
  users: {}
}

export const store = createStore(
  rootReducer,
  initialState as any,
  composeWithDevTools()
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}
