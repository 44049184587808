import React from 'react';
import classNames from 'classnames';
import { formatFileSize } from '../../utils';

// components
import { IonIcon } from '@ionic/react';
import { documentOutline } from 'ionicons/icons';

// types
import { ProjectField } from '../../models/Project';


type ProjectDataValueProps = {
  field: ProjectField;
  value: any;
  fileUrls?: { [fid: string]: string };
}

const ProjectDataValue = ({ field, value, fileUrls }: ProjectDataValueProps) => {

  switch (field.type) {
    case 'select':
      const optionName = field.options?.find(option => option.value === value)?.name || value;
      return (
        <div className={classNames({
          'data-value': true,
          'data-value--empty': !optionName
        })}>{ optionName || 'Not set' }</div>
      )
    case 'checkbox':
      const selectedOptions = value && field.options
        ?.filter(option => value[option.value])
        .map(option => option.name)
        .join(', ');
      return (
        <div className={classNames({
          'data-value': true,
          'data-value--empty': !selectedOptions
        })}>{ selectedOptions || 'Not Set' }</div>
      )
    case 'rich-text':
      return (
        <div className={classNames({
          'data-value': true,
          'data-value--empty': !value
        })} dangerouslySetInnerHTML={{ __html: value || 'Not Set' }}></div>
      )
    case 'files':
      if (value && (value.length > 0)) {
        return value.map((file: any, idx: number) => (
          <div key={file.name + idx} className="data-value data-value--file">
            { ((file.fid || file.id) && fileUrls && fileUrls[file.fid || file.id])
              ? <a href={fileUrls[file.fid || file.id]} download target="_download">
                  <IonIcon icon={documentOutline} className="data-value--file-icon" />
                  { file.name }
                </a>
              : <>
                  <IonIcon icon={documentOutline} className="data-value--file-icon" />
                  { file.name }
                </>
            }
            <span className="data-value--file-size">{ formatFileSize(file.size) }</span>
          </div>
        ));
      } else {
        return <div className="data-value data-value--empty">Not set</div>;
      }
    default:
      return (
        <div className={classNames({
          'data-value': true,
          'data-value--empty': !value
        })}>{ value || 'Not set' }</div>
      )
  }
}

export default ProjectDataValue;
