import React from 'react';

// hooks
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';


type AuthChangePasswordProps = {
  password: any;
  createPasswordCode: string;
  errors: any;
  changeFormType: Function;
  setMessageType: Function;
  clearState?: Function;
}

const AuthChangePassword = ({ password, createPasswordCode, errors, changeFormType, setMessageType, clearState }: AuthChangePasswordProps) => {
  const firebase = useFirebase();

  // change password
  function changePassword() {
    if (createPasswordCode !== '') {
      firebase.confirmPasswordReset(createPasswordCode, password.value)
        .then(() => {
          setMessageType('password-changed');
          if (clearState) {
            clearState();
          }
        });
    }
  }

  // catch the Enter key in the password field to submit the form
  function enterPressed(evt: any) {
    if (evt && evt.which && evt.which === 13) {
      changePassword();
    }
  }
  
  return (
    <>
      <div className="auth-form-wrap esch">
        <IonList lines="full" className="auth-form-content" class="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.new-password"
                defaultMessage= "New Password"
                description="New Password field label"
              />
            </IonLabel>
            <IonInput required type="text" name="password"
              {...password}
              autofocus={true}
              onKeyPress={enterPressed}
            ></IonInput>
          </IonItem>
        </IonList>
        { errors }
        <div className="ion-padding button-container">
          <IonButton
            type="submit"
            class="ion-change-password-button"
            onClick={changePassword}
          >
            <FormattedMessage
              id= "button.change-password"
              defaultMessage= "Change Password"
              description="Change Password button label"
            />
          </IonButton>
        </div>
      </div>
      <div className="auth-form-content-switch">
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => {
            if (clearState) clearState();
            changeFormType('sign-in')
          }}
        >
          <FormattedMessage
              id= 'button.back-sign-in'
              defaultMessage= 'Back to Sign In'
              description= 'Back to Sign In'
            />
          </IonButton>
      </div>
    </>
  )
}

export default AuthChangePassword;
