import React from 'react';

// hooks
import { useSelector } from 'react-redux';

// components
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';


const Resources: React.FC = () => {

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Resources</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Resources</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          { permissions['resources.general.read'] &&
            <IonItem button routerLink="/resources/general">
              <IonLabel>General</IonLabel>
            </IonItem>
          }
          { permissions['resources.branding.read'] &&
            <IonItem button routerLink="/resources/branding">
              <IonLabel>Branding</IonLabel>
            </IonItem>
          }
          { permissions['resources.coaching.read'] &&
            <IonItem button routerLink="/resources/coaching" lines="none">
              <IonLabel>Coaching</IonLabel>
            </IonItem>
          }
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Resources;
