import React, { useMemo, useState } from 'react';
import { normString } from '../utils';

// hooks
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useSelector } from 'react-redux';

// components
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import TopNavigation from '../components/TopNavigation';
import LoadingScreen from '../components/LoadingScreen';
import SearchField from '../components/SearchField';

// types
import { CompaniesState, Company } from '../models/Company';
import { FormattedMessage, useIntl } from 'react-intl';


// which fields are used in search
// we have to set the field names in the type to match the User fields
type TCompanySearchFields = Array<
  'name' | 'cardDescription' | 'website' | 'description' |
  'contactPerson' | 'contactEmail' | 'contactPhone' | 'remarks'
>;
const searchDataFields: TCompanySearchFields = [
  'name', 'cardDescription', 'website', 'description',
  'contactPerson', 'contactEmail', 'contactPhone', 'remarks'
];

const CompaniesList = () => {

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  // load companies
  const companies = useFirestoreCollectionQuery(
    'companies', false,
    permissions['companies.all.read']
    ? {} 
    : { where: ['nothing', '==', 'everything'] }
  ) as CompaniesState;



  const [ searchValue, setSearchValue ] = useState< string[] >([]);
  const setSearchString = (searchStr: string) => {
    setSearchValue(
      searchStr
        .split(/[\s|,]/)
        .map(str => normString(str))
        .filter(str => str !== '')
    )
  }


  // search
  const [ searchData, setSearchData ] = useState<{ [uid: string]: string } | null>(null);
  const prepareDataForSearch = () => {
    if (!searchData) {
      setSearchData(
        Object.keys(companies.items)
          .map(companyId => {
            const data = searchDataFields.map(field => normString(companies.items[companyId][field] || '')).join('|');
            return {
              id: companyId,
              data
            }
          })
          .reduce((acc, item) => ({ ...acc, [item.id]: item.data }), {})
      )
    }
  }


  // filtering
  const [ filter, setFilter ] = useState('all');
  const filterBySearchValue = (company: Company) => {
    // if the user was removed, filter it out
    if (!company) return false;
    // check for category filters
    if (filter !== 'all') {
      if (filter === 'published' && !company.published) return false;
      if (filter === 'unpublished' && company.published) return false;
    }
    // if no search string — show everything
    if (!searchValue.length) return true;
    // for each piece of the search string – look for a match
    for (let i = 0, len = (searchValue).length; i < len; i++) {
      if (searchData && searchData[company.id].indexOf(searchValue[i]) >= 0) {
        return true;
      }
    }
    // return false if nothing matched
    return false;
  }
  

  // display items
  const resultsArr = useMemo(() => {
    if (companies.items && Object.keys(companies.items).length) {
      return Object.keys(companies.items)
        .map(companyId => companies.items[companyId])
        .filter(company => filterBySearchValue(company))
        .sort((com1, com2) => (com1.name || '') > (com2.name || '') ? 1 : -1);
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [companies.items, searchValue, filter]);

  const intl = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>
            <FormattedMessage
              id="nav.companies"
              defaultMessage="Companies"
              description="Settings nav button"
            />
          </IonTitle>
          <TopNavigation
            items={[
              {
                id: 'all',
                title: intl.formatMessage({
                  id: 'topNavigation.all',
                  defaultMessage: 'All',
                  description: 'Top navigation view all button',
                }),
                onClick: () => setFilter('all')
              },
              {
                id: 'published',
                title: intl.formatMessage({
                  id: 'topNavigation.published',
                  defaultMessage: 'Published',
                  description: 'Top navigation button label',
                }),
                onClick: () => setFilter('published')
              },
              {
                id: 'unpublished',
                title: intl.formatMessage({
                  id: 'topNavigation.hidden',
                  defaultMessage: 'Hidden',
                  description: 'Top navigation button label',
                }),
                onClick: () => setFilter('unpublished')
              }
            ]}
            selected={filter}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { (companies.state.isNotLoaded || companies.state.isLoading)
          ? <LoadingScreen />
          : <>
              <h2 className="list-title">
                <span>
                  { resultsArr.length }&nbsp;
                  <FormattedMessage
                    id="nav.companies"
                    defaultMessage="Companies"
                    description="Settings nav button"
                  />
                </span>
                <SearchField onSearch={setSearchString} onActivate={prepareDataForSearch} />
              </h2>
              <IonList>
                { !resultsArr.length &&
                  <IonItem>
                    <IonLabel>
                      <h2>
                        <FormattedMessage
                          id="companies.title.not-found"
                          defaultMessage="No Companies Found"
                          description="Companies listing title if no item"
                        />
                      </h2>
                      <p>
                        <FormattedMessage
                          id="companies.description.not-found"
                          defaultMessage="Try to change the filtering criteria."
                          description="Companies listing description if no item"
                        />
                      </p>
                    </IonLabel>
                  </IonItem>
                }
                { resultsArr
                    .map((company, idx, all) => (
                      <IonItem
                        key={idx}
                        routerLink={`/settings/companies/${company.id}`}
                        button
                        lines={all.length === idx + 1 ? 'none' : 'inset'}
                      >
                        <IonLabel>
                          <h2>{ company.name }</h2>
                          <p>{ company.cardDescription }</p>
                        </IonLabel>
                        <p slot="end">
                          { 
                            company.published
                            ? <FormattedMessage
                                id= 'topNavigation.published'
                                defaultMessage= 'Published'
                                description= 'Top navigation button label'
                              />
                            : <FormattedMessage
                                id= 'topNavigation.hidden'
                                defaultMessage= 'Hidden'
                                description= 'Top navigation button label'
                              />
                          }<br />
                        </p>
                      </IonItem>
                    ))
                }
              </IonList>
            </>
        }

      </IonContent>
    </IonPage>
  )
}

export default CompaniesList;
