import React, { useMemo } from 'react';
import { IonPage, IonContent, IonList, IonItem, IonLabel } from '@ionic/react';
import { useParams } from 'react-router';

import ProjectHeader from '../components/projects/ProjectHeader';
import LoadingScreen from '../components/LoadingScreen';

import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { RequestState } from '../models/RequestState';

import { FormattedDate, FormattedTime} from 'react-intl';

import './ProjectRevisionview.scss';
import RevisionsDropdown from '../components/RevisionsDropdown';

export type Revision = {
    date: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    uid?: string;
    updatedBy?: string;
    moderation_state?: string;
  }

export type RevisionsState = {
    items: { 
      [id: string]: Revision };
    state: RequestState;
  };

const ProjectRevisions = () => {

    //get current projectid
    const { pid } = useParams<{ pid: string }>();
    // load revisions for the current project
    const revisions = useFirestoreCollectionQuery(`projects/${pid}/revisions`, false, { storeAs: `revisions-${pid}` }) as RevisionsState;

    const needsReviewRevisions = useMemo(() => {
        if (revisions.items) {
            return Object.keys( revisions.items )
                .map( key => revisions.items[key] )
                .filter( revision => revision )
                .sort((revision1, revision2 ) => revision1.date > revision2.date ? -1 : 1)
                .filter((revision ) => revision.moderation_state === 'needs_review');
        } else {
            return [];
        }
    }, [revisions.items]);

    return (
        <IonPage>
            <ProjectHeader pid={pid} page="revisions"/>
            <IonContent>
            <RevisionsDropdown revisions={needsReviewRevisions}/>
                {
                    !revisions.state.isLoaded
                    ? <LoadingScreen />
                    : revisions.state.isEmpty
                        ? <h2 className="revisions-title">No revisions!</h2>
                        : !needsReviewRevisions.length
                            ? <h2 className="revisions-title">Only drafts in revisions list</h2>
                            :
                            <IonList>
                                {
                                    needsReviewRevisions
                                        .map( revision => (   
                                        <IonItem
                                            key={ revision.date }
                                            routerLink={ `/projects/${pid}/revisions/${revision.date}` } 
                                            button
                                            >
                                            <IonLabel>
                                                { revision.moderation_state && revision.moderation_state }
                                            </IonLabel>
                                            <IonLabel>
                                                <FormattedDate value={revision.date} dateStyle="medium" />,&nbsp;<FormattedTime value={revision.date}/>
                                            </IonLabel>
                                            
                                            {
                                                revision.userName 
                                                ? <p slot="end">{ revision.userName} </p>
                                                : revision.firstName || revision.lastName || revision.email
                                                    ? <p slot="end">{ `${revision.firstName || ''} ${revision.lastName || ''} (${revision.email})` } </p>
                                                    : <p slot="end">{ revision.uid} </p>
                                            }
                                        </IonItem>
                                    ))
                                }
                            </IonList>
                }
            </IonContent>
        </IonPage>

    );
}

export default ProjectRevisions;