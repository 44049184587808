import React from 'react';
import { FormattedMessage } from 'react-intl';

// hooks
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';

type AuthLoginProps = {
  email: any;
  password: any;
  errors: any;
  changeFormType: Function;
}

const AuthLogin = ({email, password, errors, changeFormType}: AuthLoginProps) => {
  const firebase = useFirebase();

  // log in
  function loginWithPassword() {
    firebase.login({ email: email.value, password: password.value });
  }

  // catch the Enter key in the password field to submit the form
  function enterPressed(evt: any) {
    if (evt && evt.which && evt.which === 13) {
      loginWithPassword();
    }
  }
  
  return (
    <>
      <div className="auth-form-wrap esch">
        <IonList lines="full" className="auth-form-content" class="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.email"
                defaultMessage= "Email address"
                description="Email field on all form"
              />
            </IonLabel>
            <IonInput required type="email" name="email"
              {...email}
              autofocus={true}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.password"
                defaultMessage= "Password"
                description="Password field on all form"
              />
            </IonLabel>
            <IonInput required type="password" name="password"
              {...password}
              onKeyPress={enterPressed}
            ></IonInput>
          </IonItem>
        </IonList>
        { errors }
        <div className="button-container">
          <IonButton
            expand="block" type="submit"
            class="ion-sign-in-button"
            onClick={loginWithPassword}
          >
            <FormattedMessage
                id= "auth.form.sign-in"
                defaultMessage= "Sign-In"
                description="Sign in form Sign-In button"
            />
          </IonButton>
        </div>
      </div>
      <div className="auth-form-content-switch esch">
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => changeFormType('reset-password')}
        >
          <FormattedMessage
                id= "auth.form.forgot-pass"
                defaultMessage= "Forgot password?"
                description="Sign in form forgot password button"
            />
        </IonButton>
        &bull;
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => changeFormType('choose-registration')}
        >
          <FormattedMessage
                id= "auth.form.create"
                defaultMessage= "Create an account"
                description="Sign in form create account button"
            />
        </IonButton>
      </div>
    </>
  )
}

export default AuthLogin;
