import { IonSelect, IonSelectOption } from '@ionic/react';
import React, { useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { RequestState } from '../models/RequestState';

import './RevisionsDropdown.scss';

export type Revision = {
    date: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    uid?: string;
    updatedBy?: string;
    moderation_state?: string;
  }

export type RevisionsState = {
    items: { 
      [id: string]: Revision };
    state: RequestState;
  };

type TRevisionsDropdownProps = {
    revisions: Revision[];
    selected?: string;
}

const RevisionsDropdown = ({ revisions, selected }: TRevisionsDropdownProps) => {

    //get current projectid
    const { pid } = useParams<{ pid: string }>();
    //selected revision
    const [ selectedRevision, setSelectedRevision ] = useState(selected);
    //redirect to the chosen revision page
    const history = useHistory();
    const selectRevision = (revision: string) => {
        history.push(`/projects/${pid}/revisions/${revision}`);
        setSelectedRevision( revision );
    }

    return (
            <IonSelect
                className="revision-select"
                onIonChange={ (e) => selectRevision(e.detail.value) }
                value={ selected || revisions[0]?.date || selectedRevision }
                disabled={ !revisions.length }
            >
                {
                    revisions.map( revision => (   
                            <IonSelectOption
                                key={ revision.date }
                                value={ revision.date }
                            >
                                { revision.moderation_state ? 'Submitted on: ' : '' }
                                <FormattedDate value={revision.date} dateStyle="short" />
                                &nbsp;<FormattedTime value={revision.date}/>
                            </IonSelectOption>
                        ))
                }
            </IonSelect>
    );
}

export default RevisionsDropdown;