import React, { useMemo } from 'react';
import { getYouTubeId } from '../utils';

// hooks
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow } from '@ionic/react';
import { Company, getCompanyInterests, getCompanyActivityFields } from '../models/Company';
import ModalForm from '../components/ModalForm';
import YouTube from 'react-youtube';

import { star, starOutline } from 'ionicons/icons';
import './SponsorModal.scss';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from "classnames";


type SponsorModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  sponsor: Company;
  visualsUrl?: string;
  hideFavorites?: boolean;
}


const SponsorModal = ({ sponsor, visualsUrl, hideFavorites, ...props }: SponsorModalProps) => {
  const firebase = useFirebase();

  // favorites
  const favorites = useSelector((state: any) => state.firebase.profile?.favorites);
  const toggleFavorite = (sponsorId: string) => {
    firebase.updateProfile({
      favorites: {
        ...favorites,
        [sponsorId]: favorites ? !favorites[sponsorId] : true
      }
    });
  }

  const intl = useIntl();
  const companyInterests = useMemo( () => getCompanyInterests(intl),
  // eslint-disable-next-line
  [intl.locale]);
  const companyActivityFields = useMemo( () => getCompanyActivityFields(intl),
  // eslint-disable-next-line
  [intl.locale]);

  return (
    <ModalForm {...props} title={ sponsor.name || 'Untitled Company' } className="sponsor-modal-desktop">
      <div className="sponsor-modal">
        { !hideFavorites &&
          <IonButton
            shape="round"
            color="light"
            className={classNames({
              'sponsor-modal--favorite-button': true,
              'no-visual': !visualsUrl
            })}
            onClick={() => toggleFavorite(sponsor.id)}
          >
            <IonIcon icon={favorites && favorites[sponsor.id] ? star : starOutline} slot="icon-only" color="dark" />
          </IonButton>
        }

        <div className={classNames({
           'sponsor-modal--presentation-panel': true,
           'no-visual': !visualsUrl
        })}>
          { visualsUrl &&
            <IonImg src={visualsUrl} className="sponsor-modal--logo" />
          }
          <h2>{ sponsor.name }</h2>
          { sponsor.description && sponsor.description !== '<p></p>' &&
            <div className="sponsor-modal--description" dangerouslySetInnerHTML={{ __html: sponsor.description || '' }}></div>
          }
          { sponsor.youTubeLink &&
            <YouTube videoId={getYouTubeId(sponsor.youTubeLink)} opts={{ width: '540', height: '300' }} />
          }
          <IonGrid>
            <IonRow>
              <IonCol>
                <h3>
                  <FormattedMessage
                    id= "form.activity-field"
                    defaultMessage= 'Field of Activity'
                    description= "Input field label"
                  />
                </h3>
                <p>{
                  companyActivityFields
                    .filter(item => sponsor.activity === item.value)
                    .map(item => item.name)
                }</p>
              </IonCol>
              { sponsor.contactPerson &&
                <IonCol>
                  <h3>
                    <FormattedMessage
                      id= "form.contact-person"
                      defaultMessage= 'Contact Person'
                      description= "Input field label"
                    />
                  </h3>
                  { sponsor.contactPerson }
                </IonCol>
              }
            </IonRow>
            { (sponsor.contactEmail || sponsor.contactPhone) &&
              <IonRow>
                { sponsor.contactEmail &&
                  <IonCol>
                    <h3>
                      <FormattedMessage
                        id= "form.email"
                        defaultMessage= "Email address"
                        description="Email field on all form"
                      />
                    </h3>
                    { sponsor.contactEmail }
                  </IonCol>
                }
                { sponsor.contactPhone &&
                  <IonCol>
                    <h3>
                      <FormattedMessage
                        id= "form.phone"
                        defaultMessage= 'Phone number'
                        description= "Phone number field on all form"
                      />
                    </h3>
                    { sponsor.contactPhone }
                  </IonCol>
                }
              </IonRow>
            }
          </IonGrid>

          { sponsor.interests && sponsor.interests.length &&
            <div className="sponsor-modal--data">
              <h3>
                <FormattedMessage
                  id= "form.interested-in"
                  defaultMessage= 'Interested In'
                  description= "Input field label"
                />
              </h3>
              <p>{
                companyInterests
                  .filter(item => sponsor.interests.indexOf(item.value) >= 0)
                  .map(item => item.name)
                  .join(', ')
              }</p>
            </div>
          }

          { sponsor.remarks &&
            <div className="sponsor-modal--data">
              <h3>
                <FormattedMessage
                  id= "form.remarks"
                  defaultMessage= 'Remarks'
                  description= "Input field label"
                />
              </h3>
              <p dangerouslySetInnerHTML={{ __html: sponsor.remarks }}></p>
            </div>
          }
        </div>
      </div>
    </ModalForm>
  )
}

export default SponsorModal;
