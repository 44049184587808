import React from 'react';

import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import TopNavigation from './TopNavigation';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';


type AccountHeaderProps = {
  page: string;
}

const AccountHeader = ({ page }: AccountHeaderProps) => {

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || []);
  const intl = useIntl();

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/account" />
        </IonButtons>
        <IonTitle>
          <FormattedMessage
            id="account.title"
            defaultMessage="Account"
            description="Account section title"
          />
        </IonTitle>
        <TopNavigation items={[
            {
              id: 'company',
              title: intl.formatMessage({
                id: "form.company",
                defaultMessage: "Company",
                description: "Company field on all form"
              }),
              href: '/account/company',
              hidden: !permissions['companies.group.read']
            },
            {
              id: 'profile-details',
              title: intl.formatMessage({
                id: 'account.details',
                defaultMessage: 'Profile Details',
                description: 'Profile Details nav item',
              }),
              href: '/account/profile-details'
            },
            {
              id: 'change-password',
              title: intl.formatMessage({
                id: 'button.change-password',
                defaultMessage: 'Change Password',
                description: 'Change Password button label',
              }),
              href: '/account/change-password'
            },
            {
              id: 'notifications',
              title: intl.formatMessage({
                id: 'account.notifications',
                defaultMessage: 'Notifications',
                description: 'Notifications nav item',
              }),
              href: '/account/notifications',
              hidden: !permissions['settings.notifications.own.edit']
            },
            {
              id: 'language',
              title: intl.formatMessage({
                id: "account.language",
                defaultMessage: "Language",
                description: "Language nav item"
              }),
              href: '/account/language',
            }
          ]}
          selected={page}
        />
      </IonToolbar>
    </IonHeader>
  )
}

export default AccountHeader;
