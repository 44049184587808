import React, { useState } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

// components
import { Redirect } from 'react-router';
import {
  IonPage, IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonButton,
  IonToast,
  IonFooter,
  IonItemDivider
} from '@ionic/react';
import AccountHeader from '../components/AccountHeader';
import { FormattedMessage } from 'react-intl';

const Notifications = () => {

  //current user profile
  const userProfile = useSelector((state: any) => state.firebase.profile);
  //current user permissions
  const userPermissions = useSelector((state: any) => state.firestore.data.role?.permissions || { isNotLoaded: true });

  // save toolbar visibility
  const [ toolbarVisible, setToolbarVisible ] = useState(false);
  //update form inputs
  const [ formData, setFormData ] = useState({ ...userProfile.notifications });
  const updateFormData = (name: string, value:any ) => {
    if( (formData as any )[name] !== value ) {
      if (!toolbarVisible) setToolbarVisible(true);
      setFormData( ( data: any ) => ({ ...data, [name]: value} ));
    }
  }

  //send data to firestore
  const firestore = useFirestore();
  const updateUserData = () => {
    if ( formData ) {
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key];
        }
      });
    }

    firestore
      .collection('users')
      .doc(userProfile.uid)
      .update({notifications: formData})
      .then(() => {
        setToolbarVisible(false);
        showMessage('The user profile was updated!', "success");
      })
      .catch(function(error) {
        showMessage(error.message, "danger");
      });
  }
  
  // show success or fail messages
  const [ toast, setToast ] = useState({ message: '', color: "success", isOpen: false});
  const showMessage = (message: string, color: string) => {
    setToast( state => ({ ...state, header: 'Profile edition', message, color, isOpen: true} ));
  }

  // Redirect to the profile page without proper permission
  if (!userPermissions.isNotLoaded && !userPermissions['settings.notifications.own.edit']) {
    return <Redirect to="/account/profile-details" push />;
  }
  return (
    <IonPage>
      <AccountHeader page="notifications" />
      <IonContent>
        <IonToast
          {...toast}
          onDidDismiss={ () => setToast(state => ({...state, isOpen: false }))}
          position='bottom'
          cssClass='bottom-toasts'
          animated={true}
          buttons={[{
            text: 'x',
            role: 'cancel',
            handler: () => {}
          }]}
        />
        <IonList>
          <IonItemDivider>
            <FormattedMessage
              id= "account.notifications.description"
              defaultMessage= "You can choose in which cases you will receive an email notifications"
              description="Description text about notification settings"
            />
          </IonItemDivider>
          <IonItem>
            <IonCheckbox
              slot="start"
              checked={formData.commentAdded}
              onIonChange={ (e) => updateFormData('commentAdded', e.detail.checked )}
            />
            <IonLabel>
              <FormattedMessage
                id= "checkbox.new-comment"
                defaultMessage= "A new Comment is added"
                description="Checkbox label"
              />
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              checked={formData.projectSubmitted }
              onIonChange={ (e) => updateFormData('projectSubmitted', e.detail.checked )}
            />
            <IonLabel>
              <FormattedMessage
                id= "checkbox.submitted-project"
                defaultMessage= "A Project is submitted to Review"
                description="Checkbox label"
              />
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              checked={formData.companySubmitted }
              onIonChange={ (e) => updateFormData('companySubmitted', e.detail.checked )}
            />
            <IonLabel>
              <FormattedMessage
                id= "checkbox.sponsor-request"
                defaultMessage= "A Sponsor is requesting access"
                description="Checkbox label"
              />
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      { toolbarVisible &&
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <section className="bottom-controls">
              <div>
                <IonButton className="blue-style min-widthed" onClick={updateUserData}>
                  <FormattedMessage
                    id="button.save-changes"
                    defaultMessage="Save Changes"
                    description="Save changes button label"
                  />
                </IonButton>
              </div>
            </section>
          </IonToolbar>
        </IonFooter>
      }
    </IonPage>
  );
}

export default Notifications;
