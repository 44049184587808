import React, { useState } from 'react';
import firebase from 'firebase/app';

// hooks
import { useSelector } from 'react-redux';

// components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonInput
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';

const Settings: React.FC = () => {

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  const [ limit, setLimit ] = useState(25);
  const inviteUsers = () => {
    const inviteUsersFn = firebase.app().functions('europe-west3').httpsCallable('inviteUsersOnlyOnce');
    inviteUsersFn({ limit });
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="nav.settings"
              defaultMessage="Settings"
              description="Settings nav button"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
            <FormattedMessage
              id="nav.settings"
              defaultMessage="Settings"
              description="Settings nav button"
            />
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          { permissions['settings.users.read'] &&
            <IonItem routerLink="/settings/users" button>
              <IonLabel>
                <FormattedMessage
                  id="nav.users"
                  defaultMessage="Users"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonItem>
          }
          { permissions['companies.all.read'] &&
            <IonItem routerLink="/settings/companies" button>
              <IonLabel>
                <FormattedMessage
                  id="nav.companies"
                  defaultMessage="Companies"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonItem>
          }
          { permissions['settings.roles.read'] &&
            <IonItem routerLink="/settings/roles" button>
              <IonLabel>
                <FormattedMessage
                  id="nav.roles"
                  defaultMessage="Roles"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonItem>
          }
          { permissions['settings.permissions.read'] && 
            <IonItem routerLink="/settings/permissions">
              <IonLabel>
                <FormattedMessage
                  id="nav.permissions"
                  defaultMessage="Permissions"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonItem>
          }
          { permissions['settings.taxonomies.read'] && 
            <IonItem routerLink="/settings/taxonomies" button lines="none">
              <IonLabel>
                <FormattedMessage
                  id="nav.taxonomies"
                  defaultMessage="Taxonomies"
                  description="Settings nav button"
                />
              </IonLabel>
            </IonItem>
          }
        </IonList>
        { permissions['settings.users.invite'] &&
          <p style={{ textAlign: 'center' }}>
            <IonInput value={limit} onIonChange={(e) => setLimit(parseInt(e.detail.value || '0', 10))} />
            <IonButton onClick={() => inviteUsers()}>Invite everybody</IonButton>
          </p>
        }
      </IonContent>
    </IonPage>
  );
};

export default Settings;
