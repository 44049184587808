import React, { useEffect, useRef, useState } from 'react';

// hooks
import { Redirect, useParams } from 'react-router';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

// components
import { IonButton, IonContent, IonFooter, IonItem, IonLabel, IonList, IonPage, IonToolbar } from '@ionic/react';
import ProjectHeader from '../components/projects/ProjectHeader';
import LoadingScreen from '../components/LoadingScreen';
import ProjectFormField from '../components/projects/ProjectFormField';
import ProjectDataValue from '../components/projects/ProjectDataValue';
import { FormattedMessage, useIntl } from 'react-intl';
import CardGrid from '../components/CardGrid';
import CardGridItem from '../components/CardGridItem';
import ProjectSponsoringModal from '../modals/ProjectSponsoringModal';

// types
import { ProjectState } from '../models/Project';


const ProjectSponsoring = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const intl = useIntl();

  const { pid } = useParams<{ pid: string }>();

  // project
  const project = useCollectionItem('projects', pid) as ProjectState;

  // get the user's id
  const uid = useSelector((state: any) => state.firebase.profile?.disguise?.uid || state.firebase.auth.uid);
  const gropupId = useSelector((state: any) => state.firebase.profile?.disguise?.groupId || state.firebase.profile?.groupId);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});
  
  // get download urls
  const [ fileUrls, setFileUrls ] = useState<{ [fileId: string]: any }>({});
  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setFileUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }
  useEffect(() => {
    if (project.state.isLoaded && !project.state.isEmpty && project.item) {
      if (project.item.sponsoring && project.item.sponsoring.documents && project.item.sponsoring.documents.length) {
        project.item.sponsoring.documents.forEach((file: any) => {
          if (file.fullPath) {
            getDownloadUrl(file.fid || file.id, file.fullPath);
          }
        });
      }
      if (project.item.sponsoring && project.item.sponsoring.visual && project.item.sponsoring.visual.length) {
        project.item.sponsoring.visual.forEach((file: any) => {
          if (file.fullPath) {
            getDownloadUrl(file.id, file.fullPath);
          }
        });
      }
      if (project.item.sponsoring && project.item.sponsoring.budget && project.item.sponsoring.budget.length) {
        project.item.sponsoring.budget.forEach((file: any) => {
          if (file.fullPath) {
            getDownloadUrl(file.id, file.fullPath);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [project.item]);


  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<{ [prop: string]: any }>(project.item);

  useEffect(() => {
    if (
      project.state.isLoaded &&
      !project.state.isEmpty
    ) {
      setFormData(project.item.sponsoring || {});
    }
    // eslint-disable-next-line
  }, [project.item]);


  const [toolbarVisible, setToolbarVisible] = useState(false);

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any, checked?: boolean) => {
    if (checked !== undefined) {
      setToolbarVisible(true);
      const updatedData = { ...formData, [property]: { ...formData[property], [value]: checked } };
      setFormData(updatedData);
    } else if (!formData) {
      setFormData(data => ({ ...data, [property]: value }));
    } else if ((formData as any)[property] === undefined && value === '<p></p>') {
    } else if ((formData as any)[property] !== value) {
      setToolbarVisible(true);
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // function that is saving the data
  const updateItem = () => {
    // checking the permission first
    if (permissions['projects.sponsoring.edit'] || permissions['projects.all.edit']) {

      // clearing undefined values — Firestore don't like theese
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key]
        }
      });

      const filledFields = Object.keys(formData)
        .filter(fieldName => {
          // filter fields by names
          if (['participation', 'updatedBy', 'updatedOn'].indexOf(fieldName) < 0) {
            // checking value
            const fieldValue = formData[fieldName];
            if (
              fieldValue !== '<p></p>' &&
              // here's the trick — Object.keys(a).length returns 0
              // - when a is an empty string
              // - when a is an empty object
              // - when a is an empty array
              Object.keys(fieldValue).length !== 0
            ) {
              return true;
            }
          }
          return false;
        }).length;
      const sponsoringCompletionRate = Math.round(filledFields / 13 * 100);
      
      // sending to the firestore
      firestore
        .collection('projects')
        .doc(pid)
        .update({
          sponsoring: {
            ...formData,
            updatedOn: new Date().toISOString(),
            updatedBy: uid
          },
          date: new Date().toISOString(),
          sponsoringCompletionRate: sponsoringCompletionRate
        })
        .then(() => {
          setToolbarVisible(false);
        }).catch(function(error) {
          //there was an error when updating project data, show error
          console.log('error: ', error.message );
        });
    }
  }


  const [ popupOpened, setPopupOpened ] = useState(false);
  const pageRef = useRef();
  const previewPopup = () => {
    setPopupOpened(true);
  }

  if (
    (
      project.state.isNotLoaded ||            // project is unavailable
      project.state.isLoaded                  // project is loaded
    ) &&
    Object.keys(permissions).length &&         // permissions are loaded
    !permissions['projects.all.edit'] &&       // it's not an admin
    !(permissions['projects.group.edit'] && !!project.item?.access[gropupId]) && // it's not a project leader
    !permissions['projects.sponsoring.edit']   // don't have rights to edit sponsoring info
  ) {
    return <Redirect to={`/projects/${pid}`} />
  } else {
    return (
      <IonPage ref={pageRef}>
        <ProjectHeader pid={pid} page="sponsoring"/>
        { (project.state.isNotLoaded || project.state.isLoading || project.state.isNotFound || project.state.isEmpty)
          ? <LoadingScreen />
          : <IonContent>
              <IonList>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id="form.image-visual"
                      defaultMessage="Visual Image"
                      description="Form input field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'files', name: 'visual', multiple: false, accept: '.jpg,.gif,.jpeg,.png,image/*' }}
                    value={formData? formData['visual'] : ''}
                    updateData={updateData}
                    pid={pid}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id="form.project-name"
                      defaultMessage="Project Name"
                      description="Project Name field label"
                    />
                  </IonLabel>
                  <ProjectDataValue field={{
                    name: 'title',
                    type: 'text'
                  }} value={project.item && project.item.title} />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                  <FormattedMessage
                    id= "form.card-description"
                    defaultMessage= 'Card Description (required)'
                    description= "Card description field on all form"
                  />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'text', name: 'cardDescription' }}
                    value={formData? formData['cardDescription'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.youtube.describe"
                      defaultMessage= 'Video describing the project (YouTube link)'
                      description= "Input field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'text', name: 'youTubeLink' }}
                    value={formData? formData['youTubeLink'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.description"
                      defaultMessage= 'Description'
                      description= "Input field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'rich-text', name: 'description' }}
                    value={formData? formData['description'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.doc.sponsorship"
                      defaultMessage= 'Sponsorship files'
                      description= "Sponsorship Document file field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'files', name: 'documents', multiple: true }}
                    value={formData? formData['documents'] : ''}
                    updateData={updateData}
                    pid={pid}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.doc.budget-file"
                      defaultMessage= 'Project budget'
                      description= "Sponsorship Budget file field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'files', name: 'budget', multiple: false }}
                    value={formData? formData['budget'] : ''}
                    updateData={updateData}
                    pid={pid}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                  <FormattedMessage
                    id='form.territory'
                    defaultMessage='Territory'
                    description='Project revision form Territory field label'
                  />&nbsp;
                  <FormattedMessage
                    id='form.required'
                    defaultMessage='(required)'
                    description='2nd label for required input fields'
                  />
                  </IonLabel>
                  <ProjectFormField
                    field={{
                      name: 'region',
                      type: 'select',
                      multiple: true,
                      options: [
                        { name: 'PROSUD', value: 'esch22-prosud' },
                        { name: 'CCPHVA', value: 'esch22-ccphva' },
                        { name: 'Europe', value: 'europe' },
                      ],
                    }}
                    value={formData? formData['region'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id='form.partnership'
                      defaultMessage='Type of partnership'
                      description='2nd label for required input fields'
                    />
                    </IonLabel>
                  <ProjectFormField
                    field={{
                      name: 'type',
                      type: 'select',
                      multiple: true,
                      options: [
                        { 
                          name: intl.formatMessage({
                            id: "form.select.finance",
                            defaultMessage: 'Finance',
                            description: "Select input other item"
                          }),
                          value: 'financial'
                        },
                        { 
                          name: intl.formatMessage({
                            id: "form.select.nature",
                            defaultMessage: 'In nature',
                            description: "Select input other item"
                          }),
                          value: 'natural'
                        },
                        { 
                          name: intl.formatMessage({
                            id: "form.select.skills",
                            defaultMessage: 'Skills',
                            description: "Select input other item"
                          }),
                          value: 'experience'
                        },
                        { 
                          name: intl.formatMessage({
                            id: "form.select.other",
                            defaultMessage: 'Other',
                            description: "Select input other item"
                          }),
                          value: 'other' 
                        }
                      ],
                    }}
                    value={formData? formData['type'] : ''}
                    updateData={updateData}
                  />
                </IonItem>
                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id="form.partnership-details"
                      defaultMessage="Details of the partnership"
                      description="Details of the partnership field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'rich-text', name: 'partnershipDetails' }}
                    value={formData? formData['partnershipDetails'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id="form.contact-person"
                      defaultMessage="Contact Person"
                      description="Input field label"
                    />&nbsp;
                    <FormattedMessage
                      id='form.required'
                      defaultMessage='(required)'
                      description='2nd label for required input fields'
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'text', name: 'contactPerson' }}
                    value={formData? formData['contactPerson'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                      <FormattedMessage
                        id= "form.contact-person.email"
                        defaultMessage= 'Contact Person: E-mail address'
                        description= "Contact Person: Email field on all form"
                      />&nbsp;
                      <FormattedMessage
                        id='form.required'
                        defaultMessage='(required)'
                        description='2nd label for required input fields'
                      />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'text', name: 'contactEmail' }}
                    value={formData? formData['contactEmail'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.contact-person.phone"
                      defaultMessage= 'Contact Person: Phone number'
                      description= "Contact Person: Phone number field on all form"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'text', name: 'contactPhone' }}
                    value={formData? formData['contactPhone'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel position="stacked">
                    <FormattedMessage
                      id= "form.additional-information"
                      defaultMessage= 'Additional information'
                      description= "Additional information field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{ type: 'rich-text', name: 'remarks' }}
                    value={formData? formData['remarks'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

                <IonItem className="project--section-item">
                  <IonLabel className="closer-ion-label" position="stacked">
                    <FormattedMessage
                      id= "checkbox.project-view-authorization"
                      defaultMessage= 'I authorize that the sponsors have access to certain data of my project.'
                      description= "Additional information field label"
                    />
                  </IonLabel>
                  <ProjectFormField
                    field={{
                      type: 'checkbox',
                      name: 'participation',
                    }}
                    value={formData? formData['participation'] : ''}
                    updateData={updateData}
                  />
                </IonItem>

              </IonList>

              { popupOpened &&
                <ProjectSponsoringModal
                  opened={popupOpened}
                  setOpened={setPopupOpened}
                  pageRef={pageRef.current}
                  project={project.item}
                  visualsUrl={formData && formData['visual'] ? fileUrls[formData['visual'][0]?.id] : undefined}
                  hideFavorites={true}
                />
              }
              <CardGrid title={
                  intl.formatMessage({
                    id: 'sponsors.card-preview',
                    defaultMessage: 'Preview',
                    description: 'Title for the preview section in Partners and Projects partnership'
                  })
                }>
                  <CardGridItem
                    id={pid}
                    visualsUrl={formData && formData['visual'] ? fileUrls[formData['visual'][0]?.id] : undefined}
                    title={(project.item && project.item.title) || 'Untitled Project'}
                    description={formData ? formData['cardDescription'] : ''}
                    onReadMore={() => previewPopup()}
                  />
                </CardGrid>
            </IonContent>
        }
        { toolbarVisible &&
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <section className="bottom-controls">
                <div>
                  <IonButton className="blue-style" onClick={() => updateItem()}>
                    <FormattedMessage
                      id="button.save-changes"
                      defaultMessage="Save Changes"
                      description="Save changes button label"
                    />
                  </IonButton>
                </div>
              </section>
            </IonToolbar>
          </IonFooter>
        }
      </IonPage>
    )
  }
}

export default ProjectSponsoring;
