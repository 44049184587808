import React from 'react';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { store, rrfProps } from './store';

import Intl from './components/Intl';
import Auth from './components/auth/Auth';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Navigation from './components/Navigation';
import DataLoader from './components/DataLoader';

// pages
import ProjectsList from './pages/ProjectsList';
import Project from './pages/Project';
import ProjectEdit from './pages/ProjectEdit';
import ProjectComments from './pages/ProjectComments';
import ProjectSponsoring from './pages/ProjectSponsoring';
import ProjectDocuments from './pages/ProjectDocuments';
import ProjectRevisions from './pages/ProjectRevisions';

import Resources from './pages/Resources';
import ResourcesCategory from './pages/ResourcesCategory';
import SponsorsList from './pages/SponsorsList';

import Settings from './pages/Settings';
import Account from './pages/Account';
import UsersList from './pages/UsersList';
import CompaniesList from './pages/CompaniesList';
import RolesList from './pages/RolesList';
import PermissionsList from './pages/PermissionsList';
import ProfileDetails from './pages/ProfileDetails';
import ChangePassword from './pages/ChangePassword';
import TaxonomiesList from './pages/TaxonomiesList';
import TaxonomyTermList from './pages/TaxonomyTermList';
import Notifications from './pages/Notifications';
import CompanyProfile from './pages/CompanyProfile';
import AccountLanguage from './pages/AccountLanguage';
import ProjectRevisionview from './pages/ProjectRevisionview';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* CSS */
import './theme/general.scss';
import './theme/variables.css';
import './theme/responsive.scss';

const App: React.FC = () => (
  <IonApp>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Intl>
          <Auth>
            <DataLoader>
              <IonReactRouter>
                <Navigation>
                  <IonRouterOutlet id="main">
                    <Route path="/projects" component={ProjectsList} exact={true} />
                    <Route path="/projects/favorites" component={ProjectsList} exact={true} />
                    <Route path="/projects/:pid" component={Project} exact={true} />
                    <Route path="/projects/:pid/edit" component={ProjectEdit} exact={true} />
                    <Route path="/projects/:pid/comments" component={ProjectComments} exact={true} />
                    <Route path="/projects/:pid/partnership" component={ProjectSponsoring} exact={true} />
                    <Route path="/projects/:pid/documents" component={ProjectDocuments} exact={true} />
                    <Route path="/projects/:pid/revisions" component={ProjectRevisions} exact={true} />
                    <Route path="/projects/:pid/revisions/:vid" component={ProjectRevisionview} exact={true} />
                    <Route path="/resources" component={Resources} exact={true} />
                    <Route path="/resources/:category" component={ResourcesCategory} exact={true} />
                    <Route path="/sponsors" component={SponsorsList} exact={true} />
                    <Route path="/sponsors/favorites" component={SponsorsList} exact={true} />
                    <Route path="/settings" component={Settings} exact={true} />
                    <Route path="/settings/users" component={UsersList} exact={true} />
                    <Route path="/settings/companies" component={CompaniesList} exact={true} />
                    <Route path="/settings/companies/:companyId" component={CompanyProfile} exact={true} />
                    <Route path="/settings/roles" component={RolesList} exact={true} />
                    <Route path="/settings/permissions" component={PermissionsList} exact={true} />
                    <Route path="/settings/taxonomies" component={TaxonomiesList} exact={true} />
                    <Route path="/settings/taxonomies/:taxonomy" component={TaxonomyTermList} exact={true} />
                    <Route path="/account" component={Account} exact={true} />
                    <Route path="/account/company" component={CompanyProfile} exact={true} />
                    <Route path="/account/profile-details" component={ProfileDetails} exact={true} />
                    <Route path="/account/change-password" component={ChangePassword} exact={true} />
                    <Route path="/account/notifications" component={Notifications} exact={true} />
                    <Route path="/account/language" component={AccountLanguage} exact={true} />
                    <Route exact path="/" render={() => <Redirect to="/projects" />} />
                  </IonRouterOutlet>
                </Navigation>
              </IonReactRouter>
            </DataLoader>
          </Auth>
        </Intl>
      </ReactReduxFirebaseProvider>
    </Provider>
  </IonApp>
);

export default App;
