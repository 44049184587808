import React from 'react';
import { formatFileSize } from '../utils';

// components
import { IonButton, IonIcon } from '@ionic/react';
import { document, trashOutline } from 'ionicons/icons';

import './ResourceTile.scss';


type ResourceTileProps = {
  name: string;
  size: number;
  type: string;
  date: string;
  url: string;
  onDelete?: Function;
}

const ResourceTile = ({ name, size, url, onDelete }: ResourceTileProps) => {
  return (
    <div className="resource-tile--container">
      <IonButton
        fill="clear"
        className="resource-tile"
        href={url}
        target="_blank"
        disabled={!url}
      >
        <div className="resource-tile--content">
          <IonIcon icon={document} className="resource-tile--icon" />
          <h2 className="resource-tile--title">{ name }</h2>
          <p className="resource-tile--size">{ formatFileSize(size) }</p>
        </div>
      </IonButton>
      { onDelete &&
        <IonButton
          fill="clear"
          color="medium"
          size="small"
          shape="round"
          className="resource-tile--delete"
          onClick={() => onDelete()}
        >
          <IonIcon icon={trashOutline} slot="icon-only"/>
        </IonButton>
      }
    </div>
  )
}

export default ResourceTile;
