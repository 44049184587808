import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// components
import {
  IonPage,
  IonContent
} from '@ionic/react';
import AuthHat from './AuthHat';
import AuthMessage, { AuthMessageType } from './AuthMessage';
import AuthTitle from './AuthTitle';
import AuthLogin from './AuthLogin';
import AuthRegister from './AuthRegister';
import AuthResetPassword from './AuthResetPassword';
import AuthChangePassword from './AuthChangePassword';
import AuthChooseRegistration from './AuthChooseRegistration';
import AuthRegisterSponsor from './AuthRegisterSponsor';
import { FormattedMessage, useIntl } from 'react-intl';

type AuthFormsProps = {
  createPasswordCode: string;
  clearState?: Function;
  isUserAction?: boolean;
  registerPath?: string;
}


const AuthForms = ({ createPasswordCode, clearState, isUserAction, registerPath }: AuthFormsProps) => {

  //check languge from path
  const intl = useIntl();
  const changeLocale = (newLocale: string) => {
    if (newLocale !== intl.locale) {
      dispatch({ type: 'SET_LOCALE', payload: newLocale });
    }
  }

  // which form to show
  const [ formType, setFormType ] = useState<string>(createPasswordCode !== '' ?  'create-password' : 'sign-in');
  useEffect(() => {
    if (registerPath === '/en/create-a-partner-account') {
      setFormType('sign-sponsor');
      changeLocale('en');
    } else if (registerPath === '/fr/creer-un-compte-partenaire') {
      setFormType('sign-sponsor');
      changeLocale('fr');
    } else if (createPasswordCode === '') {
      setFormType('sign-in');
    }
    // eslint-disable-next-line
  }, [createPasswordCode]);

  const [ messageType, setMessageType ] = useState<AuthMessageType>('');
  //state for gdpr checkbox
  const [ gdprCheck, setGdprCheck ] = useState({ checked: false, dateTime: ''});
  function changeFormType(evt: any) {
    if (typeof evt === 'string') {
      setFormType(evt);
    } else {
      setFormType(evt.detail.value);
    }
    // clear auth errors
    dispatch({ type: '@@reactReduxFirebase/LOGIN_ERROR', authError: null });
    setErrorMessage('');
    window.history.pushState({}, document.title, "/" );
  }

  // get data from redux
  const authError = useSelector((state: any) => state.firebase.authError);
  const [ errorMessage, setErrorMessage ] = useState('');
  const dispatch = useDispatch();

  // set internal state for the inputs
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const email = useFormInput('');
  const password = useFormInput('');
  const company = useFormInput('');
  const jobPosition = useFormInput('');

  // display login errors
  function getError() {
    if (authError && authError.message) {
      if (authError.code) {
        switch (authError.code) {
          case 'auth/email-already-exists':
            return <div className="auth-error">
              <FormattedMessage
                id="auth.errors.email-already-exists"
                defaultMessage="The email address is already in use by another account."
                description="Firebase auth error: trying to register already registered account"
              />
            </div>;
          case 'auth/invalid-email':
            return <div className="auth-error">
              <FormattedMessage
                id="auth.errors.invalid-email"
                defaultMessage="The email address is badly formatted."
                description="Firebase auth error: trying to login or register not en email"
              />
            </div>;
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            return <div className="auth-error">
              <FormattedMessage
                id="auth.errors.wrong-password"
                defaultMessage="The email or password is incorrect."
                description="Firebase auth error: wrong credentials"
              />
            </div>;
          case 'auth/invalid-password':
          case 'auth/weak-password':
            return <div className="auth-error">
              <FormattedMessage
                id="auth.errors.weak-password"
                defaultMessage="Password should be at least 6 characters"
                description="Firebase auth error: weak password"
              />
            </div>;
          case 'auth/invalid-action-code':
          case 'auth/expired-action-code':
            return <div className="auth-error">
              <FormattedMessage
                id="auth.errors.expired-action-code"
                defaultMessage="The procedure has expired, please go back to Sign In and click on the link “Forgot password?” in the bottom."
                description="Firebase auth error: weak password"
              />
            </div>;
          default:
            return <div className="auth-error">{authError.message}</div>
        }
      } else if (
        authError.message === 'The action code is invalid.' ||
        authError.message === 'The action code has expired.'
      ) {
        return <div className="auth-error">
          <FormattedMessage
            id="auth.errors.expired-action-code"
            defaultMessage="The procedure has expired, please go back to Sign In and click on the link “Forgot password?” in the bottom."
            description="Firebase auth error: weak password"
          />
        </div>
      } else {
        try {
          const errorMsg = JSON.parse(authError.message);
          if (errorMsg && errorMsg.error && errorMsg.error.message) {
            return <div className="auth-error">{errorMsg.error.message}</div>
          }
        } catch(e) {
          return <div className="auth-error">{authError.message}</div>
        }
      }
    } else if (errorMessage && errorMessage !== '') {
      return <div className="auth-error">{errorMessage}</div>
    }
    return null;
  }


  // function that shows the Sign In form and clears the message
  const backToSignIn = () => {
    changeFormType('sign-in');
    setMessageType('');
  }

  if (messageType === '') {
    return (
      <IonPage>
        <IonContent fullscreen className="auth-content">
          <AuthHat />
          <div className="center-element auth-content--center">
            <div className={classNames({
              'auth-form': true,
              'auth-form-choose': formType === 'choose-registration'
            })}>
              <AuthTitle toWhichForm={(formType === 'create-password' && !isUserAction) ? 'reset-password-expired' : formType}/>
              { formType === 'sign-in'
                ? <AuthLogin
                    email={email}
                    password={password}
                    errors={getError()}
                    changeFormType={changeFormType}
                  />
                : formType === 'sign-up'
                ? <AuthRegister
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    password={password}
                    gdprCheck={gdprCheck}
                    errors={getError()}
                    setErrorMessage={setErrorMessage}
                    setGdprCheck={setGdprCheck}
                    changeFormType={changeFormType}
                  />
                : formType === 'sign-sponsor'
                ? <AuthRegisterSponsor
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    password={password}
                    company={company}
                    jobPosition={jobPosition}
                    gdprCheck={gdprCheck}
                    errors={getError()}
                    setErrorMessage={setErrorMessage}
                    setGdprCheck={setGdprCheck}
                    changeFormType={changeFormType}
                  />
                : (formType === 'reset-password' || (formType === 'create-password' && !isUserAction))
                ? <AuthResetPassword
                    email={email}
                    errors={getError()}
                    changeFormType={changeFormType}
                    setMessageType={setMessageType}
                    clearState={clearState}
                  />
                : formType === 'create-password'
                ? <AuthChangePassword
                    password={password}
                    errors={getError()}
                    createPasswordCode={createPasswordCode}
                    changeFormType={changeFormType}
                    setMessageType={setMessageType}
                    clearState={clearState}
                  />
                : formType === 'choose-registration'
                ? <AuthChooseRegistration
                    changeFormType={changeFormType}
                  />
                : <></>
              }
            </div>

            <div className="auth-form--aligner"></div>
          </div>
        </IonContent>
      </IonPage>
    )
  } else {
    return <AuthMessage type={messageType} email={email.value} clearState={backToSignIn} />
  }
}

export default AuthForms;


function useFormInput(initialValue: string) {
  const [value, setValue] = useState(initialValue);

  function handleChange(evt: any) {
    if (evt && evt.target && typeof evt.target.value === 'string') {
      setValue(evt.target.value);
    }
  }

  return {
    value,
    onInput: handleChange
  };
}
