import React, { useRef, useState } from 'react';
import classNames from 'classnames';

// hooks
import { useHistory } from 'react-router';

// components
import { IonButtons, IonButton, IonBadge, IonIcon, IonPopover, IonList, IonItem, IonLabel } from '@ionic/react';

// styling
import './TopNavigation.scss';
import { caretDown } from 'ionicons/icons';

type TopNavItem = {
  id: string;
  title: string;
  href?: string;
  onClick?: Function;
  disabled?: boolean;
  hidden?: boolean;
  badge?: string | number;
}

type TopNavProps = {
  items: TopNavItem[];
  selected: string;
}

const TopNavigation = ({ items, selected }: TopNavProps) => {
  // hook from react-router to change pages
  const history = useHistory();

  // function to change route to given url
  const goto = (url: string) => {
    history.replace(url);
  }

  // popover menu  
  const popoverRef = useRef<any>(null);
  const [ popover, setPopover ] = useState<{ isOpen: boolean, event: Event | undefined }>({ isOpen: false, event: undefined });
  const dismissPopover = () => {
    popoverRef.current?.dismiss();
    setPopover({ isOpen: false, event: undefined });
  }

  return (
    <IonButtons slot="primary" className="top-navigation">
      { items
          .filter(item => !item.hidden)
          .map(item => (
            <IonButton
              key={item.id}
              className={classNames({
                'only-desktop': true,
                'selected': item.id === selected
              })}
              disabled={item.disabled || item.id === selected}
              onClick={() => { if (item.href) { goto(item.href) } else if (item.onClick) { item.onClick() } }}
            >
              { item.title }
              { item.badge &&
                <IonBadge className="top-navigation--badge" color="medium">{ item.badge }</IonBadge>
              }
            </IonButton>
          ))
      }
      <IonButton
        className="only-mobile"
        onClick={(e) => setPopover({ isOpen: true, event: e.nativeEvent })}
      >
        { items.filter(item => item.id === selected).map(item => item.title).join('') }
        <IonIcon slot="end" icon={caretDown} style={{ fontSize: '10px' }} />

        <IonPopover
          {...popover}
          ref={popoverRef}
          onDidDismiss={dismissPopover}
        >
          <IonList>
            { items
              .filter(item => !item.disabled && !item.hidden)
              .map((item, idx, all) => (
                <IonItem key={item.id}
                  button
                  detail={false}
                  onClick={() => {
                    dismissPopover();
                    if (item.href) { goto(item.href) } else if (item.onClick) { item.onClick() }
                  }}
                  lines={ idx === all.length - 1 ? 'none' : 'inset' }
                >
                  <IonLabel>{ item.title }</IonLabel>
                  { item.badge &&
                    <IonBadge slot="end" color="medium">{ item.badge }</IonBadge>
                  }
                </IonItem>
              ))
            }
          </IonList>
        </IonPopover>
      </IonButton>
    </IonButtons>
  )
}

export default TopNavigation;
