import React, { useState } from 'react';
import * as firebase from 'firebase/app';

import { IonPage, IonContent, IonIcon, IonButton } from '@ionic/react';
import { checkmarkCircle, closeCircle, helpCircle } from 'ionicons/icons';
import AuthHat from './AuthHat';

import './AuthMessage.scss';
import { useIntl } from 'react-intl';

export type AuthMessageType = ''
  | 'verify-email'
  | 'email-verification-checking'
  | 'email-verified'
  | 'email-verification-error'
  | 'reset-password'
  | 'password-changed'
  | 'no-access'
  | 'under-construction';

type AuthMessageProps = {
  type: AuthMessageType;
  email?: string;
  error?: any;
  clearState?: Function;
}

const AuthMessage = ({ type, email, error, clearState}: AuthMessageProps) => {
  const intl = useIntl();

  const [ resendError, setResendError ] = useState('');
  const resendEmailVerification = () => {

    const verifyEmail = firebase.app().functions('europe-west3').httpsCallable('verifyEmail');
    verifyEmail({
      lang: intl.locale
    })
      .then((res: any) => {
        setResendError(intl.formatMessage({
          id: 'auth.message.email-verification-resend',
          defaultMessage: 'Verification email was send one more time.',
          description: 'Message description after email verification resend'
        }));
      })
      .catch((err: any) => {
        setResendError(err);
      });
  }

  const signOut = () => {
    firebase.auth().signOut();
  }

  const backToAuth = () => {
    if (clearState) {
      clearState();
    }
  }

  const messages = {
    '': {
      icon: helpCircle,
      title: '',
      description: '',
      button: false,
      buttonAction: () => {},
      button2: false,
      button2Action: () => {}
    },
    'verify-email': {
      icon: checkmarkCircle,
      title: intl.formatMessage({
        id: 'auth.message.email-verify',
        defaultMessage: 'Your account has been succesfully created.',
        description: 'Message title after account creation',
      }),
      description: intl.formatMessage({
        id: 'auth.message.email-verify.description',
        defaultMessage: 'An email was sent to {email}. Please, click on the link in it, to confirm that you have access to this address.',
        description: 'Message description after account creation',
      },{
        email: email
      }),
      button: intl.formatMessage({
        id: 'button.send-again',
        defaultMessage: 'Send Again',
        description: 'Send Again verification email',
      }),
      buttonAction: resendEmailVerification,
      button2: intl.formatMessage({
        id: 'button.back-sign-in',
        defaultMessage: 'Back to Sign In',
        description: 'Back to Sign In',
      }),
      button2Action: signOut,
    },
    'email-verification-checking': {
      icon: helpCircle,
      title: intl.formatMessage({
        id: 'auth.message.email-checking',
        defaultMessage: 'Your email is verifying',
        description: 'Email verification in progress',
      }),
      description: intl.formatMessage({
        id: 'auth.message.email-checking.description',
        defaultMessage: 'Wait a little bit to check is the code valid.',
        description: 'Email verification in progress, wait a minit text',
      }),
      button: false,
      buttonAction: () => {},
      button2: false,
      button2Action: () => {}
    },
    'email-verified': {
      icon: checkmarkCircle,
      title: intl.formatMessage({
        id: 'auth.message.email-ok',
        defaultMessage: 'Your email was verified succesfully',
        description: 'Email verified title',
      }),
      description: intl.formatMessage({
        id: 'auth.message.email-ok.description',
        defaultMessage: 'You may have to sign in again.',
        description: 'Email ok, may sign in again',
      }),
      button: intl.formatMessage({
        id: 'button.continue',
        defaultMessage: 'Continue',
        description: 'Button continue',
      }),
      buttonAction: backToAuth,
      button2: false,
      button2Action: () => {}
    },
    'email-verification-error': {
      icon: closeCircle,
      title: intl.formatMessage({
        id: 'auth.message.email-error',
        defaultMessage: 'Your email verification got an error',
        description: 'Email verification error message title',
      }),
      description: error || intl.formatMessage({
        id: 'auth.message.email-error.description',
        defaultMessage: 'Please try to sign in again and get a new email.',
        description: 'Email verification error message description',
      }),
      button: intl.formatMessage({
        id: 'button.continue',
        defaultMessage: 'Continue',
        description: 'Button continue',
      }),
      buttonAction: backToAuth,
      button2: false,
      button2Action: () => {}
    },
    'reset-password': {
      icon: checkmarkCircle,
      title: intl.formatMessage({
        id: 'auth.message.password-reset',
        defaultMessage: 'We have sent password reset instructions to {email}',
        description: 'Password reset message title',
      },{ 
        email: email
      }),
      description: intl.formatMessage({
        id: 'auth.message.password-reset.description',
        defaultMessage: 'Please, follow the link in the email, to change your password.',
        description: 'Password reset message description',
      }),
      button: intl.formatMessage({
        id: 'button.back-sign-in',
        defaultMessage: 'Back to Sign In',
        description: 'Back to Sign In',
      }),
      buttonAction: backToAuth,
      button2: false,
      button2Action: () => {}
    },
    'password-changed': {
      icon: checkmarkCircle,
      title: intl.formatMessage({
        id: 'auth.message.password-changed',
        defaultMessage: 'Your password has been changed.',
        description: 'Password changed message title',
      }),
      description: intl.formatMessage({
        id: 'auth.message.password-changed.description',
        defaultMessage: 'Please sign in with your new password.',
        description: 'Password changed message description',
      }),
      button: intl.formatMessage({
        id: 'button.back-sign-in',
        defaultMessage: 'Back to Sign In',
        description: 'Back to Sign In',
      }),
      buttonAction: backToAuth,
      button2: false,
      button2Action: () => {}
    },
    'no-access': {
      icon: checkmarkCircle,
      title: intl.formatMessage({
        id: 'auth.message.no-access',
        defaultMessage: 'Your account is waiting for approval.',
        description: 'Account verification is pending',
      }),
      description: intl.formatMessage({
        id: 'auth.message.no-access.description',
        defaultMessage: 'Now it\'s up to Esch 2022 Administration to approve your account and let you in.',
        description: 'Account verification is pending, at Esch admin',
      }),
      button: intl.formatMessage({
        id: 'button.sign-out',
        defaultMessage: 'Sign Out',
        description: 'Button sign out',
      }),
      buttonAction: signOut,
      button2: false,
      button2Action: () => {}
    }
    ,
    'under-construction': {
      icon: '',
      title: 'Coming soon',
      description: '<br>Un problème technique est survenu le 12.08.2020 vous informant d\'un nouveau commentaire dans l\'application Esch2022, nous vous invitons à ignorer cet e-mail. Rendez-vous très bientôt pour découvrir la nouvelle interface, avec plus de fonctionnalités et encore plus intuitive !<br><br> <i>L\'équipe Esch2022</i>',
      button: false,
      buttonAction: () => {},
      button2: false,
      button2Action: () => {}
    }
  }
  
  return (
    <IonPage>
      <IonContent fullscreen className="auth-content">
        <AuthHat />
        <div className="center-element auth-content--center">
          <div className="auth-message">
            <IonIcon icon={messages[type].icon} className="auth-message--type" />
            <h1 className="auth-message--title">{ messages[type].title }</h1>
            <p className="auth-message--description"
              dangerouslySetInnerHTML={{ __html: resendError || messages[type].description }}
            ></p>
            { messages[type].button &&
              <p><IonButton className="reversed-colors blue-style" onClick={messages[type].buttonAction}>{ messages[type].button }</IonButton></p>
            }
            { messages[type].button2 &&
              <p><IonButton color="dark" className="over-red-ion-button" fill="clear" onClick={messages[type].button2Action}>{ messages[type].button2 }</IonButton></p>
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default AuthMessage;
