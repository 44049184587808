import React, { useState, useEffect } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestoreCollectionQuery } from '../../hooks/useFirestoreCollectionQuery';
import { useFirestore } from 'react-redux-firebase';

// components
import { IonList, IonItem, IonLabel } from '@ionic/react';
import ProjectFormField from './ProjectFormField';
import { FormattedMessage, useIntl } from 'react-intl';

// types and constants
import { staticTaxonomies, TaxonomiesState } from '../../models/Taxonomies';
import { ProjectState } from '../../models/Project';


type ProjectReviewFormProps = {
  project: ProjectState;
  saveData?: boolean;
  showSaveButton: Function;
}

const ProjectReviewForm = ({ project, saveData, showSaveButton }: ProjectReviewFormProps ) => {
  const firestore = useFirestore();
  const intl = useIntl();

  // get the user's id
  const uid = useSelector((state: any) => state.firebase.auth.uid);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});

  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<{ [prop: string]: any }>(project.item);

  //update form data after page load
  useEffect(() => {
    if (
      project.state.isLoaded &&
      !project.state.isEmpty &&
      (!formData || project.item.pid !== formData.pid)
    ) {
      setFormData(project.item);
    }
    // eslint-disable-next-line
  }, [project.item]);
      
  //editable Taxonomies
  const actionRequired = useFirestoreCollectionQuery( 'actionRequired', false) as TaxonomiesState;
  const tags = useFirestoreCollectionQuery( 'tags', false) as TaxonomiesState;
  const region = useFirestoreCollectionQuery( 'region', false) as TaxonomiesState;

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any) => {
    showSaveButton(true);
    if (!formData) {
      setFormData(data => ({ ...data, [property]: value }));
    } else if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // saving is triggered by the saveData property
  useEffect(() => {
    if (saveData) updateItem();
    // eslint-disable-next-line
  }, [saveData]);

  // function that is saving the data
  const updateItem = () => {
    // checking the permission first
    if (permissions['project.review.view']) {

      // clearing undefined values — Firestore don't like theese
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key]
        }
      });

      // sending to the firestore
      firestore
        .collection('projects')
        .doc(project.item.pid)
        .update({
          ...formData,
          date: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
          updatedBy: uid
        })
        .then(() => {
          console.log('saved');
          showSaveButton(false);
        });
    }
  }

  return (
    <>
      { project.state.isLoaded && tags.state.isLoaded && actionRequired.state.isLoaded && region.state.isLoaded &&
        <IonList className="project-view-first-block">
                
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.status"
                defaultMessage="Status"
                description="Project revision form status field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'moderation_state',
                type: 'select',
                multiple: false,
                options: [
                  { 
                    name: intl.formatMessage({
                      id: 'form.select.draft',
                      defaultMessage: 'Draft',
                      description: 'Select form draft label',
                    }),
                    value: 'draft' 
                  },
                  { 
                    name: intl.formatMessage({
                      id: 'form.select.needs-review',
                      defaultMessage: 'Needs Review',
                      description: 'Select form needs_review label',
                    }),
                    value: 'needs_review' 
                  }
                ],
              }}
              value={formData? formData['moderation_state'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.category"
                defaultMessage="Category"
                description="Project revision form category field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'category',
                type: 'select',
                multiple: false,
                options: staticTaxonomies.category.terms
              }}
              value={formData? formData['category'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.deadline"
                defaultMessage="Deadline"
                description="Project revision form deadline field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'deadline',
                type: 'date',
              }}
              value={formData? formData['deadline'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.action-required"
                defaultMessage="Action Required"
                description="Project revision form Action Required field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'actionRequired',
                type: 'select',
                multiple: true,
                options: Object.values(actionRequired.items)
              }}
              value={formData? formData['actionRequired'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.region"
                defaultMessage="Region"
                description="Project revision form Region field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'region',
                type: 'select',
                multiple: true,
                options: Object.values(region.items)
              }}
              value={formData? formData['region'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.discipline1"
                defaultMessage="Discipline I"
                description="Project revision form Discipline I field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'discipline1',
                type: 'select',
                multiple: false,
                options: staticTaxonomies.discipline1.terms
              }}
              value={formData? formData['discipline1'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.discipline2"
                defaultMessage="Discipline II"
                description="Project revision form Discipline II field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'discipline2',
                type: 'select',
                multiple: true,
                options: staticTaxonomies.discipline2.terms
              }}
              value={formData? formData['discipline2'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.public-target"
                defaultMessage="Public Target"
                description="Project revision form Public Target field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'publicTarget',
                type: 'select',
                multiple: true,
                options: staticTaxonomies.publicTarget.terms
              }}
              value={formData? formData['publicTarget'] : ''}
              updateData={updateData}
            />
          </IonItem>

          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.tags"
                defaultMessage="Tags"
                description="Project revision form Tags field label"
              />
            </IonLabel>
            <ProjectFormField
              field={{
                name: 'tags',
                type: 'select',
                multiple: true,
                options: Object.values(tags.items)
              }}
              value={formData? formData['tags'] : ''}
              updateData={updateData}
            />
          </IonItem>

        </IonList>
      }
    </>
  );
}
export default ProjectReviewForm;
