import React, { RefObject } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonSearchbar,
  isPlatform,
  IonFab,
  IonFabButton
} from '@ionic/react';
import { closeOutline, add } from 'ionicons/icons';

import './ModalForm.css';

type ModalFormProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  title: string | Function ;
  children?: React.ReactNode;
  modalRef?: RefObject<HTMLIonModalElement>;
  setSearchStr?: Function;
  searchStr?: string;
  addAction?: Function;
  action?: { title: string; callback: Function }
  className?: string;
}

const ModalForm = (props: ModalFormProps) => {
  return (
    <IonModal
      ref={props.modalRef ? props.modalRef : null}
      isOpen={props.opened}
      onDidDismiss={() => props.setOpened(false)}
      swipeToClose={true}
      presentingElement={props.pageRef ? props.pageRef : undefined}
      cssClass={'modals ' + props.className}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => props.setOpened(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          { ((props.addAction && isPlatform('ios')) || props.action) &&
            <IonButtons slot="primary">
              { props.addAction &&
                <IonButton onClick={() => props.addAction && props.addAction()}>
                  <IonIcon icon={add} slot="icon-only" />
                </IonButton>
              }
              { props.action &&
                <IonButton color="primary" onClick={() => props.action?.callback && props.action.callback()}>
                  { props.action.title }
                </IonButton>
              }
            </IonButtons>
          }
        </IonToolbar>
        { props.setSearchStr &&
          <IonToolbar>
            <IonSearchbar
              value={props.searchStr}
              onIonChange={e => props.setSearchStr && props.setSearchStr(e.detail.value)}
            />
          </IonToolbar>
        }
      </IonHeader>
      <IonContent className="main-content">
        { props.addAction && !isPlatform('ios') &&
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => props.addAction && props.addAction()}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        }
        <div className="modal-form--apart">
          { props.children }
        </div>
      </IonContent>
    </IonModal>
  )
}

export default ModalForm;
