import React, { useRef, useState } from 'react';

// components
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { settingsSharp } from 'ionicons/icons';

import './ActionsButton.scss';

type TActionsButtonProps = {
  actions: {
    title: string;
    action: Function;
    hide?: boolean;
  }[]
}

const ActionsButton = ({ actions }: TActionsButtonProps) => {

  // popover menu  
  const popoverRef = useRef<any>(null);
  const [ popover, setPopover ] = useState<{ isOpen: boolean, event: Event | undefined }>({ isOpen: false, event: undefined });
  const dismissPopover = () => {
    popoverRef.current?.dismiss();
    setPopover({ isOpen: false, event: undefined });
  }
  
  return (
    <>
      <IonButton fill="clear" shape="round" className="actions-button"
        onClick={(e) => setPopover({ isOpen: true, event: e.nativeEvent })}
      >
        <IonIcon icon={settingsSharp} slot="icon-only" color="dark" />
      </IonButton>
      <IonPopover
        {...popover}
        ref={popoverRef}
        onDidDismiss={dismissPopover}
      >
        <IonList>
          { actions
              .filter(action => !action.hide)
              .map((action, idx, arr) => (
                <IonItem
                  key={`action-${idx}`}
                  button
                  detail={false}
                  onClick={() => { dismissPopover(); action.action(); }}
                  lines={idx + 1 === arr.length ? 'none' : 'inset'}
                >
                  <IonLabel>{ action.title }</IonLabel>
                </IonItem>
              ))
          }
        </IonList>
      </IonPopover>
    </>
  )
}

export default ActionsButton;
