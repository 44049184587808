import React, { useState } from 'react';

// hooks
import { useFirebase } from 'react-redux-firebase';

// components
import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';


type AuthRegisterSponsorProps = {
  firstName: any;
  lastName: any;
  email: any;
  password: any;
  company: any;
  jobPosition: any;
  gdprCheck: any;
  errors: any;
  setErrorMessage: Function;
  setGdprCheck: Function;
  changeFormType: Function;
}

const AuthRegisterSponsor = ({
  firstName,
  lastName,
  email,
  password,
  company,
  jobPosition,
  gdprCheck,
  errors,
  setGdprCheck,
  changeFormType
}: AuthRegisterSponsorProps) => {
  const firebase = useFirebase();
  const intl = useIntl();

  const [ errorMessage, setErrorMessage ] = useState('');

  // registration
  function createAccount() {
    // check the fields first
    if (firstName.value === '') {
      setErrorMessage('empty-first-name');
    } else if (lastName.value === '') {
      setErrorMessage('empty-last-name');
    } else if (company.value === '') {
      setErrorMessage('empty-company');
    } else if (jobPosition.value === '') {
      setErrorMessage('empty-job-position');
    } else if (!gdprCheck.checked) {
      setErrorMessage('privacy-policy');
    } else {
      setErrorMessage('');
      window.history.pushState({}, document.title, "/" );

      firebase
        .createUser(
          {
            email: email.value,
            password: password.value
          },
          {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            company: company.value,
            jobPosition: jobPosition.value,
            role: 'unapproved-sponsor', // default role for the new sponsors
            // groupId will be created by the cloud function
            gdpr: gdprCheck.dateTime,
            lang: intl.locale
          }
        )
    }
  }

  // catch the Enter key in the password field to submit the form
  function enterPressed(evt: any) {
    if (evt && evt.which && evt.which === 13) {
      createAccount();
    }
  }

  return (
    <>
      <div className="auth-form-wrap esch">
        <IonList lines="full" className="auth-form-content" class="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.first-name"
                defaultMessage= "First Name"
                description="First Name field on all form"
              />
            </IonLabel>
            <IonInput required type="text" name="firstName"
              {...firstName}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
            <FormattedMessage
              id= "form.last-name"
              defaultMessage= "Last Name"
              description="Last Name field on all form"
            />
            </IonLabel>
            <IonInput required type="text" name="lastName"
              {...lastName}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.email"
                defaultMessage= "Email address"
                description="Email field on all form"
              />
            </IonLabel>
            <IonInput required type="email" name="email"
              {...email}
              autofocus={true}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.password"
                defaultMessage= "Password"
                description="Password field on all form"
              />
            </IonLabel>
            <IonInput required type="password" name="password"
              {...password}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
            <FormattedMessage
              id= "form.company"
              defaultMessage= "Company"
              description="Company field on all form"
            />
            </IonLabel>
            <IonInput required type="text" name="company"
              {...company}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
            <FormattedMessage
              id= "form.job"
              defaultMessage= "Job Position"
              description="Job Position field on all form"
            />
            </IonLabel>
            <IonInput required type="text" name="jobPosition"
              {...jobPosition}
              onKeyPress={enterPressed}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonCheckbox 
              checked={gdprCheck.checked}
              slot="start"
              name="gdpr"
              onIonChange={
                (e) => {
                  setGdprCheck( { checked: e.detail.checked, dateTime : new Date().toISOString() });
                  setErrorMessage('');
                }
              }
            />
            <p>
              <FormattedMessage
                id= "form.privacy.first"
                defaultMessage= "You accept the"
                description="You accept the  ... "
              />&nbsp;
              <a href="https://esch2022.lu/assets/pdf/legal/privacy-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id= "form.privacy.second"
                  defaultMessage= "Privacy policy"
                  description="... Privacy policy"
                />
              </a>
            </p>
          </IonItem>
        </IonList>
        { errorMessage !== ''
          ? <div className="auth-error">
              <FormattedMessage id={'auth.errors.' + errorMessage} />
            </div>
          : errors
        }
        <div className="ion-padding button-container">
          <IonButton
            expand="block" type="submit"
            class="ion-sign-up-sponsor-button"
            onClick={createAccount}
          >
            <FormattedMessage
            id= "auth.register.submit"
            defaultMessage= "Create Account"
            description="Create account submit button label"
          />
          </IonButton>
        </div>
      </div>
      <div className="auth-form-content-switch">
        <IonButton
          className="over-red-ion-button"
          fill="clear" size="small" color="dark"
          onClick={() => changeFormType('sign-in')}
        >
          <FormattedMessage
            id= 'button.back-sign-in'
            defaultMessage= 'Back to Sign In'
            description= 'Back to Sign In'
          />
        </IonButton>
      </div>
    </>
  )
}

export default AuthRegisterSponsor;
