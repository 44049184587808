import React, { useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";

// hooks
import { useFirebase } from "react-redux-firebase";

// components
import {
  IonButton,
  IonCheckbox,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import RichTextField from "../RichTextField";

// types and icons
import { ProjectField } from "../../models/Project";
import { arrowUpCircle, documentOutline, trashOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";
import { File } from "../../models/File";

type ProjectFormFieldProps = {
  field: ProjectField;
  value: any;
  updateData: Function;
  pid?: string;
};

const ProjectFormField = ({
  field,
  value,
  updateData,
  pid = "0",
}: ProjectFormFieldProps) => {
  // file uploading
  const firebase = useFirebase();

  const fileInput = useRef<any>(null);
  const [uploading, setUploading] = useState(false);

  const selectFile = () => {
    if (fileInput.current && fileInput.current.click) {
      fileInput.current.click();
    }
  };

  const uploadFile = async (fieldName: string, fieldValue: any[] = []) => {
    if (fileInput.current.files) {
      // upload files
      setUploading(true);

      const newFiles: any[] = [];
      // reverse the array so the first files will get the latest `created` timestamp
      for (let i = fileInput.current.files.length - 1; i >= 0; i--) {
        const file = fileInput.current.files[i];
        if (file) {
          // create id for the file
          const fileId = nanoid(14);

          await firebase
            .uploadFile(field.path || `projects/${pid}`, file, undefined, {
              name: fileId + "-" + file.name,
              metadata: {
                contentDisposition: `filename="${file.name}"`
              }
            })
            .then(({ uploadTaskSnapshot: { metadata } }: any) => {
              // if file was successfully uploaded
              // add it to the files array
              newFiles.push({
                id: fileId,
                name: file.name,
                contentType: metadata.contentType,
                fullPath: metadata.fullPath,
                size: metadata.size,
                created: metadata.timeCreated,
                updated: metadata.updated,
              });
            })
            .catch((err) => {
              console.error("File upload error:", err);
            });
        }

        updateData(fieldName, [...fieldValue, ...newFiles]);
      }

      // clear the file input
      if (fileInput.current) {
        fileInput.current.value = null;
      }
      setUploading(false);
    }
  };

  // file deleting
  const deleteFile = (fieldName: string, fieldValue = [], fileId: string) => {
    if (fieldValue && fieldValue.length) {
      
      const file = fieldValue.find((file: any) => file.id ? file.id === fileId : file.fid === fileId) as any;
      
      if (file) {
        // const storageRef = firebase.storage().ref(file.fullPath);
        // storageRef.delete()
        //   .then(() => {
        // file is deleted, we need to remove links to it from firestore
        const updatedFiles = fieldValue.filter(
          (file: any) => file.id ? file.id !== fileId : file.fid !== fileId
        );
        updateData(fieldName, updatedFiles);
        //   })
        //   .catch(err => {
        //     console.error('Error deleting file', err, file);

        //     // and anyway remove the record...
        //     const updatedFiles = fieldValue.filter((file: any) => file.id !== fileId);
        //     updateData(fieldName, updatedFiles);
        //   });
      }
    }
  };

  // file urls
  const [fileUrls, setFileUrls] = useState<{ [fileId: string]: any }>({});

  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL().then((downloadUrl: string) => {
      setFileUrls((state) => ({ ...state, [fileId]: downloadUrl }));
    });
  };

  useEffect(() => {
    if (field.type === "files" && value && value.length > 0) {
      value.forEach((file: File) => {
        getDownloadUrl(file.id, file.fullPath);
      });
    }
    // eslint-disable-next-line
  }, [value, field.type]);

  switch (field.type) {
    case "select":
      if (field.options) {
        return (
          <IonSelect
            value={value || field.default}
            onIonChange={(e) => updateData(field.name, e.detail.value)}
            interface="alert"
            multiple={field.multiple || false}
          >
            {field.options.map((option, idx) => (
              <IonSelectOption key={option.value + idx} value={option.value}>
                {option.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        );
      } else return null;
    case "checkbox":
      if (field.options) {
        return (
          <div className="checkbox-list">
            {field.options?.map((option) => (
              <IonItem key={option.value}>
                <IonLabel>{option.name}</IonLabel>
                <IonCheckbox
                  slot="start"
                  checked={value && value[option.value]}
                  onIonChange={(e) => {
                    if (e.detail.checked !== undefined) {
                      updateData(field.name, option.value, e.detail.checked);
                    }
                  }}
                />
              </IonItem>
            ))}
          </div>
        );
      } else {
        return (
          <IonCheckbox
            slot="start"
            checked={value}
            onIonChange={(e) => {
              if (e.detail.checked !== undefined) {
                updateData(field.name, e.detail.checked);
              }
            }}
          />
        );
      }
    case "rich-text":
      return (
        <RichTextField
          value={value}
          onChange={(value: string) => updateData(field.name, value)}
        />
      );
    case "date":
      return (
        <>
          <IonDatetime
            placeholder="Select Date"
            value={value}
            onIonChange={(e) =>
              updateData(field.name, e.detail.value?.substr(0, 10))
            }
            max="2024"
            min="2018"
          />
          <IonButton
            className="data-value--clear-button"
            fill="clear"
            size="small"
            onClick={() => updateData(field.name, "")}
          >
            <FormattedMessage
              id="button.cancel"
              defaultMessage="Cancel"
              description="Cancel button label"
            />
          </IonButton>
        </>
      );
    case "number":
      return (
        <IonInput
          value={value}
          onIonChange={(e) => updateData(field.name, e.detail.value)}
          name={field.name}
          type="number"
          inputmode="decimal"
          enterkeyhint="next"
        ></IonInput>
      );
    case "files":
      return (
        <>
          {value &&
            value.length > 0 &&
            value.map((file: any, idx: number) => (
              <div
                key={file.name + idx}
                className="data-value data-value--file data-value--input"
              >
                {fileUrls[file.id] ? (
                  <a
                    className="data-value--file-name"
                    href={fileUrls[file.id]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IonIcon
                      icon={documentOutline}
                      className="data-value--file-icon"
                    />
                    {file.name}
                  </a>
                ) : (
                  <div className="data-value--file-name">
                    <IonIcon
                      icon={documentOutline}
                      className="data-value--file-icon"
                    />
                    {file.name}
                  </div>
                )}
                
                <span className="data-value--file-size">
                { file.fid
                  ? <IonButton
                      size="small"
                      fill="clear"
                      color="dark"
                      onClick={() => deleteFile(field.name, value, file.fid)}
                    >
                       <IonIcon icon={trashOutline} slot="icon-only" />
                  </IonButton>
                  : 
                    <IonButton
                      size="small"
                      fill="clear"
                      color="dark"
                      onClick={() => deleteFile(field.name, value, file.id)}
                    >
                       <IonIcon icon={trashOutline} slot="icon-only" />
                  </IonButton>
                }
                  
                    {/* <IonIcon icon={trashOutline} slot="icon-only" />
                  </IonButton> */}
                </span>
              </div>
            ))}
          {(field.multiple || !value || value.length < 1) && (
            <>
              <div className="data-value--file data-value--add-button">
                <IonButton
                  fill="clear"
                  onClick={selectFile}
                  disabled={uploading}
                >
                  <IonIcon icon={arrowUpCircle} slot="start" />
                  {field.multiple ? (
                    <FormattedMessage
                      id="form.doc.pdf.add-multiple"
                      defaultMessage="Upload .pdf documents here"
                      description="Button label to add multiple files"
                    />
                  ) : (
                    <FormattedMessage
                      id="form.doc.pdf.add-one"
                      defaultMessage="Upload .pdf document here"
                      description="Button label to add only one file"
                    />
                  )}
                </IonButton>
              </div>
              <input
                type="file"
                capture="environment"
                accept={
                  field.accept ||
                  ".jpg,.gif,.jpeg,.png,image/*,.pdf,application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,application/*,.mov,.avi,.mp4,video/*"
                }
                multiple
                ref={fileInput}
                onChange={() => uploadFile(field.name, value)}
                className="resource-tile--hidden-input"
              />
            </>
          )}
        </>
      );

    default:
      return (
        <IonInput
          value={value}
          onIonChange={(e) => updateData(field.name, e.detail.value)}
          name={field.name}
          type="text"
          inputmode="text"
          enterkeyhint="next"
        ></IonInput>
      );
  }
};

export default ProjectFormField;
