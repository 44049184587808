import { RequestState } from './RequestState';
import { File } from './File';
import { IntlShape } from 'react-intl';

export type Company = {
  id: string;
  name?: string;
  cardDescription?: string;
  logo?: [File];
  logoUrl?: string;
  website: string;
  activity: string;
  description?: string;
  youTubeLink?: string;
  contactPerson?: string;
  contactEmail?: string;
  contactPhone?: string;
  interests: string[];
  remarks?: string;

  published: boolean;
  editable?: boolean;
  access: {
    [gid: string]: string;
  };
  createdOn: string;
  createdBy: string;
  updatedOn?: string;
  updatedBy?: string;
}

export type CompanyState = {
  item: Company;
  state: RequestState;
};

export type CompaniesState = {
  items: { [id: string]: Company };
  state: RequestState;
};

export type CompaniesOrderedState = {
  items: Company[];
  state: RequestState;
};

export const getCompanyActivityFields = (intl: IntlShape) => {
  return [
    {
      name: intl.formatMessage({
        id: "form.select.activity.financial",
        defaultMessage: 'Banque / Assurance',
        description: "Sponsoring Company Activity Banking and Insurance"
      }),
      value: 'financial' 
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.law",
        defaultMessage: 'Cabinet d’avocats',
        description: "Sponsoring Company Activity Law Office"
      }),
      value: 'law'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.industrial",
        defaultMessage: 'Industrie',
        description: "Sponsoring Company Activity Industrial"
      }),
      value: 'industrial'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.trade",
        defaultMessage: 'Grande distribution',
        description: "Sponsoring Company Activity Trading"
      }),
      value: 'trade'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.construction",
        defaultMessage: 'Construction',
        description: "Sponsoring Company Activity Construction"
      }),
      value: 'construction'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.computing",
        defaultMessage: 'Informatique',
        description: "Sponsoring Company Activity Computing"
      }),
      value: 'computing'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.horesca",
        defaultMessage: 'Hôtellerie et restauration',
        description: "Sponsoring Company Activity Hotels and Restaurants"
      }),
      value: 'restaurants'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.realty",
        defaultMessage: 'Immobilier',
        description: "Sponsoring Company Activity Realty"
      }),
      value: 'realty'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.communication",
        defaultMessage: 'Information et communication',
        description: "Sponsoring Company Activity Information and Communication"
      }),
      value: 'communication'
    },
    {
      name: intl.formatMessage({
        id: "form.select.activity.other",
        defaultMessage: 'Other',
        description: "Sponsoring Company Activity Other"
      }),
      value: 'other'
    },
  ];
}

export const getCompanyInterests = (intl: IntlShape) => {
  return [
    { 
      name: intl.formatMessage({
        id: "form.select.interest.art",
        defaultMessage: 'Art-culture',
        description: "Sponsoring Company Interests selection list item"
      }),
      value: 'art' 
    },
    { 
      name: intl.formatMessage({
        id: "form.select.interest.sport",
        defaultMessage: 'Sport',
        description: "Sponsoring Company Interests selection list item"
      }),
      value: 'sport' 
    },
    { 
      name: intl.formatMessage({
        id: "form.select.interest.humanitaire",
        defaultMessage: 'Humanitaire',
        description: "Sponsoring Company Interests selection list item"
      }),
      value: 'humanitarian' 
    },
    { 
      name: intl.formatMessage({
        id: "form.select.other",
        defaultMessage: 'Other',
        description: "Select input other item"
      }),
      value: 'other' 
    },
  ];
}
