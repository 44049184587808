import React, { useState, useRef, useMemo, useEffect } from 'react';
import { normString } from '../utils';
import classNames from 'classnames';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useCollection } from '../hooks/useCollection';

// components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';
import ProjectCreateModal from '../modals/project/ProjectCreateModal';
import PaginationToolbar from '../components/PaginationToolbar';
import SearchField from '../components/SearchField';
import { Redirect, useLocation } from 'react-router';
import ActionsButton from '../components/ActionsButton';
import TopNavigation from '../components/TopNavigation';
import ProjectDashboard from '../components/projects/ProjectDashboard';
import ProjectSponsoringList from '../components/projects/ProjectSponsoringList';
import ProjectsForUpapprovedSponsors from '../components/projects/ProjectsForUnapprovedSponsors';

// icons and types
import {
  Project,
  getProjectModerationStatus,
  ProjectsState,
  TProjectFieldName,
  searchDataFields
} from '../models/Project';
import { staticTaxonomies } from '../models/Taxonomies';


// styles
import './ProjectsList.scss';
import { FormattedMessage, useIntl } from 'react-intl';

// how many items to show on the page
const pageLength = 20;


const ProjectsList: React.FC = () => {
  
  const pageRef = useRef();

  const intl = useIntl();
  const projectModerationStatus = useMemo( () => getProjectModerationStatus(intl),
  // eslint-disable-next-line
  [intl.locale]);

  const { pathname } = useLocation();
  const page = pathname === '/projects/favorites' ? 'favorites' : 'all';

  // get projects
  const projects = useCollection('projects', false) as ProjectsState;

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});

  const [ modalOpened, setModalOpened ] = useState(false);
  // this opens the modal
  const openModal = () => {
    // set modal as opened
    if (permissions['projects.group.create']) {
      setModalOpened(true);
    }
  }


  // search
  const [ searchData, setSearchData ] = useState<{ [pid: string]: string } | null>(null);
  const prepareDataForSearch = () => {
    if (!searchData && projects.items) {
      setSearchData(
        Object.keys(projects.items)
          .map(projectId => {
            const project = projects.items[projectId];
            const data = searchDataFields.map(field => normString(project[field] || '')).join('|');
            return {
              id: projectId,
              data
            }
          })
          .reduce((acc, item) => ({ ...acc, [item.id]: item.data }), {})
      )
    }
  }

  const [ fieldsFilteredData, setFieldFilteredData ] = useState<{ [fields: string]: { [pid: string]: string } }>({});
  const prepareFilteredDataForSearch = (fields: TProjectFieldName[] = []) => {
    if (fields.length) {
      const searchKey = fields.join('|');
      if (!fieldsFilteredData || !fieldsFilteredData[searchKey]) {
        setFieldFilteredData(state => ({
          ...state,
          [searchKey]: Object.keys(projects.items)
            .map(projectId => {
              const project = projects.items[projectId];
              const data = fields.map(field => normString(project[field] || '')).join('|');
              return {
                id: projectId,
                data
              }
            })
            .reduce((acc, item) => ({ ...acc, [item.id]: item.data }), {})
        }));
      }
    }
  }

  const [ searchFields, setSearchFields ] = useState<string[]>([]);
  const changeSearchFields = (newSearchFields: TProjectFieldName[]) => {
    const searchKey = newSearchFields.join('|');
    if (searchKey !== '' && (!fieldsFilteredData || !fieldsFilteredData[newSearchFields.join('|')])) {
      prepareFilteredDataForSearch(newSearchFields);
    }
    setSearchFields(newSearchFields);
  }

  const [ searchValue, setSearchValue ] = useState< string[] >([]);
  const setSearchString = (searchStr: string) => {
    setSearchValue(
      searchStr
        .split(/[\s|,]/)
        .map(str => normString(str))
        .filter(str => str !== '')
    )
  }

  // category filters
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state: any) => state.ui.selectedCategory);
  const selectCategory = (category: string) => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: category
    });
  }

  // sorting
  const [ sortField, setSortField ] = useState<TProjectFieldName>('pid');
  const [ sortDirection, setSortDirection ] = useState(-1);

  const changeSortField = (newSortField: TProjectFieldName) => {
    if (newSortField === sortField) {
      setSortDirection(direction => direction * -1);
    } else {
      setSortField(newSortField);
    }
  }

  const sortByField = (a: Project, b: Project) => {
    // const f1 = (sortField === 'pid' ? parseInt(a[sortField], 10) : a[sortField]) || '';
    // const f2 = (sortField === 'pid' ? parseInt(b[sortField], 10) : b[sortField]) || '';
    let f1: any;
    let f2: any;
    switch (sortField) {
      case 'pid':
        f1 = parseInt(a[sortField], 10);
        f2 = parseInt(b[sortField], 10);
        break;
      case 'sponsoringCompletionRate':
        f1 = parseInt(a[sortField], 10) || '';
        f2 = parseInt(b[sortField], 10) || '';
        break;
      default:
        f1 = a[sortField] || '';
        f2 = b[sortField] || '';
    }
    if (f1 > f2) return sortDirection * -1;
    if (f1 < f2) return sortDirection;
    return 0;
  }


  const filterBySearchValue = (project: Project) => {
    // if the user was removed, filter it out
    if (!project) return false;
    // check for category filters
    if (selectedCategory !== 'all' && project.category !== selectedCategory ) return false;
    // if no search string — show everything
    if (!searchValue.length) return true;
    // if we are looking for specific fields only
    const dataToSearch = (searchFields && fieldsFilteredData[searchFields.join('|')])
      ? fieldsFilteredData[searchFields.join('|')]
      : searchData;
    // for each piece of the search string – look for a match
    for (let i = 0, len = (searchValue).length; i < len; i++) {
      if (dataToSearch && dataToSearch[project.pid].indexOf(searchValue[i]) >= 0) {
        return true;
      }
    }
    // return false if nothing matched
    return false;
  }


  // display items
  const resultsArr = useMemo(() => {
    if (projects.items && Object.keys(projects.items).length) {
      return Object.keys(projects.items)
        .filter(pid => projects.items[pid])
        .map(pid => projects.items[pid])
        .sort((a, b) => sortByField(a, b))
        .filter(project => filterBySearchValue(project));
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [projects.items, searchValue, selectedCategory, sortField, sortDirection, searchFields]);

  // pagination
  const [ currentPage, setCurrentPage ] = useState(0);
  const pages = useMemo(() => Math.ceil(resultsArr.length / pageLength), [resultsArr]);
  useEffect(() => setCurrentPage(0), [pages]);


  // xls export
  const exportProjects = (all: boolean) => {
    const tableHeader = ['id', 'Project Name', 'Location', 'Keywords', 'Brief Description', 'Start Date', 'End Date', // project
      'Contact Type',
      'First Name', 'Last Name', 'Email', 'Phone Number', 'Official Name', 'Registration Number', // contact
      // 'Representatives', 'Position', 'Municipality', 'Address', 'Post Code', 'City', 'Country', 'Structure Name',
      // 'Reasons', 'Why These Themes', 'Presentation', 'Innovations', 'Participation', 'Local Partners', // other
      // 'International Partners', 'Why These Partners', 'Target Audiences', 'Why These Audiences', 'Reach Audiences',
      // 'Involve Audiences', 'Realization Stages', 'Impact', 'Estimated Budget', 'Requested Budget', 'Questions'
    ]

    const tableKeys: TProjectFieldName[] = ['pid', 'title', 'location', 'keywords', 'briefDescription', 'startDate', 'endDate', // project
      'contactType',
      'firstName', 'lastName', 'email', 'phoneNumber', 'officialName', 'registrationNumber', // contact
      // 'representatives', 'position', 'municipality', 'address', 'postCode', 'city', 'country', 'structureName',
      // 'reasons', 'whyThemes', 'presentation', 'innovations', 'participation', 'localPartners', // other
      // 'internationalPartners', 'whyThesePartners', 'targetAudiences', 'whyTheseAudiences', 'reachAudiences',
      // 'involveAudiences', 'realizationStages', 'impact', 'estimatedBudget', 'requestedBudget', 'questions'
    ]

    // heading row
    const header: any[] = tableHeader.map(header => ({ value: header, type: 'string' }));

    // generate table
    const tableData: any[] = [];

    const exportedProjects = all ? Object.keys(projects.items).map(pid => projects.items[pid]) : resultsArr;

    exportedProjects.forEach(project => {
      tableData.push(tableKeys.map(key => ({ value: project[key], type: 'string' })))
    });

    (window as any).zipcelx({
      filename: `projects-${new Date().toISOString().substr(0, 10)}.xlsx`,
      sheet: {
        data: [ header, ...tableData ]
      }
    });
  }

  if (
    !permissions['projects.all.read'] &&
    !permissions['projects.approved.read'] &&
    !permissions['projects.group.read'] &&
    !permissions['projects.sponsoring.read']
  ) {
    if (permissions['companies.group.edit']) {
      // it's an unapproved sponsor
      return <ProjectsForUpapprovedSponsors />
    } else if (
      permissions['companies.all.read'] ||
      permissions['companies.group.read']
    ) {
      return <Redirect to="/account/company" />
    } else {
      return <Redirect to="/account/profile-details" />
    }
  } else return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="nav.projects"
              defaultMessage="Projects"
              description="Projects nav button"
            />
          </IonTitle>
          { permissions['projects.filters-toolbar'] &&
            <TopNavigation items={[
                {
                  id: 'all',
                  title: intl.formatMessage({
                    id: 'topNavigation.all',
                    defaultMessage: 'All',
                    description: 'Top navigation view all button',
                  }),
                  onClick: () => selectCategory('all')
                },
                ...staticTaxonomies['category'].terms.map(category => ({
                  id: category.value,
                  title: category.name,
                  onClick: () => selectCategory(category.value)
                }))
              ]}
              selected={selectedCategory}
            />
          }
          { (permissions['projects.sponsoring.read'] && !permissions['projects.all.read']) &&
            <TopNavigation items={[
                {
                  id: 'all',
                  title: intl.formatMessage({
                    id: 'topNavigation.all',
                    defaultMessage: 'All',
                    description: 'Top navigation view all button',
                  }),
                  href: '/projects',
                },
                {
                  id: 'favorites',
                  title: intl.formatMessage({
                    id: 'topNavigation.favorites',
                    defaultMessage: 'Favorites',
                    description: 'Top navigation favorites button',
                  }),
                  href: '/projects/favorites'
                }
              ]}
              selected={page}
            />
          }
        </IonToolbar>
      </IonHeader>
      <IonContent
          forceOverscroll={true}
          scrollX={true}
          scrollY={true}
          className={classNames({
            'sponsors-grid': permissions['projects.sponsoring.read'] && !permissions['projects.all.read'] && !permissions['projects.group.read']
          })}>

        { permissions['projects.group.create'] && setModalOpened &&
          <ProjectCreateModal opened={modalOpened} setOpened={setModalOpened} pageRef={pageRef.current} />
        }
        
        { !projects.state.isLoaded
          ? <LoadingScreen />
          : <>
              { !(
                  permissions['projects.sponsoring.read'] &&
                  !permissions['projects.all.read'] &&
                  !permissions['projects.group.read']
                ) &&
                <h2 className={classNames({
                  'list-title': true,
                  'list-title--wide': permissions['project.dashboard.view']
                })}>
                  <span>{ resultsArr.length }&nbsp;
                  <FormattedMessage
                    id="nav.projects"
                    defaultMessage="Projects"
                    description="Projects nav button"
                  />
                  </span>
                  <span>
                    <SearchField
                      onSearch={setSearchString}
                      onActivate={prepareDataForSearch}
                      filterFields={searchDataFields}
                      onFiltersSet={changeSearchFields}
                    />
                    { (permissions['projects.group.create'] || permissions['projects.all.export']) &&
                      <ActionsButton
                        actions={[
                          {
                            title: intl.formatMessage({
                              id: 'actionButton.create',
                              defaultMessage: 'Create New Project',
                              description: 'Projects action button: create new project',
                            }),
                            action: openModal,
                            hide: !permissions['projects.group.create']
                          },
                          {
                            title: intl.formatMessage({
                              id: 'actionButton.exportall',
                              defaultMessage: 'Export All Projects to Excel Sheet',
                              description: 'Projects action button: Export All Projects to Excel Sheet',
                            }),
                            action: () => exportProjects(true),
                            hide: !permissions['projects.all.export']
                          },
                          {
                            title: intl.formatMessage({
                              id: 'actionButton.exportfiltered',
                              defaultMessage: 'Export Filtered Projects to Excel Sheet',
                              description: 'Projects action button: Export Filtered Projects to Excel Sheet',
                            }),
                            action: () => exportProjects(false),
                            hide: !(permissions['projects.all.export'] && (searchValue.length || selectedCategory !== 'all'))
                          }
                        ]}
                      />
                    }
                  </span>
                </h2>
              }
              { projects.state.isEmpty
                ? <IonList lines="inset">
                    <IonItem onClick={openModal} button={permissions['projects.group.create']} lines="none">
                      <IonLabel>
                        <h2>
                          <FormattedMessage
                            id="projects.no-projects"
                            defaultMessage="No Projects"
                            description="No projects title"
                          />
                        </h2>
                        { permissions['projects.group.create'] &&
                          <p>
                            <FormattedMessage
                              id="projects.no-projects.action-create"
                              defaultMessage="Click here to create the first one"
                              description="No projects, create one description"
                            />
                          </p>
                        }
                      </IonLabel>
                    </IonItem>
                  </IonList>
                : permissions['project.dashboard.view']
                  ? <ProjectDashboard
                      projects={
                        resultsArr
                          .filter((project, idx) => idx >= currentPage * pageLength && idx < (currentPage + 1) * pageLength)
                      }
                      sortField={sortField}
                      sortDirection={sortDirection}
                      onSortFieldChange={changeSortField}
                    />
                  : permissions['projects.sponsoring.read'] && !permissions['projects.all.read'] && !permissions['projects.group.read']
                    ? <ProjectSponsoringList projects={resultsArr} fav={page === 'favorites'} pageRef={pageRef.current} />
                    : <IonList lines="inset">
                        { resultsArr
                            .filter((project, idx) => idx >= currentPage * pageLength && idx < (currentPage + 1) * pageLength)
                            .map((project, idx, all) => (
                              <IonItem
                                key={project.pid}
                                routerLink={`/projects/${project.pid}`}
                                lines={all.length === idx + 1 ? 'none' : 'inset'}
                              >
                                <IonLabel>
                                  <h2>
                                    { project.pid }
                                    { ' · ' }
                                    { project.title }</h2>
                                </IonLabel>
                                <p slot="end">
                                  { projectModerationStatus[project.moderation_state] }
                                </p>
                              </IonItem>
                            ))
                        }
                      </IonList>
              }
            </>
        }
      </IonContent>

      { pages > 1 &&
        <PaginationToolbar
          currentPage={currentPage}
          pagesNumber={pages}
          onFirst={() => setCurrentPage(0)}
          onPrevious={() => setCurrentPage(page => page - 1)}
          onNext={() => setCurrentPage(page => page + 1)}
          onLast={() => setCurrentPage(pages - 1)}
        />
      }
    </IonPage>
  );
};

export default ProjectsList;
