import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useFirestoreCollectionQuery } from '../../hooks/useFirestoreCollectionQuery';
import { useOrderedCollection } from '../../hooks/useOrderedCollection';

// components
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import TopNavigation from '../TopNavigation';

// types and constants
import { staticTaxonomies } from '../../models/Taxonomies';
import { CommentsOrderedState } from '../../models/Comment';
import { FormattedMessage, useIntl } from 'react-intl';
import { Project } from '../../models/Project';

type TProjectHeaderProps = {
  pid: string;
  page: string;
}

const ProjectHeader = ({ pid, page }: TProjectHeaderProps) => {

  const project = useSelector((state: any) => state.firestore.data?.projects && state.firestore.data.projects[pid]) as Project;
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});

  // load comments for the current project
  useFirestoreCollectionQuery(`projects/${pid}/comments`, false, { storeAs: `comments-${pid}` });
  const lastCommentsTime = useSelector((state: any) => state.firebase?.profile?.comments?.[pid]);
  const comments = useOrderedCollection(`comments-${pid}`) as CommentsOrderedState;
  const [ unreadComments, setUnreadComments ] = useState(0);
  useEffect(() => {
    if (comments.items) {
      if (lastCommentsTime) {
        const lastTime = new Date(lastCommentsTime);
        setUnreadComments(comments.items.filter(comment => new Date(comment.date) > lastTime).length);
      } else {
        setUnreadComments(comments.items.length);
      }
    }
  }, [comments.items, lastCommentsTime]);

  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCategory = useSelector((state: any) => state.ui.selectedCategory);
  const gotoCategory = (category: string) => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: category
    });
    history.push('/projects');
  }
  const intl = useIntl();
  return (
    <IonHeader>
      { permissions['projects.filters-toolbar'] &&
        <IonToolbar>
          <IonTitle>
            <FormattedMessage
              id="nav.projects"
              defaultMessage="Projects"
              description="Projects nav button"
            />
          </IonTitle>
          <TopNavigation items={[
              {
                id: 'all',
                title: intl.formatMessage({
                  id: 'topNavigation.all',
                  defaultMessage: 'All',
                  description: 'Top navigation view all button',
                }),
                onClick: () => gotoCategory('all')
              },
              ...staticTaxonomies['category'].terms.map(category => ({
                id: category.value,
                title: category.name,
                onClick: () => gotoCategory(category.value)
              }))
            ]}
            selected={selectedCategory}
          />
        </IonToolbar>
      }
      <IonToolbar className={classNames({
        'secondary-toolbar': permissions['projects.filters-toolbar']
      })}>
        { !permissions['projects.filters-toolbar'] &&
          <IonButtons slot="start">
            <IonBackButton defaultHref="/projects" />
          </IonButtons>
        }
        <IonTitle>{ pid }{ ' • ' }{ project?.title }</IonTitle>
        <TopNavigation items={[
            {
              id: 'view',
              title: intl.formatMessage({
                id: 'topNavigation.view',
                defaultMessage: 'View',
                description: 'Top navigation project view button',
              }),
              href: `/projects/${pid}`
            },
            {
              id: 'edit',
              title: intl.formatMessage({
                id: 'topNavigation.edit',
                defaultMessage: 'Edit',
                description: 'Top navigation project edit button',
              }),
              href: `/projects/${pid}/edit`,
              disabled:
                (
                  !permissions['projects.all.edit'] &&
                  permissions['projects.group.edit'] &&
                  !(
                    project?.moderation_state === 'draft' ||
                    project?.moderation_state === null ||
                    project?.moderation_state === undefined ||
                    project?.moderation_state === ''
                  )
                ),
              hidden: !permissions['projects.all.edit'] && !permissions['projects.group.edit']
            },
            {
              id: 'comments',
              title: intl.formatMessage({
                id: 'topNavigation.comments',
                defaultMessage: 'Comments',
                description: 'Top navigation project comments button',
              }),
              href: `/projects/${pid}/comments`,
              badge: unreadComments || undefined,
              hidden: !permissions['projects.all.edit'] && !permissions['projects.group.edit']
            },
            {
              id: 'sponsoring',
              title: intl.formatMessage({
                id: 'topNavigation.sponsoring',
                defaultMessage: 'Partnership',
                description: 'Top navigation project sponsoring button',
              }),
              href: `/projects/${pid}/partnership`,
              hidden: project?.category !== 'cat1' || !(permissions['projects.all.edit'] || permissions['projects.group.edit'])
            },
            {
              id: 'documents',
              title: intl.formatMessage({
                id: 'topNavigation.documents',
                defaultMessage: 'Official Documents',
                description: 'Top navigation Documents button',
              }),
              href: `/projects/${pid}/documents`,
              hidden: !permissions['projects.all.edit'] && !permissions['projects.group.edit']
            },
            {
              id: 'revisions',
              title: intl.formatMessage({
                id: 'topNavigation.revisions',
                defaultMessage: 'Revisions',
                description: 'Top navigation Revisions button',
              }),
              href: `/projects/${pid}/revisions`,
              hidden: !permissions['revisions.all.read']
            }
          ]}
          selected={page}
        />
      </IonToolbar>
    </IonHeader>
  )
}

export default ProjectHeader;
