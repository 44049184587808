import React from 'react';

import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import TopNavigation from './TopNavigation';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';


type ResourcesHeaderProps = {
  category: string;
}

const ResourcesHeader = ({ category }: ResourcesHeaderProps) => {

  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || []);

  const intl = useIntl();

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/resources" />
        </IonButtons>
        <IonTitle>Resources</IonTitle>
        <TopNavigation items={[
            {
              id: 'general',
              title: intl.formatMessage({
                id: 'navigation.resources.general',
                defaultMessage: 'General',
                description: 'Button for filtering resources',
              }),
              href: '/resources/general',
              hidden: !permissions['resources.general.read']
            },
            // {
            //   id: 'branding',
            //   title: intl.formatMessage({
            //     id: 'navigation.resources.branding',
            //     defaultMessage: 'Branding',
            //     description: 'Button for filtering resources',
            //   }),
            //   href: '/resources/branding',
            //   hidden: !permissions['resources.branding.read']
            // },
            {
              id: 'coaching',
              title: intl.formatMessage({
                id: 'navigation.resources.coaching',
                defaultMessage: 'Coaching',
                description: 'Button for filtering resources',
              }),
              href: '/resources/coaching',
              hidden: !permissions['resources.coaching.read']
            }
          ]}
          selected={category}
        />
      </IonToolbar>
    </IonHeader>
  )
}

export default ResourcesHeader;
