import React, { ReactElement, useEffect, useMemo, useState } from 'react';

// more hooks
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

// components
import { IntlProvider } from 'react-intl';
import LoadingScreen from './LoadingScreen';


export const locales = [
  { id: 'en', title: 'English' },
  { id: 'fr', title: 'Français' },
  // { id: 'de', title: 'Deutsche' }
];

type TIntlProps = {
  children: ReactElement;
}

const Intl = ({ children }: TIntlProps) => {
  const firebase = useFirebase();

  const profile = useSelector((state: any) => state.firebase?.profile);
  const uiLocale = useSelector((state: any) => state.ui?.locale);

  const browserLanguage = useMemo(() => {
    const langArr = navigator.languages || [navigator.language];
    for (let i = 0, len = langArr.length; i < len; i++) {
      if (locales.find(locale => locale.id === langArr[i])) {
        return langArr[i];
      }
    }
    return locales[0].id;
    // eslint-disable-next-line
  }, [navigator.language, navigator.languages]);

  const [ locale, setLocale ] = useState(profile?.lang || browserLanguage);
  useEffect(() => {
    // set current language
    const newLocale = profile.lang || uiLocale;
    if (newLocale && newLocale !== locale) {
      setLocale(newLocale);
    }
    // set locale in the profile
    if (uiLocale && profile.isLoaded && !profile.isEmpty && profile.lang !== uiLocale) {
      firebase.updateProfile({ lang: uiLocale });
    }
    // eslint-disable-next-line
  }, [profile, uiLocale]);

  const [ messages, setMessages ] = useState();
  useEffect(() => {
    if (locale) {
      import(`../../compiled-lang/${locale}.json`)
        .then(res => {
          setMessages(res);
        });
    }
  }, [locale]);

  return messages !== undefined
    ? <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        { children }
      </IntlProvider>
    : <LoadingScreen />
}

export default Intl;
