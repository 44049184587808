import React, { useState } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

// components
import {
  IonPage,
  IonLabel,
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  IonFooter,
  IonToolbar
} from '@ionic/react';
import AccountHeader from '../components/AccountHeader';

// types
import { User } from '../models/User';
import { FormattedMessage } from 'react-intl';
// import ProjectDataValue from '../components/projects/ProjectDataValue';



const ProfileDetails = () => {

  //get current user profile
  const userProfile = useSelector((state: any) => state.firebase.profile);

  // get user's permissions
  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  // get user's role
  // const role = useSelector((state: any) => state.firestore.data?.role);

  //update user data
  const [ formData, setFormData ] = useState<User>({
    //...userProfile
    uid: userProfile.uid,
    email: userProfile.email,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    phone: userProfile.phone,
    jobPosition: userProfile.jobPosition,
    role: userProfile.role,
    groupId: userProfile.groupId,
    gdpr: userProfile.gdpr
  });

  // save toolbar visibility
  const [ toolbarVisible, setToolbarVisible ] = useState(false);
  // updateFieldValue
  const updateField = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      if (!toolbarVisible) setToolbarVisible(true);
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // send data to firestore
  const firestore = useFirestore();
  const updateUserData = () => {
    if ( formData ) {
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key];
        }
      });
    }

    firestore
      .collection('users')
      .doc(formData.uid)
      .update(formData)
      .then(() => {
        setToolbarVisible(false);
        showMessage( "The user profile was updated!", "success");
      })
      .catch(function(error) {
        showMessage( error.message, "danger");
      });
  }

  //show success or fail messages
  const [ toast, setToast ] = useState({ message: '', color: "success", isOpen: false});
  const showMessage = ( message: string, color: string) => {
    setToast( state => ({ ...state, header: 'Profile Details', message, color, isOpen: true} ));
  }
    
  return(
    <IonPage>
      <AccountHeader page="profile-details" />
      <IonContent>
        <IonToast
          {...toast}
          onDidDismiss={ () => setToast(state => ({...state, isOpen: false }))}
          position='bottom'
          cssClass='bottom-toasts'
          animated={true}
          buttons={[{
              text: 'x',
              role: 'cancel',
              handler: () => {}
          }]}
        />

        <IonList>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.first-name"
                defaultMessage= "First Name"
                description="First Name field on all form"
              />
            </IonLabel>
            <IonInput 
              name="firstName"
              type="text"
              value={ formData.firstName }
              onIonChange={ (e) => updateField('firstName', e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.last-name"
                defaultMessage= "Last Name"
                description="Last Name field on all form"
              />
            </IonLabel>
            <IonInput 
              name="lastName"
              type="text"
              value={ formData.lastName }
              onIonChange={ (e) => updateField('lastName', e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.email"
                defaultMessage= "Email address"
                description="Email field on all form"
              />
            </IonLabel>
            <IonInput name="email" type="email" readonly value={ userProfile.email }></IonInput>
          </IonItem>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
            <FormattedMessage
                id= "form.phone"
                defaultMessage= 'Phone number'
                description= "Phone number field on all form"
              />
            </IonLabel>
            <IonInput 
              name="phone"
              type="tel"
              value={formData.phone}
              onIonChange={ (e) => updateField('phone', e.detail.value)}
            ></IonInput>
          </IonItem>
          { permissions['settings.profile.jobposition'] &&
            <IonItem className="project--section-item">
              <IonLabel position="stacked">
                <FormattedMessage
                  id= "form.job"
                  defaultMessage= "Job Position"
                  description="Job Position field on all form"
                />
              </IonLabel>
              <IonInput 
                name="jobPosition"
                type="text"
                value={formData.jobPosition}
                onIonChange={ (e) => updateField('jobPosition', e.detail.value)}
              ></IonInput>
            </IonItem>
          }

          {/* <IonItem className="project--section-item">
            <IonItem className="project--section-item">
            <IonLabel position="stacked">
                <FormattedMessage
                  id= "form.role"
                  defaultMessage= "Role"
                  description="User role field on all form"
                />
              </IonLabel>
            <ProjectDataValue
              field={{ name: 'Role', type: 'text' }}
              value={role.name}
            />
          </IonItem>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.user-id"
                defaultMessage= "User ID"
                description="User id field on all form"
              />
            </IonLabel>
            <ProjectDataValue
              field={{ name: 'UID', type: 'text' }}
              value={userProfile.uid}
            />
          </IonItem> */}

        </IonList>
      </IonContent>
      { toolbarVisible &&
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <section className="bottom-controls">
              <div>
                <IonButton className="blue-style min-widthed" onClick={updateUserData}>
                  <FormattedMessage
                    id= "button.save-changes"
                  />
                </IonButton>
              </div>
            </section>
          </IonToolbar>
        </IonFooter>
      }
    </IonPage>
  )
}

export default ProfileDetails;
