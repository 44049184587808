import React from 'react';

// hooks
import { useParams } from 'react-router';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

// components
import { IonContent, IonPage } from '@ionic/react';
import ProjectHeader from '../components/projects/ProjectHeader';
import LoadingScreen from '../components/LoadingScreen';
import ResourcesList from '../components/ResourcesList';

// types
import { ProjectState } from '../models/Project';


const ProjectDocuments = () => {
  const { pid } = useParams<{ pid: string }>();

  // project
  const project = useCollectionItem('projects', pid) as ProjectState;

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || { isNotLoaded: true });

  const firestore = useFirestore();
  const updateDocument = (updatedFiles: { [fileId: string]: any }) => {
    // update firestore data
    firestore
      .collection('projects')
      .doc(pid)
      .update({
        officialDocuments: Object.keys(updatedFiles).map(key => updatedFiles[key]),
        date: new Date().toISOString()
      })
      .catch(err => {
        console.error('Resource document update error:', pid, err);
      });
  }

  return (
    <IonPage>
      <ProjectHeader pid={pid} page="documents"/>
      <IonContent>
        { project.state.isLoading
          ? <LoadingScreen />
          : <ResourcesList
              collection="projects"
              doc={pid}
              files={project.item.officialDocuments?.reduce((acc, file) => {
                const id = file.id || 'id' + file.fid;
                return { ...acc, [file.id || 'id' + file.fid]: { ...file, id }}
              }, {}) || {}}
              permissions={{
                create: permissions['projects.all.edit'],
                delete: permissions['projects.all.edit']
              }}
              onChange={updateDocument}
            />
        }
      </IonContent>
    </IonPage>
  )
}

export default ProjectDocuments;
