import React, { useEffect, useMemo, useRef, useState } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useFirestoreItemQuery } from '../hooks/useFirestoreItemQuery';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';

// components
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  // IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import AccountHeader from '../components/AccountHeader';
import LoadingScreen from '../components/LoadingScreen';
import ProjectFormField from '../components/projects/ProjectFormField';
import ProjectDataValue from '../components/projects/ProjectDataValue';
// import UserModal from '../modals/UserModal';
import SponsorModal from '../modals/SponsorModal';
import { useIntl, FormattedMessage } from 'react-intl';
import CardGrid from '../components/CardGrid';
import CardGridItem from '../components/CardGridItem';

// types
import { getCompanyActivityFields, getCompanyInterests, CompanyState } from '../models/Company';
import { ProjectField } from '../models/Project';
import { UsersState } from '../models/User';
// import { RolesState } from '../models/Role';


const CompanyProfile = () => {
  const pageRef = useRef();

  const firebase = useFirebase();
  const firestore = useFirestore();
  const { companyId } = useParams<{ companyId: string }>();

  // get the user's id
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const profile = useSelector((state: any) => state.firebase.profile);
  const groupId = companyId || profile.groupId;

  // get user's permissions
  const permissions = useSelector((state: any) => state.firestore.data?.role?.permissions || {});

  // get company info
  const company = useFirestoreItemQuery('companies', groupId) as CompanyState;

  // get company users
  const users = useFirestoreCollectionQuery('users', false, {
    where: permissions['settings.users.read']
      ? ['groupId', '==', groupId]
      : ['none', '==', 'none'],
    storeAs: `users-${groupId}`
  }) as UsersState;

  // roles
  // const roles = useFirestoreCollectionQuery('roles', false, { storeAs: 'allRoles' }) as RolesState;
    // { state: { isLoading: true, isLoaded: false }, items: {} } as RolesState;

  // const [ userOpened, setUserOpened ] = useState(false);
  // const [ selectedUser, setSelectedUser ] = useState();

  // this opens the modal
  // const openUserModal = (user: any) => {
  //   // setting which user we want to edit
  //   setSelectedUser(user);
  //   // set modal as opened
  //   setUserOpened(true);
  // }

  const intl = useIntl();
  const companyInterests = useMemo( () => getCompanyInterests(intl),
  // eslint-disable-next-line
  [intl.locale]);
  const companyActivityFields = useMemo( () => getCompanyActivityFields(intl),
  // eslint-disable-next-line
  [intl.locale]);
  // fields used in the form
  const companyFormFields: ProjectField[] = useMemo(() => [
    {
      title: intl.formatMessage({
        id: "form.company-name",
        defaultMessage: 'Company Name (required)',
        description: "Company name field on all form"
      }),
      type: 'text',
      name: 'name'
    },
    {
      title: intl.formatMessage({
        id: "form.card-description",
        defaultMessage: 'Card Description (required)',
        description: "Card description field on all form"
      }),
      type: 'text',
      name: 'cardDescription'
    },
    {
      title: intl.formatMessage({
        id: "form.logo",
        defaultMessage: 'Logo',
        description: "File upload field label"
      }),
      type: 'files',
      name: 'logo',
      multiple: false,
      accept: '.jpg,.gif,.jpeg,.png,image/*',
      path: `companies/${groupId}`
    },
    {
      title: intl.formatMessage({
        id: "form.website",
        defaultMessage: 'Website',
        description: "Input field label"
      }),
      type: 'text',
      name: 'website'
    },
    {
      title: intl.formatMessage({
        id: "form.activity-field",
        defaultMessage: 'Field of Activity',
        description: "Input field label"
      }),
      name: 'activity',
      type: 'select',
      multiple: false,
      options: companyActivityFields,
    },
    {
      title: intl.formatMessage({
        id: "form.description",
        defaultMessage: 'Description',
        description: "Input field label"
      }),
      type: 'rich-text',
      name: 'description'
    },
    {
      title: intl.formatMessage({
        id: "form.youtube",
        defaultMessage: 'Video (YouTube link)',
        description: "Input field label"
      }),
      type: 'text',
      name: 'youTubeLink'
    },
    {
      title: intl.formatMessage({
        id: "form.contact-person",
        defaultMessage: 'Contact Person',
        description: "Input field label"
      }),
      type: 'text',
      name: 'contactPerson'
    },
    {
      title: intl.formatMessage({
        id: "form.contact-person.email",
        defaultMessage: 'Contact Person: E-mail address',
        description: "Contact Person: Email field on all form"
      }),
      type: 'email',
      name: 'contactEmail'
    },
    {
      title: intl.formatMessage({
        id: "form.contact-person.phone",
        defaultMessage: 'Contact Person: Phone number',
        description: "Contact Person: Phone number field on all form"
      }),
      type: 'tel',
      name: 'contactPhone'
    },
    {
      title: intl.formatMessage({
        id: "form.interested-in",
        defaultMessage: 'Interested In',
        description: "Input field label"
      }),
      name: 'interests',
      type: 'select',
      multiple: true,
      options: companyInterests,
    },
    {
      title: intl.formatMessage({
        id: "form.remarks",
        defaultMessage: 'Remarks',
        description: "Input field label"
      }),
      type: 'rich-text',
      name: 'remarks'
    },
    {
      title: intl.formatMessage({
        id: "checkbox.sponsor-published",
        defaultMessage: 'Published in the Sponsors List',
        description: "Checkbox label"
      }),
      type: 'checkbox',
      name: 'published',
      visible: permissions['companies.all.edit'] || false
    }
    // eslint-disable-next-line
  ], [groupId, permissions, intl.locale]);


  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<{ [prop: string]: any }>(company.item);
  const [ fileUrls, setFileUrls ] = useState<{ [fileId: string]: string }>({});

  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setFileUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }

  useEffect(() => {
    if (
      company.state.isLoaded &&
      (!company.state.isEmpty || company.state.isNotFound)
    ) {
      setFormData({
        name: profile.company,
        contactPerson: `${profile.firstName} ${profile.lastName}`,
        contactEmail: profile.email,
        contactPhone: profile.phone,

        ...(company.item || {})
      });

      if (company.item?.logo && company.item.logo.length) {
        // get download url for the logo
        company.item.logo.forEach(logo => getDownloadUrl(logo.id, logo.fullPath));
      }
    }
    // eslint-disable-next-line
  }, [company.item]);

  // save toolbar visibility
  const [ toolbarVisible, setToolbarVisible ] = useState(false);

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any, checked?: boolean) => {
    if (checked !== undefined) {
      setToolbarVisible(true);
      const updatedData = { ...formData, [property]: { ...formData[property], [value]: checked } };
      setFormData(updatedData);
    } else if (!formData) {
      setFormData(data => ({ ...data, [property]: value }));
    } else if ((formData as any)[property] === undefined && value === '<p></p>') {
    } else if ((formData as any)[property] !== value) {
      setToolbarVisible(true);
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // function that is saving the data
  const updateItem = () => {
    // clearing undefined values — Firestore don't like theese
    Object.keys(formData).forEach(key => {
      if ((formData as any)[key] === undefined) {
        delete (formData as any)[key]
      }
    });

    // checking the permission first
    if (company.state.isNotFound && permissions['companies.group.create']) {
      // sending to the firestore
      firestore
        .collection('companies')
        .doc(groupId)
        .set({
          ...formData,
          id: groupId,
          editable: true,
          access: {
            [groupId]: 'author'
          },
          createdOn: new Date().toISOString(),
          createdBy: uid
        })
        .then(() => {
          setToolbarVisible(false);
        });
    } else if (permissions['companies.group.edit'] || permissions['companies.all.edit']) {
      // sending to the firestore
      firestore
        .collection('companies')
        .doc(groupId)
        .update({
          ...formData,
          id: groupId,
          updatedOn: new Date().toISOString(),
          updatedBy: uid
        })
        .then(() => {
          setToolbarVisible(false);

          // promoting user roles
          if (formData.published && users.items) {
            Object.keys(users.items)
              .forEach((userId: string) => {
                if (
                  users.items[userId] &&
                  users.items[userId].role === 'unapproved-sponsor'
                ) {
                  firestore
                    .collection('users')
                    .doc(userId)
                    .update({
                      role: 'sponsor',
                      updatedOn: new Date().toISOString(),
                      updatedBy: uid
                    });
                }
              });
          }
        });
    }
  }

  // const previewCard = () => {
  //   console.log('Preview Card');
  // }

  const [ popupOpened, setPopupOpened ] = useState(false);
  const previewPopup = () => {
    setPopupOpened(true);
  }

  return (
    <IonPage ref={pageRef}>
      { companyId
        ? <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/settings/companies" />
              </IonButtons>
              <IonTitle>
                <FormattedMessage
                  id="modal.title.edit"
                  defaultMessage="Edit {data}"
                  description="Edit a role"
                  values={{
                    data: company.item?.name || 'Untitled Company'
                  }}
                />
              </IonTitle>
            </IonToolbar>
          </IonHeader>
        : <AccountHeader page="company" />
      }

      { company.state.isLoading
        ? <LoadingScreen />
        : (company.state.isNotFound && permissions['companies.group.create']) ||
          (!company.state.isEmpty && permissions['companies.group.edit']) ||
          (!company.state.isEmpty && permissions['companies.all.edit'])
          ? <>
              {/* selectedUser &&
                <UserModal opened={userOpened} setOpened={setUserOpened} pageRef={pageRef.current} data={selectedUser} />
              */}
              { popupOpened &&
                <SponsorModal
                  opened={popupOpened}
                  setOpened={setPopupOpened}
                  pageRef={pageRef.current}
                  sponsor={company.item}
                  visualsUrl={company.item?.logo ? fileUrls[company.item?.logo[0]?.id] : undefined}
                  hideFavorites={true}
                />
              }
              <IonContent>
                <IonList>
                  { companyFormFields
                      .filter(field => field.visible === undefined || field.visible === true)
                      .map(field => (
                        <IonItem key={field.name} className="project--section-item">
                          <IonLabel
                            className={field.type !== 'checkbox' ? '' : 'closer-ion-label'}
                            position={field.type !== 'checkbox' ? 'stacked' : undefined}
                          >
                            { field.title }
                          </IonLabel>
                          <ProjectFormField
                            field={field}
                            value={formData? formData[field.name] : ''}
                            updateData={updateData}
                          />
                        </IonItem>
                      ))
                  }
                </IonList>
                {/* { permissions['settings.users.edit'] &&
                  <>
                    <h2 className="list-title">
                      <span>
                        <FormattedMessage
                          id= "company-users.title"
                          defaultMessage= "Company Users"
                          description="Section title"
                        />
                      </span>
                    </h2>
                    <IonList>
                      { users.state.isNotLoaded || users.state.isLoading
                        ? <IonItem>
                            <IonLabel style={{ textAlign: 'center' }}><IonSpinner /></IonLabel>
                          </IonItem>
                        : users.state.isEmpty || !users.items
                        ? <IonItem>
                            <IonLabel>Strange, but no users found.</IonLabel>
                          </IonItem>
                        : Object.keys(users.items)
                            .map(uid => users.items[uid])
                            .map((user, idx, all) => (
                              <IonItem
                                key={idx}
                                onClick={() => openUserModal(user)}
                                button
                                detail={false}
                                lines={all.length === idx + 1 ? 'none' : 'inset'}
                              >
                                <IonLabel>
                                  <h2>{ user.firstName } { user.lastName }</h2>
                                  <p>{ user.email }</p>
                                </IonLabel>
                                <p slot="end">
                                  { roles.state.isLoaded ? roles.items[user.role]?.name : user.role }
                                </p>
                              </IonItem>
                            ))
                      }
                    </IonList>
                  </>
                } */}
                { company.item &&
                  <CardGrid title={
                    intl.formatMessage({
                      id: 'sponsors.card-preview',
                      defaultMessage: 'Preview',
                      description: 'Title for the preview section in Partners and Projects partnership'
                    })
                  }>
                    <CardGridItem
                      className="company-card"
                      id={groupId}
                      visualsUrl={company.item?.logo ? fileUrls[company.item?.logo[0]?.id] : undefined}
                      title={formData && formData['name'] !== '' ? formData['name'] : 'Untitled Company'}
                      description={formData ? formData['cardDescription'] : ''}
                      onReadMore={() => previewPopup()}
                    />
                  </CardGrid>
                }
              </IonContent>
              { toolbarVisible &&
                <IonFooter className="ion-no-border">
                  <IonToolbar>
                    <section className="bottom-controls">
                      <IonButton className="blue-style min-widthed" onClick={() => updateItem()}>
                        <FormattedMessage
                          id="button.save-changes"
                          defaultMessage="Save Changes"
                          description="Save changes button label"
                        />
                      </IonButton>
                    </section>
                  </IonToolbar>
                </IonFooter>
              }
            </>
          : <IonContent>
              <IonList>
                { companyFormFields.map(field => (
                    <IonItem key={field.name} className="project--section-item">
                      <IonLabel position="stacked">{ field.title }</IonLabel>
                      <ProjectDataValue field={field} value={formData? formData[field.name] : ''} fileUrls={{}} />
                    </IonItem>
                  ))
                }
              </IonList>
            </IonContent>
      }
    </IonPage>
  )
}

export default CompanyProfile;
