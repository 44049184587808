import React, { useState, useEffect } from 'react';

//models
import { Role, RoleState, newRole } from '../models/Role';
import { PermissionsOrderedState } from '../models/Permission';

//Components
import ModalForm from '../components/ModalForm';
import {IonList, IonItem, IonLabel, IonInput, IonCheckbox, IonItemDivider, IonButton} from '@ionic/react';
import LoadingScreen from '../components/LoadingScreen';

//hooks
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirestoreCollectionQuery } from '../hooks/useFirestoreCollectionQuery';
import { useFirestore } from 'react-redux-firebase';
import { FormattedMessage, useIntl } from 'react-intl';

type RoleModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  roleId?: string;
  setRoleId?: Function;
}

const RoleModal = ( props: RoleModalProps ) => {

  const role = useCollectionItem('allRoles', props.roleId) as RoleState;
  const [ roleId, setRoleId ] = useState<string>(props.roleId || '');

  const permissions = useFirestoreCollectionQuery('permissions', true, { orderBy: 'orderIndex' }) as PermissionsOrderedState;

  // updating form data when the item is loaded
  useEffect(() => {
    if (roleId !== props.roleId && role.item?.id !== formData.id) {
      setRoleId(props.roleId || '');
      resetForm();
    }
    // eslint-disable-next-line 
  }, [role])

  // updates the roleData by property key
  const updateData = (property: string, value: any) => {
    if ((formData as any)[property] !== value) {
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // updates permission value
  const updatePermission = (permissionId: string, checked: boolean) => {
    setFormData(data => ({
      ...data,
      permissions: {
        ...data.permissions,
        [permissionId]: checked
      }
    }));
  }

  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<Role>({
    ...newRole,
    ...role.item
  });
  // reset roles data
  const resetForm = () => {
    if (props.roleId !== '') {
      setFormData({ ...role.item });
    } else {
      setFormData({ ...newRole });
    }
  }
  
  const firestore = useFirestore();
  const createItem = () => {

    if ( formData.permissions ) {
      Object.keys(formData.permissions).forEach(key => {
        if ((formData.permissions as any)[key] === undefined) {
          delete (formData.permissions as any)[key];
        }
      });
    }

    firestore
      .collection('roles')
      .doc(formData.id)
      .set(formData)
      .then(() => {
        props.setOpened(false);
        console.log( formData.id, "role was updated!");
      });
  }

  const intl = useIntl();

  return (
    <ModalForm {...props}
      title={ 
        props.roleId
        ? intl.formatMessage({
          id: 'modal.title.edit',
          defaultMessage: 'Edit {data}',
          description: 'Edit a role',
        },{
          data: formData.name
        })
        : intl.formatMessage({
          id: 'role.modal.title.create',
          defaultMessage: 'Create a New Role',
          description: 'Create a New Role modal title',
        })
      }
    >
      <IonList className="no-bottom-controls">
        <IonItem className="project--section-item">
          <IonLabel position="stacked">
            <FormattedMessage
              id="role.modal.id"
              defaultMessage="Role Id (required)"
              description="Role field label"
            />
          </IonLabel>
          <IonInput
            value={formData.id}
            onIonChange={e => updateData('id', e.detail.value)}
            name="id"
            type="text"
            readonly={!!props.roleId}
          ></IonInput>
        </IonItem>
        <IonItem className="project--section-item">
          <IonLabel position="stacked">
          <FormattedMessage
              id="role.modal.name"
              defaultMessage="Role Name"
              description="Role field label"
            />
          </IonLabel>
          <IonInput
            value={ formData.name }
            name="name"
            type="text"
            onIonChange={ e => updateData('name', e.detail.value)}
          ></IonInput>
        </IonItem>
        <IonItem className="project--section-item">
          <IonLabel>
            <FormattedMessage
              id="checkbox.show-role"
              defaultMessage="Show this role in the main filters list"
              description="Role field label"
            />
          </IonLabel>
          <IonCheckbox
            slot="start"
            checked={formData.showInTheFilters}
            name="showInTheFilters"
            onIonChange={ e => updateData('showInTheFilters', e.detail.checked)}
          />
        </IonItem>

        { permissions.state.isLoaded
          ? <>
              <IonItemDivider>
                <FormattedMessage
                  id="nav.permissions"
                  defaultMessage="Permissions"
                  description="Settings nav button"
                />
              </IonItemDivider>
              { permissions.items
                  .filter(permission => permission)
                  .map(permission => (
                    <IonItem key={permission.id}>
                      <IonCheckbox
                        slot="start"
                        checked={formData.permissions && formData.permissions[permission.id]}
                        onIonChange={(e) => updatePermission(permission.id, e.detail.checked)}
                      />
                      <IonLabel>
                        <h2>{ permission.name }</h2>
                        <p>{permission.id}</p>
                      </IonLabel>
                    </IonItem>
                  ))
              }
            </>
          : <LoadingScreen />
        }
      </IonList>
      <div className="bottom-controls">
        <IonButton
          expand="block"
          onClick={createItem}
          className="blue-style"
        >{
          props.roleId
          ? <FormattedMessage
              id= "button.update"
              defaultMessage= "Update"
              description="Update button label"
            />
          : <FormattedMessage
              id= "role.button.create"
              defaultMessage= 'Create New Role'
              description="create role button label"
            />
        }
        </IonButton>
      </div>
    </ModalForm>
  )
}

export default RoleModal;
