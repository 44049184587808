import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { IonAlert, IonButton, IonIcon, IonInput } from '@ionic/react';
import { searchOutline, close, funnelOutline } from 'ionicons/icons';

import { projectFieldsIntlIds } from '../models/Project';

import './SearchField.scss';

type TSearchFieldProps = {
  onSearch: Function;
  onActivate?: Function;
  filterFields?: string[];
  onFiltersSet?: Function;
}

const SearchField = ({ onSearch, onActivate, filterFields, onFiltersSet }: TSearchFieldProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const [ isOpen, setIsOpen ] = useState(false);

  const toggleField = () => {
    if (!isOpen && inputRef.current) {
      inputRef.current.setFocus();
      if (inputRef.current.value !== '') {
        onSearch(inputRef.current.value);
      }
      if (onActivate) {
        onActivate();
      }
    } else {
      onSearch('');
    }
    setIsOpen(state => !state);
  }

  const onSearchInput = (searchStr: string) => {
    if (isOpen) {
      onSearch(searchStr);
    }
  }

  // filters
  const [ filtersOpen, setFiltersOpen ] = useState(false);
  const [ activeFilters, setActiveFilters ] = useState<{ [name: string]: boolean }>({});
  const showFilters = () => {
    setFiltersOpen(true);
  }

  const setFilters = (filters: string[]) => {
    if (onFiltersSet) {
      onFiltersSet(filters);
    }
    const newFilters: { [name: string]: boolean } = {};
    filters.forEach(filter => {
      newFilters[filter] = true;
    })
    setActiveFilters(newFilters);    
  }

  const clearFilters = () => {
    if (onFiltersSet) {
      onFiltersSet([]);
    }
    setActiveFilters({});
  }

  return (
    <div className={classNames({
      'search-field': true,
      'search-field--open': isOpen
    })}>
      <IonInput
        type="search"
        className="search-field--input"
        ref={inputRef}
        onIonChange={(e) => onSearchInput(e.detail.value!)}
        onIonFocus={() => dispatch({ type: 'HIDE_TABS' })}
        onIonBlur={() => dispatch({ type: 'SHOW_TABS' })}
      />
      { filterFields &&
        <>
          <IonAlert
            cssClass="select-alert"
            isOpen={filtersOpen}
            onDidDismiss={() => setFiltersOpen(false)}
            header={'Search only in selected fields'}
            inputs={
              filterFields.map(field => ({
                label: projectFieldsIntlIds[field] ? intl.formatMessage({ id: projectFieldsIntlIds[field] }) : field,
                name: field,
                value: field,
                type: 'checkbox',
                checked: activeFilters[field]
              }))
            }
            buttons={[
              {
                text: 'Clear',
                handler: clearFilters
              },
              {
                text: 'Ok',
                handler: setFilters
              }
            ]}
          />
          <IonButton
            fill="clear"
            className="search-field--filter"
            onClick={() => showFilters()}
          >
            <IonIcon icon={funnelOutline} slot="icon-only" color={ Object.keys(activeFilters).length ? 'primary' : 'dark'} />
          </IonButton>
        </>
      }

      <IonButton
        shape="round"
        fill="clear"
        className="search-field--button"
        onClick={() => toggleField()}
      >
        <IonIcon icon={isOpen ? close : searchOutline} slot="icon-only" color="dark" />
      </IonButton>
    </div>
  )
}

export default SearchField;
