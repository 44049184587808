import React, { useRef, useEffect, useState, useMemo } from 'react';

// hooks
import { Redirect, useParams } from 'react-router';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

// components
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonFooter,
  IonToolbar,
  IonButton,
  IonIcon
} from '@ionic/react';
import ProjectHeader from '../components/projects/ProjectHeader';
import ProjectDataValue from '../components/projects/ProjectDataValue';
import ProjectReviewForm from '../components/projects/ProjectReviewForm';
import LoadingScreen from '../components/LoadingScreen';
import ProjectDraftPanel from '../components/projects/ProjectDraftPanel';
import { FormattedMessage, useIntl } from 'react-intl';

// types
import { getProjectStructure, ProjectField, ProjectSection } from '../models/Project';
import { useReactToPrint } from 'react-to-print';
import printStyles from '../printStyles';
import { print } from 'ionicons/icons';


const ProjectView = () => {
  const intl = useIntl();
  const pageRef = useRef();
  const firebase = useFirebase();
  const { pid } = useParams<{ pid: string }>();

  // project
  const ProjectStructure = useMemo(() => getProjectStructure(intl),
  // eslint-disable-next-line
  [intl.locale]);
  const project = useCollectionItem('projects', pid);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});
  const gropupId = useSelector((state: any) => state.firebase.profile?.disguise?.groupId || state.firebase.profile?.groupId);

  // get download urls
  const [ fileUrls, setFileUrls ] = useState<{ [fileId: string]: any }>({});

  const getDownloadUrl = (fileId: string, fullPath: string) => {
    const storageRef = firebase.storage().ref(fullPath);
    storageRef.getDownloadURL()
      .then((downloadUrl: string) => {
        setFileUrls(state => ({ ...state, [fileId]: downloadUrl }));
      });
  }

  useEffect(() => {
    if (project.state.isLoaded && !project.state.isEmpty && project.item) {
      ProjectStructure.forEach(section => {
        if (section.fields) {
          section.fields.forEach(field => {
            if (field.type === 'files' && project.item[field.name]) {
              project.item[field.name].forEach((file: any) => {
                if (file.fullPath) {
                  getDownloadUrl(file.fid || file.id, file.fullPath);
                }
              });
            }
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [project.item]);


  // save button
  const [ showSaveButton, setShowSaveButton ] = useState(false);
  const changeSaveButton = (state: boolean) => {
    if (showSaveButton) {
      setSaveData(false);
    }
    setShowSaveButton(state);
  }
  const [ saveData, setSaveData ] = useState(false);
  const saveChanges = () => {
    setSaveData(true);
  }

  // printing
  const [printEl, setPrintEl] = useState<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => printEl || null,
    copyStyles: false,
    removeAfterPrint: false,
    pageStyle: printStyles
  });

  if (
    (
      project.state.isNotLoaded ||             // project is unavailable
      project.state.isLoaded                   // project is loaded
    ) &&
    Object.keys(permissions).length &&         // permissions are loaded
    !permissions['projects.all.read'] &&       // it's not an admin
    !permissions['projects.approved.read'] &&  // it's not a jury
    !(permissions['projects.group.read'] && !!project.item?.access[gropupId]) // it's not a project leader
  ) {
    return <Redirect to="/projects" />
  } else {
    return (
      <IonPage ref={pageRef}>
        <ProjectHeader pid={pid} page="view"/>
        <IonContent>
          { (project.state.isNotLoaded || project.state.isLoading)
            ? <LoadingScreen />
            : project.state.isNotFound
              ? <Redirect to="/projects" />
              : <>
                  <h2 className="list-title">
                    <span></span>
                    <span>
                      <IonButton fill="clear" shape="round" className="actions-button"
                        onClick={handlePrint}
                      >
                        <IonIcon icon={print} slot="icon-only" color="dark" className="print-icon"/>
                      </IonButton>
                    </span>
                  </h2>

                  { permissions['project.review.view']
                    ? <ProjectReviewForm project={project} showSaveButton={changeSaveButton} saveData={saveData}/>
                    : permissions['projects.group.edit'] &&
                      <ProjectDraftPanel pid={pid} status={project.item.moderation_state} />
                  }
                  <div ref={setPrintEl}>
                    <ProjectFieldsView projectStructure={ProjectStructure} projectItem={project.item} fileUrls={fileUrls} />
                  </div>
                </>
          }

        </IonContent>
        { showSaveButton &&
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <section className="bottom-controls">
                <div>
                  <IonButton className="blue-style min-widthed" onClick={() => saveChanges()}>
                    <FormattedMessage
                      id="button.save-changes"
                      defaultMessage="Save Changes"
                      description="Save changes button label"
                    />
                  </IonButton>
                </div>
              </section>
            </IonToolbar>
          </IonFooter>
        }
      </IonPage>
    )
  }
}

export default ProjectView;


type TProjectFieldsViewProps = {
  projectStructure: ProjectSection[];
  projectItem: any;
  fileUrls: any;
}
const ProjectFieldsView = ({ projectStructure, projectItem, fileUrls }: TProjectFieldsViewProps) => {
  return (
    <>
      { projectStructure.map((section: ProjectSection, idx: number) => (
          <div key={`section${idx}`}>
            { section.title && section.title !== '' &&
              <h2 className="project--section-title">{ section.number && (section.number + '. ') } { section.title }</h2>
            }
            <IonList className="project--section">
              { section.fields && section.fields
                  .filter(field => filterProjectFieldsByDependancies(field, projectItem))
                  .map((field, idx) => (
                    <IonItem key={`field$${field.number}-${idx}`} className="project--section-item">
                      <IonLabel position="stacked">{ field.number && (field.number + '. ') } { field.title }</IonLabel>
                      <ProjectDataValue field={field} value={projectItem && projectItem[field.name]} fileUrls={fileUrls} />
                    </IonItem>
                  ))
              }
            </IonList>
          </div>
        ))
      }
    </>
  )
}



export const filterProjectFieldsByDependancies = (field: ProjectField, projectData: any) => {
  if (field.dependant) {
    if (projectData) {
      return Object.keys(field.dependant).every(dependencyField => {
        if (field.dependant && field.dependant[dependencyField]) {
          return projectData[dependencyField] === field.dependant[dependencyField] ||
            (projectData[dependencyField]? projectData[dependencyField][field.dependant[dependencyField]] : false);
        }
        return true;
      });
    }
    return false;
  }
  return true;
}
