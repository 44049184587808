import React, { useState, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
import { querystringDecode } from '@firebase/util';

import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import { IonSpinner, IonPage } from '@ionic/react';
import AuthMessage, { AuthMessageType } from './AuthMessage';
import AuthForms from './AuthForms';

import './Auth.scss';

const { SplashScreen } = Plugins;

type ResetPassQueryParams =
  | {
      mode: string;
      oobCode: string;
      user?: string;
    }
  | false;

const Auth: React.FunctionComponent = props => {
  const firebase = useFirebase();

  // get query string params
  const queryParams = querystringDecode(window.location.search) as ResetPassQueryParams;

  // three variables about query string params that can be
  // email verification state
  const [emailVerification, setEmailVerification] = useState<AuthMessageType>('');
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [isUserAction, setIsUserAction] = useState(false);
  // code for the new password
  const [createPasswordCode, setCreatePasswordCode] = useState('');

  // one time check on the page load
  useEffect(() => {
    if (queryParams && queryParams.mode === 'verifyEmail' && queryParams.oobCode) {
      // set that it is an email verification
      setEmailVerification('email-verification-checking');

      // send the code to the firebase
      firebase
        .auth()
        .applyActionCode(queryParams.oobCode)
        .then(() => {
          // if the email is verified
          setEmailVerification('email-verified');

          // set changes in the profile so every other app instance will get the update
          firebase.updateProfile({ emailVerified: true });
        })
        .catch(err => {
          // there were an error in the verification
          setEmailVerification('email-verification-error');
          setEmailVerificationError(err.message);
        });
    }
    if (queryParams && queryParams.mode === 'resetPassword' && queryParams.oobCode) {
      if (queryParams.user === 'true') {
        setIsUserAction(true);
      }
      setCreatePasswordCode(queryParams.oobCode);
    }
    // eslint-disable-next-line
  }, []);

  const clearEmailVerification = () => {
    if (emailVerification !== '') {
      setEmailVerification('');

      if (window.history.replaceState) {
        window.history.replaceState({}, '', '/');
      } else {
        window.location.href = '/';
      }
    }
  };

  const clearPasswordResetCode = () => {
    if (createPasswordCode !== '') {
      setCreatePasswordCode('');

      if (window.history.replaceState) {
        window.history.replaceState({}, '', '/');
      } else {
        window.location.href = '/';
      }
    }
  };

  // get data from redux
  const auth = useSelector((state: any) => state.firebase.auth);
  const profile = useSelector((state: any) => state.firebase.profile);

  useEffect(() => {
    if (
      (isLoaded(auth) && !isEmpty(auth) && auth.emailVerified) ||
      (isLoaded(profile) && !isEmpty(profile) && profile.emailVerified)
    ) {
      clearEmailVerification();
    }
    // eslint-disable-next-line
  }, [auth, profile]);

  // hide the mobile app splash screen
  // if the auth is already loaded
  if (isLoaded(auth)) {
    SplashScreen.hide();
  }

  if (isLoaded(auth)) {
    if (emailVerification !== '') {
      // if the user is verifying email right now
      // e.g. in the url there are params `mode=verifyEmail` & `oobCode`
      return (
        <AuthMessage type={emailVerification} error={emailVerificationError} clearState={clearEmailVerification} />
      );
    } else {
      if (isEmpty(auth) || createPasswordCode) {
        // there is no logged in user
        // we are showing the forms
        return (
          <AuthForms
            createPasswordCode={createPasswordCode}
            clearState={clearPasswordResetCode}
            isUserAction={isUserAction}
            registerPath={window.location.pathname}
          />
        );
      } else {
        // the user is logged in
        if (auth.emailVerified || profile?.emailVerified) {
          // the email is verified — show the content
          return <>{props.children}</>;
        } else {
          // if the email is not yet verified
          // we are showing message to check the email and click the link
          return <AuthMessage type="verify-email" email={auth.email} />;
        }
      }
    }
  } else {
    // if the current user is not yet loaded, show the spinner
    return (
      <IonPage>
        <div className="center-element">
          <IonSpinner />
        </div>
      </IonPage>
    );
  }
};

export default Auth;
