import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { IonAlert, IonButton } from '@ionic/react';

import './ProjectDraftPanel.scss';


type ProjectDraftPanelProps = {
  status: string;
  pid: string;
}

const ProjectDraftPanel = ({ status, pid }: ProjectDraftPanelProps) => {
  const firestore = useFirestore();

  const [ alertOpen, setAlertOpen ] = useState(false);
  const submitProject = (pid: string) => {
    firestore
      .collection('projects')
      .doc(pid)
      .update({
        editable: false,
        moderation_state: 'needs_review',
        date: new Date().toISOString()
      });
  }

  if ((status === 'draft' || status === '')) {
    return (
      <div className="draft-panel">
        <div className="draft-panel--message">
          <IonAlert
            isOpen={alertOpen}
            cssClass='confirmation-alert-modal'
            onDidDismiss={() => setAlertOpen(false)}
            header={'Submit Project'}
            message={'Are you sure, you want to submit the project? You won\'t be able to change it anymore.'}
            buttons={['Cancel', {
              text: 'Submit',
              handler: () => submitProject(pid)
            }]}
          />
          <h2>Your project is not submitted to Esch2022</h2>
          <p>
            Your project is in a draft mode. Once you complete all the fields,<br />
            you can submit it to be reviewed by Esch 2022 team.
          </p>
        </div>
        <IonButton className="blue-style" onClick={() => setAlertOpen(true)}>Submit my project</IonButton>
      </div>
    )
  } else {
    return (
      <div className="draft-panel">
        <div className="draft-panel--message">
          <h2>Your project has been submitted to Esch2022</h2>
          <p>
            Your project is currently being reviewed by a moderator, the editing is not possible anymore.<br />
            In case you need to make some changes, contact the moderator by adding a comment.
          </p>
        </div>
      </div>
    )
  }
}

export default ProjectDraftPanel;
