import React, { useEffect, useState, useMemo } from 'react';
import { filterProjectFieldsByDependancies } from './Project';

// hooks
import { useParams, useHistory, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { useCollectionItem } from '../hooks/useCollectionItem';
import { useFirestore } from 'react-redux-firebase';

// components
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonButton, IonFooter, IonToolbar } from '@ionic/react';
import ProjectHeader from '../components/projects/ProjectHeader';
import LoadingScreen from '../components/LoadingScreen';
import ProjectFormField from '../components/projects/ProjectFormField';
import { FormattedMessage, useIntl } from 'react-intl';

// types
import { ProjectState, getProjectStructure } from '../models/Project';


const ProjectEdit = () => {
  const intl = useIntl();
  const { pid } = useParams<{ pid: string }>();
  const history = useHistory();

  // project
  const ProjectStructure = useMemo(() => getProjectStructure(intl), 
  // eslint-disable-next-line
  [intl.locale]);
  const project = useCollectionItem('projects', pid) as ProjectState;

  // get the user's id
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const gropupId = useSelector((state: any) => state.firebase.profile?.disguise?.groupId || state.firebase.profile?.groupId);

  // permissions
  const permissions = useSelector((state: any) => state.firestore.data.role?.permissions || {});


  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<{ [prop: string]: any }>(project.item);

  useEffect(() => {
    if (
      project.state.isLoaded &&
      !project.state.isEmpty &&
      (!formData || project.item.pid !== formData.pid)
    ) {
      setFormData(project.item);
    }
    // eslint-disable-next-line
  }, [project.item]);


  const [toolbarVisible, setToolbarVisible] = useState(false);

  // function that is triggered when the data is changed from the form fields
  const updateData = (property: string, value: any, checked?: boolean) => {
    if (checked !== undefined) {
      setToolbarVisible(true);
      const updatedData = { ...formData, [property]: { ...formData[property], [value]: checked } };
      setFormData(updatedData);
    } else if ((formData as any)[property] === undefined && value === '<p></p>') {
    } else if ((formData as any)[property] !== value) {
      setToolbarVisible(true);
      setFormData(data => ({ ...data, [property]: value }));
    }
  }

  // function that is saving the data
  const firestore = useFirestore();
  const updateItem = () => {
    // checking the permission first
    if (permissions['projects.group.edit'] || permissions['projects.all.edit']) {

      // clearing undefined values — Firestore don't like theese
      Object.keys(formData).forEach(key => {
        if ((formData as any)[key] === undefined) {
          delete (formData as any)[key]
        }
      });

      // sending to the firestore
      firestore
        .collection('projects')
        .doc(pid)
        .update({
          ...formData,
          date: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
          updatedBy: uid,
          uid
        })
        .then(() => {
          setToolbarVisible(false);
          history.replace(`/projects/${pid}`);
        });
    }
  }


  if (
    (
      project.state.isNotLoaded ||            // project is unavailable
      project.state.isLoaded                  // project is loaded
    ) &&
    Object.keys(permissions).length &&        // permissions are loaded
    !permissions['projects.all.edit'] &&      // it's not an admin
    !(
      permissions['projects.group.edit'] &&
      !!project.item?.access[gropupId] &&
      project.item?.moderation_state === 'draft'
    ) // it's not a project leader
  ) {
    return <Redirect to={`/projects/${pid}`} />
  } else {
    return (
      <IonPage>
        <ProjectHeader pid={pid} page="edit"/>
        <IonContent>

          { (formData && formData.pid === pid)
            ? ProjectStructure.map((section, idx) => (
                <div key={`section${idx}`}>
                  { section.title && section.title !== '' &&
                    <h2 className="project--section-title">{ section.number && (section.number + '. ') } { section.title }</h2>
                  }
                  <IonList className="project--section">
                    { section.fields && section.fields
                        .filter(field => filterProjectFieldsByDependancies(field, formData))
                        .map((field, idx) => (
                          <IonItem key={`field$${field.number}-${idx}`} className="project--section-item">
                            <IonLabel position="stacked">{ field.number && (field.number + '. ') } { field.title }</IonLabel>
                            <ProjectFormField field={field} value={formData[field.name]} updateData={updateData} pid={pid} />
                          </IonItem>
                        ))
                    }
                  </IonList>
                </div>
              ))
            : <LoadingScreen />
          }
        </IonContent>

        { toolbarVisible &&
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <section className="bottom-controls">
                <div>
                  <IonButton className="blue-style min-widthed" onClick={() => updateItem()}>
                    <FormattedMessage
                      id="button.save-changes"
                      defaultMessage="Save Changes"
                      description="Save changes button label"
                    />
                  </IonButton>
                </div>
              </section>
            </IonToolbar>
          </IonFooter>
        }
      </IonPage>
    );
  }
}

export default ProjectEdit;
