import { RequestState } from "./RequestState";
import { IntlShape } from "react-intl";


export type Project = {
  pid: string;
  title: string;
  editable: boolean;
  moderation_state: '' | 'draft' | 'needs_review' | 'changes_needed';
  category: 'cat1' | 'cat2' | 'cat3' | 'cat4';
  date: string;

  uid?: string;
  deadline?: string;
  actionRequired?: [];
  tags?: [];
  region?: [];
  discipline1: [];
  discipline2: [];
  publicTarget?: [];

  // project
  location: string;
  keywords: string;
  briefDescription: string;
  startDate: string;
  endDate: string;
  
  // contacts
  contactType: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  officialName: string;
  registrationNumber: string;
  representatives: string;
  position: string;
  municipality: string;
  address: string;
  postCode: string;
  city: string;
  country: string;
  structureName: string;

  // categories
  categories: string[];
  otherCategories: string;
  themes: string[];

  // other
  reasons: string;
  whyThemes: string;
  presentation: string;
  innovations: string;
  participation: string;
  localPartners: string;
  internationalPartners: string;
  whyThesePartners: string;
  targetAudiences: string;
  whyTheseAudiences: string;
  reachAudiences: string;
  involveAudiences: string;
  realizationStages: string;
  impact: string;
  estimatedBudget: string;
  requestedBudget: string;
  questions: string;

  // sponsoring
  sponsoringCompletionRate: string;
  sponsoring?: {
    participation?: { [id: string]: boolean };
    region?: string[];
    visual?: { id: string; name: string; fullPath: string; }[];
    cardDescription?: string;
    youTubeLink?: string;
    description?: string;
    documents?: { id: string; name: string; fullPath: string; }[];
    budget?: { id: string; name: string; fullPath: string; }[];
    type?: string[];
    partnershipDetails?: string;
    contactPerson?: string;
    contactEmail?: string;
    contactPhone?: string;
    remarks?: string;
    updatedOn: string;
    updatedBy: string;
  }
  
  // documents
  documents?: { id?: string, fid?: string; fullPath?: string; name: string; }[];
  docBudget?: { id?: string, fid?: string; fullPath?: string; name: string; }[];

  officialDocuments?: { id?: string, fid?: string; fullPath?: string; name: string; }[];

  access: { [gid: string]: boolean };
}

export type ProjectState = {
  item: Project;
  state: RequestState;
};

export type ProjectsState = {
  items: { [id: string]: Project };
  state: RequestState;
};

export type ProjectsOrderedState = {
  items: Project[];
  state: RequestState;
};


// which fields are used in search
// we have to set the field names in the type to match the Projects fields
export type TProjectFieldName =
  'pid' | 'title' | 'location' | 'keywords' | 'briefDescription' | 'startDate' | 'endDate' | 'contactType' |
  'discipline1' | 'discipline2' | 'tags' | 'publicTarget' |
  'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'officialName' | 'registrationNumber' |
  'representatives' | 'position' | 'municipality' | 'address' | 'postCode' | 'city' | 'country' |
  'structureName' | 'categories' | 'otherCategories' | 'themes' | 'reasons' | 'whyThemes' |
  'presentation' | 'innovations' | 'participation' | 'localPartners' | 'internationalPartners' |
  'whyThesePartners' | 'targetAudiences' | 'whyTheseAudiences' | 'reachAudiences' | 'involveAudiences' |
  'realizationStages' | 'impact' | 'estimatedBudget' | 'requestedBudget' | 'questions' | 'sponsoringCompletionRate'

export const searchDataFields: TProjectFieldName[] = [
  'pid', 'title', 'location', 'keywords', 'briefDescription', 'startDate', 'endDate', // project
  'discipline1', 'discipline2', 'tags', 'publicTarget', // moderator fields
  'firstName', 'lastName', 'email', 'phoneNumber', 'officialName', 'registrationNumber', // contact
  'representatives', 'position', 'municipality', 'address', 'postCode', 'city', 'country', 'structureName',
  'categories', 'otherCategories', 'themes', // categories
  'reasons', 'whyThemes', 'presentation', 'innovations', 'participation', 'localPartners', // other
  'internationalPartners', 'whyThesePartners', 'targetAudiences', 'whyTheseAudiences', 'reachAudiences',
  'involveAudiences', 'realizationStages', 'impact', 'estimatedBudget', 'requestedBudget', 'questions'
];

export const projectFieldsIntlIds: { [fieldId: string]: string } = {
  'pid': '',
  'title': 'form.project-name',
  'location': 'project.question.32',
  'keywords': 'project.question.33',
  'briefDescription': 'project.question.34',
  'startDate': 'project.question.111',
  'endDate': 'project.question.112',
  'discipline1': 'form.discipline1',
  'discipline2': 'form.discipline2',
  'tags': 'form.tags',
  'publicTarget': 'form.public-target',
  'firstName': 'form.first-name',
  'lastName': 'form.last-name',
  'email': 'form.email',
  'phoneNumber': 'form.phone',
  'officialName': 'form.official-name',
  'registrationNumber': 'form.registration-number',
  
  'representatives': 'form.representatives',
  'position': 'form.contact-person.position',
  'municipality': 'project.contact.municipality',
  'address': 'form.address',
  'postCode': 'form.postal-code',
  'city': 'form.city',
  'country': 'form.country',
  
  'structureName': 'form.others.name',
  'categories': 'project.question.41',
  'otherCategories': 'project.question.42',
  'themes': 'project.question.51',
  'reasons': 'project.title.2',
  'whyThemes': 'project.question.52',
  
  'presentation': 'project.title.6',
  'innovations': 'project.title.7',
  'participation': 'project.title.8',
  'localPartners': 'project.question.92',
  'internationalPartners': 'project.question.93',
  
  'whyThesePartners': 'project.question.94',
  'targetAudiences': 'project.question.101',
  'whyTheseAudiences': 'project.question.102',
  'reachAudiences': 'project.question.103',
  'involveAudiences': 'project.question.104',
  
  'realizationStages': 'project.question.113',
  'impact': 'project.title.12',
  'estimatedBudget': 'project.question.131',
  'requestedBudget': 'project.question.132',
  'questions': 'project.question.141'
}



export type ProjectSection = {
  number: string;
  title: string;
  fields: ProjectField[];
}

export type ProjectField = {
  dependant?: { [fieldName: string]: any };
  number?: string;
  title?: string;
  name: string;
  type: string;
  options?: ProjectOption[];
  default?: any;
  multiple?: boolean; // for multiple selects
  accept?: string;    // which file extensions are accepted
  path?: string;      // path for the file uploading
  visible?: boolean;
}

type ProjectOption = {
  name: string;
  value: any;
}


export const getProjectModerationStatus = (intl: IntlShape) => {
  return {
    undefined: intl.formatMessage({
      id:'form.select.no-status',
      defaultMessage:'No Status',
      description:'Select form no status label'
    }),
    '': intl.formatMessage({
      id:'form.select.no-status',
      defaultMessage:'No Status',
      description:'Select form no status label'
    }),
    'draft': intl.formatMessage({
      id:'form.select.draft',
      defaultMessage:'Draft',
      description:'Select form draft label'
    }),
    'needs_review': intl.formatMessage({
      id:'form.select.needs-review',
      defaultMessage:'Needs Review',
      description:'Select form needs_review label'
    }),
    'changes_needed': intl.formatMessage({
      id:'form.select.changes-needed',
      defaultMessage:'Changes needed',
      description:'Select form Changes needed label'
    })
  }
}


export const getProjectStructure = (intl: IntlShape): ProjectSection[] => {
  return[
  {
    number: '1',
    title: intl.formatMessage({
      id: 'project.contact.title',
      defaultMessage: 'Contact',
      description: 'Project section title 1',
    }),
    fields: [
      {
        number: '1.1',
        title: intl.formatMessage({
          id: 'project.contact.type',
          defaultMessage: 'Contact Type',
          description: 'Project contct type field label',
        }),
        name: 'contactType',
        type: 'select',
        options: [
          { 
            name: intl.formatMessage({
              id: 'project.contact.private',
              defaultMessage: 'Private Person',
              description: 'Project private contact field label',
            }),
            value: 'private' 
          },
          { name: 'ASBL', value: 'asbl' },
          { 
            name: intl.formatMessage({
              id: 'project.contact.municipality',
              defaultMessage: 'Municipality',
              description: 'Project municipality contact field label',
            }),
            value: 'commune'
          },
          { 
            name: intl.formatMessage({
              id: 'project.contact.others',
              defaultMessage: 'Other (institutions, schools, foundations, citizen groups)',
              description: 'Project others contact field label',
            }),
            value: 'others'
          }
        ],
        default: 'private'
      },
      // Private person
      {
        dependant: { contactType: 'private' },
        number: '1.2',
        title: intl.formatMessage({
          id: "form.first-name",
          defaultMessage: "First Name",
          description: "First Name field on all form"
        }),
        name: 'firstName',
        type: 'text'
      },
      {
        dependant: { contactType: 'private' },
        number: '1.3',
        title: intl.formatMessage({
          id: "form.last-name",
          defaultMessage: "Last Name",
          description: "Last Name field on all form"
        }),
        name: 'lastName',
        type: 'text'
      },
      {
        dependant: { contactType: 'private' },
        number: '1.4',
        title: intl.formatMessage({
          id: "form.email",
          defaultMessage: 'Email address',
          description: "Email field on all form"
        }),
        name: 'email',
        type: 'text'
      },
      {
        dependant: { contactType: 'private' },
        number: '1.5',
        title: intl.formatMessage({
          id: "form.phone",
          defaultMessage: 'Phone number',
          description: "Phone number field on all form"
        }),
        name: 'phoneNumber',
        type: 'text'
      },
      {
        dependant: { contactType: 'private' },
        number: '1.6',
        title: intl.formatMessage({
          id: "form.doc.cv",
          defaultMessage: 'CV',
          description: "CV file field on all form"
        }),
        name: 'docCv',
        type: 'files',
        multiple: false
      },

      // ASBL
      {
        dependant: { contactType: 'asbl' },
        number: '1.2',
        title: intl.formatMessage({
          id: "form.official-name",
          defaultMessage: 'Official Name',
          description: "Official Name of asbl field on all form"
        }),
        name: 'officialName',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.3',
        title: intl.formatMessage({
          id: "form.registration-number",
          defaultMessage: 'Registration number',
          description: "Registration number of asbl field on all form"
        }),
        name: 'registrationNumber',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.4',
        title: intl.formatMessage({
          id: "form.established",
          defaultMessage: 'Established',
          description: "Established date of asbl field on all form"
        }),
        name: 'established',
        type: 'date'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.5',
        title: intl.formatMessage({
          id: "form.representatives",
          defaultMessage: 'Representatives',
          description: "Representatives field on all form"
        }),
        name: 'representatives',
        type: 'rich-text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.6',
        title: intl.formatMessage({
          id: "form.doc.asbl-registration",
          defaultMessage: 'ASBL Registration Document',
          description: "ASBL Registration Document file field on all form"
        }),
        name: 'docAsbl',
        type: 'files',
        multiple: true
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.7',
        title: intl.formatMessage({
          id: "form.contact-person.first-name",
          defaultMessage: 'Contact Person: First Name',
          description: "Contact Person: First Name field on all form"
        }),
        name: 'firstName',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.8',
        title: intl.formatMessage({
          id: "form.contact-person.last-name",
          defaultMessage: 'Contact Person: Last Name',
          description: "Contact Person: Last Name field on all form"
        }),
        name: 'lastName',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.9',
        title: intl.formatMessage({
          id: "form.contact-person.position",
          defaultMessage: 'Contact Person: Position',
          description: "Contact Person: Position field on all form"
        }),
        name: 'position',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.10',
        title: intl.formatMessage({
          id: "form.contact-person.email",
          defaultMessage: 'Contact Person: E-mail address',
          description: "Contact Person: Email field on all form"
        }),
        name: 'email',
        type: 'text'
      },
      {
        dependant: { contactType: 'asbl' },
        number: '1.11',
        title: intl.formatMessage({
          id: "form.contact-person.phone",
          defaultMessage: 'Contact Person: Phone number',
          description: "Contact Person: Phone number field on all form"
        }),
        name: 'phoneNumber',
        type: 'text'
      },
      // Municipality
      {
        dependant: { contactType: 'commune' },
        number: '1.2',
        title: intl.formatMessage({
          id: "form.municipality.name",
          defaultMessage: 'Name of the Municipality',
          description: "Name of the Municipality field on all form"
        }),
        name: 'municipality',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.3',
        title: intl.formatMessage({
          id: "form.address",
          defaultMessage: 'Address',
          description: "Address field on all form"
        }),
        name: 'address',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.4',
        title: intl.formatMessage({
          id: "form.postal-code",
          defaultMessage: 'Postal Code',
          description: "Postal Code field on all form"
        }),
        name: 'postCode',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.5',
        title: intl.formatMessage({
          id: "form.city",
          defaultMessage: 'City',
          description: "City field on all form"
        }),
        name: 'city',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.6',
        title: intl.formatMessage({
          id: "form.country",
          defaultMessage: 'Country',
          description: "Country field on all form"
        }),
        name: 'country',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.7',
        title: intl.formatMessage({
          id: "form.contact-person.first-name",
          defaultMessage: 'Contact Person: First Name',
          description: "Contact Person: First Name field on all form"
        }),
        name: 'firstName',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.8',
        title: intl.formatMessage({
          id: "form.contact-person.last-name",
          defaultMessage: 'Contact Person: Last Name',
          description: "Contact Person: Last Name field on all form"
        }),
        name: 'lastName',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.9',
        title: intl.formatMessage({
          id: "form.contact-person.position",
          defaultMessage: 'Contact Person: Position',
          description: "Contact Person: Position field on all form"
        }),
        name: 'position',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.10',
        title: intl.formatMessage({
          id: "form.contact-person.email",
          defaultMessage: 'Contact Person: E-mail address',
          description: "Contact Person: Email field on all form"
        }),
        name: 'email',
        type: 'text'
      },
      {
        dependant: { contactType: 'commune' },
        number: '1.11',
        title: intl.formatMessage({
          id: "form.contact-person.phone",
          defaultMessage: 'Contact Person: Phone number',
          description: "Contact Person: Phone number field on all form"
        }),
        name: 'phoneNumber',
        type: 'text'
      },
      // other
      {
        dependant: { contactType: 'others' },
        number: '1.2',
        title: intl.formatMessage({
          id: "form.others.name",
          defaultMessage: 'Name of the Institution / Structure',
          description: "Name of the Institution / Structure field on all form"
        }),
        name: 'structureName',
        type: 'text'
      },
      {
        dependant: { contactType: 'others' },
        number: '1.3',
        title: intl.formatMessage({
          id: "form.contact-person.first-name",
          defaultMessage: 'Contact Person: First Name',
          description: "Contact Person: First Name field on all form"
        }),
        name: 'firstName',
        type: 'text'
      },
      {
        dependant: { contactType: 'others' },
        number: '1.4',
        title: intl.formatMessage({
          id: "form.contact-person.last-name",
          defaultMessage: 'Contact Person: Last Name',
          description: "Contact Person: Last Name field on all form"
        }),
        name: 'lastName',
        type: 'text'
      },
      {
        dependant: { contactType: 'others' },
        number: '1.5',
        title: intl.formatMessage({
          id: "form.contact-person.email",
          defaultMessage: 'Contact Person: E-mail address',
          description: "Contact Person: Email field on all form"
        }),
        name: 'email',
        type: 'text'
      },
      {
        dependant: { contactType: 'others' },
        number: '1.6',
        title: intl.formatMessage({
          id: "form.contact-person.phone",
          defaultMessage: 'Contact Person: Phone number',
          description: "Contact Person: Phone number field on all form"
        }),
        name: 'phoneNumber',
        type: 'text'
      }
    ]
  },
  {
    number: '2',
    title: intl.formatMessage({
      id: 'project.title.2',
      defaultMessage: 'Reasons',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '2.1',
        title: intl.formatMessage({
          id: 'project.question.21',
          defaultMessage: 'You wish to carry out a project within the framework of the European capital of culture 2022. Why? What are the reasons?',
          description: 'Project questions',
        }),
        name: 'reasons',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '3',
    title: intl.formatMessage({
      id: 'project.title.3',
      defaultMessage: 'Project',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '3.1',
        title: intl.formatMessage({
          id: 'form.project-name',
          defaultMessage: "Project Name",
          description: "Project Name field label",
        }),
        name: 'title',
        type: 'text'
      },
      {
        number: '3.2',
        title: intl.formatMessage({
          id: 'project.question.32',
          defaultMessage: 'Planned location(s)',
          description: 'Project questions',
        }),
        name: 'location',
        type: 'rich-text'
      },
      {
        number: '3.3',
        title: intl.formatMessage({
          id: 'project.question.33',
          defaultMessage: 'Up to Five Keywords describing the Project',
          description: 'Project questions',
        }),
        name: 'keywords',
        type: 'text'
      },
      {
        number: '3.4',
        title: intl.formatMessage({
          id: 'project.question.34',
          defaultMessage: 'Briefly explain your project to us and share your description with us',
          description: 'Project questions',
        }),
        name: 'briefDescription',
        type: 'rich-text'
      },
      {
        number: '3.5',
        title: intl.formatMessage({
          id: "form.doc.description",
          defaultMessage: 'Description document',
          description: "Description document file field on all form"
        }),
        name: 'docDescription',
        type: 'files',
        multiple: true
      }
    ]
  },
  {
    number: '4',
    title: intl.formatMessage({
      id: 'project.title.4',
      defaultMessage: 'Category',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '4.1',
        title: intl.formatMessage({
          id: 'project.question.41',
          defaultMessage: 'Check the categoryies corresponding to your project',
          description: 'Project questions',
        }),
        name: 'categories',
        type: 'checkbox',
        options: [
          { name: 'Architecture', value: 'architecture' },
          { name: 'Street Art', value: 'artsdelarue' },
          { name: 'Digital Art', value: 'artsnumeriques' },
          { name: 'Plastic Art', value: 'artsplastiques' },
          { name: 'Visual Art', value: 'artsvisuels' },
          { name: 'Dance', value: 'danse' },
          { name: 'Design', value: 'design' },
          { name: 'Heritage', value: 'patrimoine' },
          { name: 'Literature', value: 'litterature' },
          { name: 'Classic or Modern Music', value: 'musiqueclassique' },
          { name: 'Jazz', value: 'musiquejazz' },
          { name: 'Pop/Rock/Electro', value: 'musiquepop' },
          { name: 'Theatre', value: 'theatre' },
          { name: 'Multidisciplinary', value: 'pluridisciplinaire' },
          { name: 'Other', value: 'autre' },
        ]
      },
      {
        dependant: { 'categories': 'autre' },
        number: '4.2',
        title: intl.formatMessage({
          id: 'project.question.42',
          defaultMessage: 'Other categories',
          description: 'Project questions',
        }),
        name: 'otherCategories',
        type: 'text'
      }
    ]
  },
  {
    number: '5',
    title: intl.formatMessage({
      id: 'project.title.5',
      defaultMessage: 'Theme',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '5.1',
        title: intl.formatMessage({
          id: 'project.question.51',
          defaultMessage: 'Which themes of Esch2022 are corresponding with your project?',
          description: 'Project questions',
        }),
        name: 'themes',
        type: 'checkbox',
        options: [
          { name: 'Remix Art', value: 'remixart' },
          { name: 'Remix Europe', value: 'remixeurope' },
          { name: 'Remix Nature', value: 'remixnature' },
          { name: 'Remix Yourself', value: 'remixyourself' },
        ]
      },
      {
        number: '5.2',
        title: intl.formatMessage({
          id: 'project.question.52',
          defaultMessage: 'How your project fits into the chosen themes?',
          description: 'Project questions',
        }),
        name: 'whyThemes',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '6',
    title: intl.formatMessage({
      id: 'project.title.6',
      defaultMessage: 'Presentation',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '6.1',
        title: intl.formatMessage({
          id: 'project.question.61',
          defaultMessage: 'What does your project illustrate?',
          description: 'Project questions',
        }),
        name: 'presentation',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '7',
    title: intl.formatMessage({
      id: 'project.title.7',
      defaultMessage: 'Innovations',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '7.1',
        title: intl.formatMessage({
          id: 'project.question.71',
          defaultMessage: 'In your opinion, what is the innovative nature of your project?',
          description: 'Project questions',
        }),
        name: 'innovations',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '8',
    title: intl.formatMessage({
      id: 'project.title.8',
      defaultMessage: 'Participation',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '8.1',
        title: intl.formatMessage({
          id: 'project.question.81',
          defaultMessage: 'What is the participatory aspect of your project?',
          description: 'Project questions',
        }),
        name: 'participation',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '9',
    title: intl.formatMessage({
      id: 'project.title.9',
      defaultMessage: 'Partners',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '9.1',
        title: intl.formatMessage({
          id: 'project.question.91',
          defaultMessage: 'Do you have partners?',
          description: 'Project questions',
        }),
        name: 'havePartners',
        type: 'select',
        options: [
          { 
            name: intl.formatMessage({
              id: 'form.select.yes',
              defaultMessage: 'Yes',
              description: 'Select form yes label',
            }),
            value: 'yes' 
          },
          { 
            name: intl.formatMessage({
              id: 'form.select.no',
              defaultMessage: 'No',
              description: 'Select form no label',
            }),
            value: 'no' }
        ],
        default: false
      },
      {
        dependant: { 'havePartners': 'yes' },
        number: '9.2',
        title: intl.formatMessage({
          id: 'project.question.92',
          defaultMessage: 'Who are your local partners? (city, region, country)',
          description: 'Project questions',
        }),
        name: 'localPartners',
        type: 'rich-text'
      },
      {
        dependant: { 'havePartners': 'yes' },
        number: '9.3',
        title: intl.formatMessage({
          id: 'project.question.93',
          defaultMessage: 'Who are your international partners?',
          description: 'Project questions',
        }),
        name: 'internationalPartners',
        type: 'rich-text'
      },
      {
        dependant: { 'havePartners': 'yes' },
        number: '9.4',
        title: intl.formatMessage({
          id: 'project.question.94',
          defaultMessage: 'Why did you choose these partners and how are they involved in the project?',
          description: 'Project questions',
        }),
        name: 'whyThesePartners',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '10',
    title: intl.formatMessage({
      id: 'project.title.10',
      defaultMessage: 'Audience',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '10.1',
        title: intl.formatMessage({
          id: 'project.question.101',
          defaultMessage: 'Which audiences do you want to target with your project:',
          description: 'Project questions',
        }),
        name: 'targetAudiences',
        type: 'rich-text'
      },
      {
        number: '10.2',
        title: intl.formatMessage({
          id: 'project.question.102',
          defaultMessage: 'Why specifically them?',
          description: 'Project questions',
        }),
        name: 'whyTheseAudiences',
        type: 'rich-text'
      },
      {
        number: '10.3',
        title: intl.formatMessage({
          id: 'project.question.103',
          defaultMessage: 'How do you plan to reach them?',
          description: 'Project questions',
        }),
        name: 'reachAudiences',
        type: 'rich-text'
      },
      {
        number: '10.4',
        title: intl.formatMessage({
          id: 'project.question.104',
          defaultMessage: 'How do you want to involve the public?',
          description: 'Project questions',
        }),
        name: 'involveAudiences',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '11',
    title: intl.formatMessage({
      id: 'project.title.11',
      defaultMessage: 'Time period',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '11.1',
        title: intl.formatMessage({
          id: 'project.question.111',
          defaultMessage: 'Start of the Project',
          description: 'Project questions',
        }),
        name: 'startDate',
        type: 'date'
      },
      {
        number: '11.2',
        title: intl.formatMessage({
          id: 'project.question.112',
          defaultMessage: 'End of the Project',
          description: 'Project questions',
        }),
        name: 'endDate',
        type: 'date'
      },
      {
        number: '11.3',
        title: intl.formatMessage({
          id: 'project.question.113',
          defaultMessage: 'Stages of the realization. Dates and Places of performances.',
          description: 'Project questions',
        }),
        name: 'realizationStages',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '12',
    title: intl.formatMessage({
      id: 'project.title.12',
      defaultMessage: 'Impact',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '12.1',
        title: intl.formatMessage({
          id: 'project.question.121',
          defaultMessage: 'What is the expected short, medium and long term impact of the project on the territory and its inhabitants?',
          description: 'Project questions',
        }),
        name: 'impact',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '13',
    title: intl.formatMessage({
      id: 'project.title.13',
      defaultMessage: 'Budget',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '13.1',
        title: intl.formatMessage({
          id: 'project.question.131',
          defaultMessage: 'What is the estimated budget of your project?',
          description: 'Project questions',
        }),
        name: 'estimatedBudget',
        type: 'number'
      },
      {
        number: '13.2',
        title: intl.formatMessage({
          id: 'project.question.132',
          defaultMessage: 'What is the amount of financial assistance requested from Esch 2022?',
          description: 'Project questions',
        }),
        name: 'requestedBudget',
        type: 'number'
      },
      {
        number: '13.3',
        title: intl.formatMessage({
          id: "form.doc.budget",
          defaultMessage: 'Document with the budget details',
          description: "Document with the budget details file field on all form"
        }),
        name: 'docBudget',
        type: 'files',
        multiple: true
      }
    ]
  },
  {
    number: '14',
    title: intl.formatMessage({
      id: 'project.title.14',
      defaultMessage: 'Questions',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '14.1',
        title: intl.formatMessage({
          id: 'project.question.141',
          defaultMessage: 'Any questions or comments you have',
          description: 'Project questions',
        }),
        name: 'questions',
        type: 'rich-text'
      }
    ]
  },
  {
    number: '15',
    title: intl.formatMessage({
      id: 'project.title.15',
      defaultMessage: 'Recomendations Response',
      description: 'Project section title',
    }),
    fields: [
      {
        number: '15.1',
        title: intl.formatMessage({
          id: "form.doc.recommendations",
          defaultMessage: 'Response to the the recommendations of the committee',
          description: "Response to the the recommendations file field on all form"
        }),
        name: 'docRecommendations',
        type: 'files',
        multiple: true
      },
      {
        number: '15.2',
        title: intl.formatMessage({
          id: "form.doc.budget-final",
          defaultMessage: 'Final provisional budget',
          description: "Response to the the recommendations file field on all form"
        }),
        name: 'docFinalBudget',
        type: 'files',
        multiple: true
      }
    ]
  },
  {
    number: '16',
    title: intl.formatMessage({
      id: 'form.documents',
      defaultMessage: 'Documents',
      description: 'Documents field label',
    }),
    fields: [
      {
        number: '',
        title: '',
        name: 'documents',
        type: 'files',
        multiple: true
      }
    ]
  }
]}
