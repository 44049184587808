import React, { useState, useEffect } from "react";

// hooks
import { useFirestore } from "react-redux-firebase";
import { useIntl, FormattedMessage } from "react-intl";
import { useCollectionItem } from "../hooks/useCollectionItem";

// components
import ModalForm from "../components/ModalForm";
import { IonList, IonItem, IonLabel, IonInput, IonButton, IonAlert } from "@ionic/react";
import { newTerm, TaxonomyTerm, TaxonomyTermState } from "../models/Taxonomies";


type TTermModalProps = {
  opened: boolean;
  setOpened: Function;
  pageRef: HTMLElement | HTMLIonModalElement | undefined | null;
  termValue?: string;
  setTermValue?: Function;
  taxonomy: string;
}

const TermModal = ( props: TTermModalProps ) => {

  const term = useCollectionItem(props.taxonomy, props.termValue) as TaxonomyTermState;
  const [ termValue, setTermValue ] = useState<string>(props.termValue || '');

  // creating an object that holds all the fields for the form
  const [ formData, setFormData ] = useState<TaxonomyTerm>({...newTerm});

  // updates the termData by property key
  const updateData = (property: string, value: any) => {

    if ((formData as any)[property] !== value) {

      if ( props.termValue ) {
        setFormData(data => ({ ...data, [property]: value }));
      }else{
        setFormData({
          name: value,
          value: value.replace(/[\\/._\s]/gi, '').toLowerCase()
        });
      }
    }
  }

  //updating form data when the item is loaded
  useEffect(() => {
    if (termValue !== props.termValue && term.item?.value !== formData.value) {
      setTermValue(props.termValue || '');
      resetForm();
    }
    // eslint-disable-next-line
  }, [term]);

  // reset term data
  const resetForm = () => {
    if (props.termValue !== '') {
      setFormData({ ...term.item });
    } else {
      setFormData({ ...newTerm });
    }
  }

  const firestore = useFirestore();
  const createItem = () => {

    if ( formData.value ) {
      firestore
        .collection(props.taxonomy)
        .doc(formData.value)
        .set(formData)
        .then(() => {
          props.setOpened(false);
        })
        .catch(err => {
          console.error('error Creating taxonomy term', formData.value, err);
        });
    }
  }
  // file deleting
  const [fileToDelete, setFileToDelete] = useState<{ id?: any, name?: any }>({});

  // function that deleted current item
  const deleteItem = () => {
    if (fileToDelete.id) {
      firestore
        .collection(props.taxonomy)
        .doc(fileToDelete.id)
        .delete()
        .then(() => {
          resetForm();
          props.setOpened(false);
        })
        .catch(err => {
          console.error('error deleting taxonomy term', formData.value, err);
        });
    }
  }

  const intl = useIntl();

  return (
    <>
      <IonAlert
        isOpen={!!fileToDelete.id}
        cssClass='confirmation-alert-modal'
        onDidDismiss={() => setFileToDelete({})}
        header={ intl.formatMessage({
          id: "confirmation.delete.title",
          defaultMessage: "Delete {toDelete}",
          description: "Confirmation question",
        },{
          toDelete: formData.name
        })}
        message={ intl.formatMessage({
          id: "confirmation.sure",
          defaultMessage: "Are you sure?",
          description: "Confirmation question",
        })}
        buttons={[
          intl.formatMessage({
            id: "button.cancel",
            defaultMessage: 'Cancel',
            description: "Cancel button label"
          }), 
          {
            text: intl.formatMessage({
              id: "button.delete",
              defaultMessage: "Delete",
              description: "Delete button label"
            }),
            cssClass: 'danger',
            handler: () => deleteItem()
          }
      ]}
      />
    <ModalForm {...props}
      title={ props.termValue
        ? intl.formatMessage({
          id: 'modal.title.edit',
          defaultMessage: 'Edit {data}',
          description: 'Edit a role',
        },{
          data: formData.name
        })
        : intl.formatMessage({
          id: 'modal.title.create',
          defaultMessage: 'Create a new {data} term',
          description: 'Create a new term ',
        },{
          data: props.taxonomy 
        })
      }
    >
      <IonList className="no-bottom-controls">
        <IonItem className="project--section-item">
          <IonLabel position="stacked">
            <FormattedMessage
              id="taxonomy.term.name"
              defaultMessage="Term Name"
              description="Taxonomy term modal field label"
            />
          </IonLabel>
          <IonInput
            value={ formData.name }
            name="name"
            type="text"
            onIonChange={ e => updateData('name', e.detail.value)}
          ></IonInput>
        </IonItem>
      </IonList>
      <div className="bottom-controls">
        { props.termValue &&
            <IonButton
              expand="block"
              onClick={ () => setFileToDelete({ id: formData.value, name: formData.name })}
              className="danger-style"
            >{ props.termValue
              ? <FormattedMessage
                  id= "button.delete"
                  defaultMessage= "Delete"
                  description="Delete button label"
                />
              : <></>
              }
            </IonButton>
        }
        <IonButton
          expand="block"
          onClick={createItem}
          className="blue-style"
        >{props.termValue
          ? <FormattedMessage
              id= "button.update"
              defaultMessage= "Update"
              description="Update button label"
            />
          : <FormattedMessage
              id= "taxonomy.term.button.create"
              defaultMessage= 'Create a term'
              description="create button label"
            />
          }
        </IonButton>
      </div>
    </ModalForm>
    </>        
  );
}

export default TermModal;
