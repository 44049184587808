import React, { useState } from 'react';
import firebase from 'firebase/app';

// components
import {
  IonPage,
  IonLabel,
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast, IonFooter, IonToolbar
} from '@ionic/react';
import AccountHeader from '../components/AccountHeader';
import { FormattedMessage, useIntl } from 'react-intl';

const ChangePassword = () => {

  //change password
  //Get current user
  var user = firebase.auth().currentUser;

  // save toolbar visibility
  const [ toolbarVisible, setToolbarVisible ] = useState(false);
  //Set current and new password
  const [ currentPassword, setCurrentPassword ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const updatePasswords = ( passType: string, password: any) => {
    if (!toolbarVisible) setToolbarVisible(true);
    passType === "currentPassword" 
      ? setCurrentPassword(password)
      : setNewPassword(password)
  }

  //show success or fail messages
  const [ toast, setToast ] = useState({ message: '', color: "success", isOpen: false});
  const showMessage = ( message: string, color: string) => {
    setToast( state => ({ ...state, header: 'Account password', message, color, isOpen: true} ));
  }

  //change password
  const changePassword = () => {

    if ( user && user.email ) {
      //reauthenticate to check current password
      const currentCredential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      user.reauthenticateWithCredential(currentCredential)
        .then( () => {
          //current password ok, change the password
          if (user) { 
            user.updatePassword(newPassword)
              .then(function() {
                setToolbarVisible(false);
                //save new password
                showMessage("The new password is saved!", "success");
                //reset passwords fields
                setCurrentPassword('');
                setNewPassword('');
              })
              .catch(function(error) {
                //there was an error when changing the password, show error
                showMessage(error.message, "danger");
              });
          }
        })
        .catch( error => {
          //curent password nok, show error
          showMessage(error.message, "danger");
        });
    }
  }

  const intl = useIntl();

  return(
    <IonPage>
      <AccountHeader page="change-password" />
      <IonContent>
        <IonToast
          {...toast}
          onDidDismiss={ () => setToast(state => ({...state, isOpen: false }))}
          position='bottom'
          cssClass='bottom-toasts'
          animated={true}
          buttons={[{
              text: 'x',
              role: 'cancel',
              handler: () => {}
          }]}
        />

        <IonList>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id="form.current-password"
                defaultMessage="Current Pasword"
                description="Current Pasword field label"
              />
            </IonLabel>
            <IonInput 
              name="currentPassword"
              type="password"
              placeholder= {
                intl.formatMessage({
                  id: 'form.placeholder.current-password',
                  defaultMessage: 'Your Current Pasword',
                  description: 'Current Pasword input placeholder text',
                })
              }
              autocomplete="off"
              required
              onIonChange = { (e) => updatePasswords( "currentPassword", e.detail.value )}
            ></IonInput>
          </IonItem>
          <IonItem className="project--section-item">
            <IonLabel position="stacked">
              <FormattedMessage
                id= "form.new-password"
                defaultMessage= "New Password"
                description="New Password field label"
              />&nbsp;
              <FormattedMessage
                id= "form.new-password.description"
                defaultMessage= "(Password should be at least 6 characters)"
                description="New Password description text"
              />
            </IonLabel>
            <IonInput
              name="newPassword"
              type="password"
              placeholder= {
                intl.formatMessage({
                  id: 'form.placeholder.new-password',
                  defaultMessage: 'Your New Pasword',
                  description: 'New Pasword input placeholder text',
                })
              }
              autocomplete="off"
              onIonChange = { (e) => updatePasswords( "newPassword", e.detail.value )}
            ></IonInput>
          </IonItem>
        </IonList>
      </IonContent>
      { toolbarVisible &&
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <section className="bottom-controls">
              <div>
                <IonButton className="blue-style min-widthed" onClick={changePassword}>
                  <FormattedMessage
                    id="button.confirm"
                    defaultMessage="Confirm"
                    description="Confirm button label"
                  />
                </IonButton>
              </div>
            </section>
          </IonToolbar>
        </IonFooter>
      }
    </IonPage>
  )
}

export default ChangePassword;
