import { RequestState } from "./RequestState";

export type Role = {
  id: string;
  name: string;
  permissions: { [id: string]: boolean};
  groupId?: string;
  showInTheFilters?: boolean;
}

export type RoleState = {
  item: Role;
  state: RequestState;
};

export type RolesState = {
  items: { 
    [id: string]: Role };
  state: RequestState;
};

export type RolesOrderedState = {
  items: Role[];
  state: RequestState;
};

export const newRole = {
  id: '',
  name: '',
  permissions: {}
};
