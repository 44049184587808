import React from 'react';

import './CardGrid.scss';

type CardGridProps = {
  title?: string;
  children?: React.ReactNode
}

const CardGrid = ({ title, children }: CardGridProps) => {
  return (
    <>
      { title &&
        <h2 className="card-grid--title">{ title }</h2>
      }
      { children &&
        <div className="card-grid">
          { children }
        </div>
      }
    </>
  )
}

export default CardGrid;
